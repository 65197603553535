import { forEach, get, isUndefined, map } from 'lodash'
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'

import { get as getApi } from '../../Api/Api'
import { serverUrl } from '../../Api/api.config'
import { BasePage } from '../../components/Common/BasePage'
import { CenterContainer } from '../../components/Common/CenterContainer'
import { EmptySet } from '../../components/Common/EmptySet'
import { Loader } from '../../components/Common/Loader'
import { OrderCard } from '../../components/Orders/OrderCard'
import { OrdersHeader } from '../../components/Orders/OrdersHeader'
import {
  OrdersAction,
  OrdersActionType,
  OrdersGrouped,
  OrderShort,
  OrderShortList,
  ordersInitialState,
  OrdersState,
  OrderState,
  stateEmoji,
} from '../../models/Order'
import { alertService } from '../../providers/AlertProvider'
import { AuthContext } from '../../providers/AuthProvider'
import { Colors } from '../../styles/colors'
import { Column } from '../../styles/commonStyledComponents'
import { ApiFilterManager } from '../../utils/filters'

const Orders: FunctionComponent<Record<string, never>> = () => {
  const mergeOrdersGrouped = useCallback(
    (ordersA: OrdersGrouped, ordersB: OrdersGrouped): OrdersGrouped => {
      const keys: OrderState[] = [
        'to_pay',
        'to_pickup',
        'picking_up',
        'washing',
        'to_deliver',
        'delivering',
        'completed',
      ]
      const result: OrdersGrouped = {}

      forEach(keys, (key) => {
        result[key] = [...(ordersA[key] || []), ...(ordersB[key] || [])]
      })

      return result
    },
    []
  )

  const reducer = (state: OrdersState, action: OrdersAction): OrdersState => {
    switch (action.type) {
      case OrdersActionType.ORDERS_READ_REQUEST:
        return {
          ...state,
          readOrders: {
            ...state.readOrders,
            isLoading: true,
          },
        }
      case OrdersActionType.ORDERS_READ_SUCCESS:
        return {
          ...state,
          readOrders: {
            ...action.payload,
            orders:
              action.payload.currentPage > 1
                ? mergeOrdersGrouped(
                    state.readOrders.orders || {},
                    action.payload.orders || {}
                  )
                : action.payload.orders,
            isLoading: false,
          },
        }
      case OrdersActionType.ORDERS_READ_FAILURE:
        return {
          ...state,
          readOrders: ordersInitialState.readOrders,
        }
    }
  }

  const [state, dispatch] = useReducer(reducer, ordersInitialState)

  const {
    readOrders: {
      currentPage,
      deliveryCourierId,
      laundryId,
      orders,
      pickupCourierId,
      total,
    },
  } = state

  const {
    login: { token },
  } = useContext(AuthContext)

  const [isFirstLoad, setFirstLoad] = useState(true)
  const { t } = useTranslation()

  const readOrders = useCallback(
    async (
      page = 1,
      pickupCourierId?: number,
      deliveryCourierId?: number,
      laundryId?: number
    ) => {
      dispatch({ type: OrdersActionType.ORDERS_READ_REQUEST })

      try {
        // TODO: Define how the filters are passed to the BE
        const filterManager = new ApiFilterManager()

        if (!isUndefined(pickupCourierId)) {
          filterManager.add(
            'pickup_courier_id',
            '=',
            pickupCourierId.toString()
          )
        }

        if (!isUndefined(deliveryCourierId)) {
          filterManager.add(
            'delivery_courier_id',
            '=',
            deliveryCourierId.toString()
          )
        }

        if (!isUndefined(laundryId)) {
          filterManager.add('laundry_id', '=', laundryId.toString())
        }

        const url = filterManager.encode(
          `${serverUrl}/admin/orders?page=${page as number}`
        )
        const ordersList = await getApi<OrderShortList>(url, token)

        dispatch({
          type: OrdersActionType.ORDERS_READ_SUCCESS,
          payload: {
            currentPage: ordersList.current_page || 1,
            total: ordersList.total || 1,
            orders: ordersList.orders || {},
            pickupCourierId,
            deliveryCourierId,
            laundryId,
          },
        })
        return ordersList.orders
      } catch (err) {
        alertService.showAlert(err, 'error')
        dispatch({ type: OrdersActionType.ORDERS_READ_FAILURE })
        return undefined
      }
    },
    [token]
  )

  useEffect(() => {
    const readOrderAsync = async () => {
      if (readOrders) {
        await readOrders()
      }

      setFirstLoad(false)
    }

    void readOrderAsync()
  }, [])

  const wrapWithBasePage = (body: JSX.Element) => {
    return (
      <BasePage
        header={
          <OrdersHeader
            readOrders={readOrders}
            deliveryCourierId={deliveryCourierId}
            pickupCourierId={pickupCourierId}
            laundryId={laundryId}
          />
        }
        pageKey='orders'
      >
        {body}
      </BasePage>
    )
  }

  if (isFirstLoad) {
    return wrapWithBasePage(
      <CenterContainer>
        <Loader />
      </CenterContainer>
    )
  }

  if (!orders) {
    return wrapWithBasePage(<EmptySet description={t('orders_empty_set')} />)
  }

  const states = [
    'to_pay',
    'to_pickup',
    'picking_up',
    'washing',
    'to_deliver',
    'delivering',
    'completed',
  ]

  const fetchData = async () => {
    if (readOrders) {
      await readOrders(
        currentPage ? currentPage + 1 : 1,
        pickupCourierId,
        deliveryCourierId,
        laundryId
      )
    }
  }

  return wrapWithBasePage(
    <InfiniteScroll
      dataLength={total ? total : 1}
      next={fetchData}
      hasMore={currentPage < total}
      loader={<PaginationLabel>{t('orders_in_loading')}</PaginationLabel>}
      endMessage={<PaginationLabel>{t('all_orders_loaded')}</PaginationLabel>}
    >
      <Container>
        {map(states, (state: string) => {
          return (
            <StyledColumn key={state}>
              <SectionHeader key={state}>
                {get(stateEmoji, state)} {t(state)}
              </SectionHeader>
              {map(get(orders, state), (item: OrderShort) => (
                <StyledOrderCard key={item.order_id} order={item} />
              ))}
            </StyledColumn>
          )
        })}
      </Container>
    </InfiniteScroll>
  )
}

const StyledColumn = styled(Column)`
  align-items: center;
  flex-grow: 1;
  padding: 10px;
  border-right: 1px solid ${Colors.textColor_50};
  &:last-child {
    border-right: none;
  }
  min-width: 200px;
`

const StyledOrderCard = styled(OrderCard)`
  margin-bottom: 10px;
  margin-top: 10px;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
`

const SectionHeader = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  background-color: ${Colors.textColor_50};
  font-size: 20px;
  line-height: 23px;
  color: ${Colors.textColor_500};
  align-items: center;
  justify-content: center;
  border-radius: 6px;
`

const PaginationLabel = styled.p`
  text-align: center;
  font-weight: 400;
`

export default Orders
