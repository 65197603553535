/* eslint-disable react/prop-types */
import { Typography } from '@material-ui/core'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

import { CenterContainer } from '../CenterContainer'

export interface IEmptySetProps {
  description: string
}

const EmptySet: FunctionComponent<IEmptySetProps> = ({ description }) => {
  return (
    <CenterContainer alignItemsCenter={true}>
      <Typography variant='body1'>{description}</Typography>
      <SpacedImg src='/assets/png/empty_set.png' height={400} width={400} />
    </CenterContainer>
  )
}

const SpacedImg = styled.img`
  margin-top: 52px;
`

export default EmptySet
