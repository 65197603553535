import React, { useContext, useEffect } from 'react'

import { AuthContext } from '../../providers/AuthProvider'

const Logout = (): JSX.Element => {
  const { logout } = useContext(AuthContext)

  useEffect(() => {
    if (logout) {
      logout()
    }
  }, [])

  return <div />
}

export default Logout
