import { IconButton, withStyles } from '@material-ui/core'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Colors } from '../../../styles/colors'

interface ProductMenuProps {
  onEdit: () => void
  onDelete: () => void
}

const BasicMenu = ({ onDelete, onEdit }: ProductMenuProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { t } = useTranslation()
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        aria-controls='simple-menu'
        aria-haspopup='true'
        onClick={handleClick}
        style={{ padding: 0 }}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        MenuListProps={{
          style: {
            padding: `0px`,
          },
        }}
      >
        <StyledMenuItem
          onClick={() => {
            onEdit()
            handleClose()
          }}
        >
          <EditIcon style={{ fontSize: '16px', marginRight: '5px' }} />{' '}
          {t('edit')}
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            onDelete()
            handleClose()
          }}
          style={{ backgroundColor: Colors.red_500, color: Colors.white }}
        >
          <DeleteIcon style={{ fontSize: '16px', marginRight: '5px' }} />{' '}
          {t('delete')}
        </StyledMenuItem>
      </Menu>
    </>
  )
}

const StyledMenuItem = withStyles(() => ({
  root: {
    borderBottom: `1px solid ${Colors.textColor_50}`,
    '&:last-child': {
      borderBottom: 0,
    },
  },
}))(MenuItem)

export default BasicMenu
