import { get as getItem, isEmpty, isNull, isUndefined, map } from 'lodash'
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { deleteApi, get, post, put } from '../../Api/Api'
import { serverUrl } from '../../Api/api.config'
import { BasePage } from '../../components/Common/BasePage'
import { CenterContainer } from '../../components/Common/CenterContainer'
import { Dialog, DialogType } from '../../components/Common/Dialog'
import { EmptySet } from '../../components/Common/EmptySet'
import { Loader } from '../../components/Common/Loader'
import { DiscountCard } from '../../components/Discounts/DiscountCard'
import { DiscountDialog } from '../../components/Discounts/DiscountDialog'
import { DiscountsHeader } from '../../components/Discounts/DiscountsHeader'
import {
  CreateDiscountForm,
  Discount,
  DiscountsAction,
  DiscountsActionType,
  DiscountsGrouped,
  discountsInitialState,
  DiscountsState,
  DiscountType,
  EditDiscountForm,
} from '../../models/Discount'
import { alertService } from '../../providers/AlertProvider'
import { AuthContext } from '../../providers/AuthProvider'
import { Colors } from '../../styles/colors'
import { Column, Row } from '../../styles/commonStyledComponents'

const Discounts: FunctionComponent<Record<string, never>> = () => {
  const reducer = (
    state: DiscountsState,
    action: DiscountsAction
  ): DiscountsState => {
    switch (action.type) {
      case DiscountsActionType.DISCOUNTS_READ_REQUEST:
        return {
          ...state,
          readDiscounts: {
            ...state.readDiscounts,
            isLoading: true,
          },
        }
      case DiscountsActionType.DISCOUNTS_READ_SUCCESS:
        return {
          ...state,
          readDiscounts: {
            ...action.payload,
            isLoading: false,
          },
        }
      case DiscountsActionType.DISCOUNTS_READ_FAILURE:
        return {
          ...state,
          readDiscounts: discountsInitialState.readDiscounts,
        }
      case DiscountsActionType.DISCOUNT_CREATE_REQUEST:
        return {
          ...state,
          createDiscount: {
            ...state.createDiscount,
            isCreating: true,
          },
        }
      case DiscountsActionType.DISCOUNT_CREATE_SUCCESS:
        return {
          ...state,
          createDiscount: {
            ...action.payload,
            isCreating: false,
          },
        }
      case DiscountsActionType.DISCOUNT_CREATE_FAILURE:
        return {
          ...state,
          createDiscount: discountsInitialState.createDiscount,
        }
      case DiscountsActionType.DISCOUNT_EDIT_REQUEST:
        return {
          ...state,
          editDiscount: {
            ...state.editDiscount,
            isEditing: true,
          },
        }
      case DiscountsActionType.DISCOUNT_EDIT_SUCCESS:
        return {
          ...state,
          editDiscount: {
            ...action.payload,
            isEditing: false,
          },
        }
      case DiscountsActionType.DISCOUNT_EDIT_FAILURE:
        return {
          ...state,
          editDiscount: discountsInitialState.editDiscount,
        }
      case DiscountsActionType.DISCOUNT_DELETE_REQUEST:
        return {
          ...state,
          deleteDiscount: {
            ...state.deleteDiscount,
            isDeleting: true,
          },
        }
      case DiscountsActionType.DISCOUNT_DELETE_SUCCESS:
        return {
          ...state,
          deleteDiscount: {
            ...action.payload,
            isDeleting: false,
          },
        }
      case DiscountsActionType.DISCOUNT_DELETE_FAILURE:
        return {
          ...state,
          deleteDiscount: discountsInitialState.deleteDiscount,
        }
    }
  }

  const [state, dispatch] = useReducer(reducer, discountsInitialState)
  const {
    readDiscounts: { discounts },
  } = state

  const [dialogOpen, setDialogOpen] = useState(false)
  const [isEditMode, setEditMode] = useState(false)
  const [toEdit, setToEdit] = useState<Discount>()
  const [isFirstLoad, setFirstLoad] = useState(true)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [toDelete, setToDelete] = useState<Discount>()

  const { t } = useTranslation()

  const {
    login: { token },
  } = useContext(AuthContext)

  const readDiscounts = useCallback(async () => {
    dispatch({ type: DiscountsActionType.DISCOUNTS_READ_REQUEST })

    try {
      const discounts = await get<DiscountsGrouped>(
        `${serverUrl}/discounts`,
        token
      )

      dispatch({
        type: DiscountsActionType.DISCOUNTS_READ_SUCCESS,
        payload: { discounts },
      })
      return discounts
    } catch (err) {
      alertService.showAlert(err, 'error')
      dispatch({ type: DiscountsActionType.DISCOUNTS_READ_FAILURE })
      return undefined
    }
  }, [token])

  const createDiscount = useCallback(
    async (create: CreateDiscountForm) => {
      dispatch({ type: DiscountsActionType.DISCOUNT_CREATE_REQUEST })

      try {
        const createdDiscount = await post<Discount>(
          `${serverUrl}/discounts`,
          create,
          token
        )

        dispatch({
          type: DiscountsActionType.DISCOUNT_CREATE_SUCCESS,
          payload: { createdDiscount },
        })
        return createdDiscount
      } catch (err) {
        alertService.showAlert(err, 'error')
        dispatch({ type: DiscountsActionType.DISCOUNT_CREATE_FAILURE })
        return undefined
      }
    },
    [token]
  )

  const editDiscount = useCallback(
    async (id: number, edit: EditDiscountForm) => {
      dispatch({ type: DiscountsActionType.DISCOUNT_EDIT_REQUEST })

      try {
        const editedDiscount = await put<Discount>(
          `${serverUrl}/discounts/${id}`,
          edit,
          token
        )

        dispatch({
          type: DiscountsActionType.DISCOUNT_EDIT_SUCCESS,
          payload: { editedDiscount },
        })
        return editedDiscount
      } catch (err) {
        alertService.showAlert(err, 'error')
        dispatch({ type: DiscountsActionType.DISCOUNT_EDIT_FAILURE })
        return undefined
      }
    },
    [token]
  )

  const deleteDiscount = useCallback(
    async (id: number) => {
      dispatch({ type: DiscountsActionType.DISCOUNT_DELETE_REQUEST })

      try {
        await deleteApi(`${serverUrl}/discounts/${id}`, token)

        dispatch({
          type: DiscountsActionType.DISCOUNT_DELETE_SUCCESS,
          payload: { deletedId: id },
        })
      } catch (err) {
        alertService.showAlert(err, 'error')
        dispatch({ type: DiscountsActionType.DISCOUNT_DELETE_FAILURE })
      }
    },
    [token]
  )

  const onNewDiscountPressed = () => {
    setEditMode(false)
    setToEdit(undefined)
    setDialogOpen(true)
  }

  const handleClose = () => setDialogOpen(false)

  const onEdit = (discount: Discount) => {
    setEditMode(true)
    setToEdit(discount)
    setDialogOpen(true)
  }

  const onDelete = (discount: Discount) => {
    setToDelete(discount)
    setOpenDeleteModal(true)
  }

  const deleteDiscountAsync = async () => {
    if (deleteDiscount && toDelete) {
      await deleteDiscount(toDelete.discount_id)
      setOpenDeleteModal(false)
    }
  }

  useEffect(() => {
    const readDiscountsAsync = async () => {
      if (readDiscounts) {
        await readDiscounts()
      }

      setFirstLoad(false)
    }

    void readDiscountsAsync()
  }, [state.deleteDiscount.deletedId])

  const wrapWithBasePage = (body: JSX.Element) => {
    return (
      <BasePage
        header={<DiscountsHeader onNewDiscountPressed={onNewDiscountPressed} />}
        pageKey='discounts'
      >
        {body}
        <DiscountDialog
          open={dialogOpen}
          close={handleClose}
          isEditMode={isEditMode}
          toEdit={toEdit}
          readDiscounts={readDiscounts}
          createDiscount={createDiscount}
          editDiscount={editDiscount}
        />
        <Dialog
          title={`${t('delete')} ${t('discount').toLocaleLowerCase()}`}
          mode={DialogType.DELETE}
          open={openDeleteModal}
          onCancelPressed={() => setOpenDeleteModal(false)}
          onConfirmPressed={deleteDiscountAsync}
          onClose={() => setOpenDeleteModal(false)}
        >
          <ModalMessage>{t('delete_discount_text_modal')}</ModalMessage>
        </Dialog>
      </BasePage>
    )
  }

  if (isFirstLoad) {
    return wrapWithBasePage(
      <CenterContainer>
        <Loader />
      </CenterContainer>
    )
  }

  if (isNull(discounts) || isUndefined(discounts) || isEmpty(discounts)) {
    return wrapWithBasePage(<EmptySet description={t('discount_empty_set')} />)
  }

  const discountType: DiscountType[] = [
    'category',
    'service',
    'quantity',
    'product',
  ]

  return wrapWithBasePage(
    <Container>
      {map(discountType, (type: string) => {
        return (
          <StyledColumn key={type}>
            <SectionHeader>{t(`${type}_discount_title`)}</SectionHeader>
            {getItem(discounts, type) && (
              <div>
                {map(getItem(discounts, type), (item: Discount) => (
                  <StyledDiscountCard
                    key={item.discount_id}
                    discount={item}
                    onDelete={() => onDelete(item)}
                    onEdit={() => onEdit(item)}
                  />
                ))}
              </div>
            )}
          </StyledColumn>
        )
      })}
    </Container>
  )
}

const Container = styled(Row)`
  align-items: initial;
`

const StyledColumn = styled(Column)`
  align-items: center;
  padding: 10px;
  border-right: 1px solid ${Colors.textColor_50};
  &:last-child {
    border-right: none;
  }
  min-width: 250px;
`

const SectionHeader = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  background-color: ${Colors.textColor_50};
  font-size: 20px;
  line-height: 23px;
  color: ${Colors.textColor_500};
  align-items: center;
  justify-content: center;
  border-radius: 6px;
`

const StyledDiscountCard = styled(DiscountCard)`
  margin: 20px;
`

const ModalMessage = styled.div`
  white-space: pre-line;
`

export default Discounts
