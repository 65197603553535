import { createMuiTheme } from '@material-ui/core'

import { Colors } from './colors'

export const defaultTheme = createMuiTheme({
  typography: {
    button: {
      textTransform: 'none', // Sets the button text to not force the capitalization
    },
    h5: {
      fontWeight: 700,
    },
    allVariants: {
      color: Colors.textColor_500,
    },
  },
  palette: {
    text: {
      primary: Colors.textColor_500,
      secondary: Colors.white,
    },
    primary: {
      main: Colors.primaryColor_500,
      contrastText: Colors.white,
    },
    secondary: {
      main: Colors.white,
      contrastText: Colors.primaryColor_500,
    },
    error: {
      main: Colors.red_500,
      contrastText: Colors.white,
    },
    background: {
      paper: Colors.white,
    },
  },
})
