import { isNull, isUndefined } from 'lodash'
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useReducer,
} from 'react'
import { useTranslation } from 'react-i18next'

import { get, post } from '../../Api/Api'
import { serverUrl } from '../../Api/api.config'
import { Admin } from '../../models/Admin'
import { StorageKeys } from '../../utils/storageKeys'
import { alertService } from '../AlertProvider'

type ContextValue = {
  login: {
    token?: string | null
    login?: (email: string, password: string) => Promise<string | null>
    isLoading: boolean
  }
  getAdminData: {
    data?: Admin
    getAdminData?: (token: string) => Promise<Admin | null>
    isLoading: boolean
  }
  changePassword: {
    changePassword?: (
      currentPassword: string,
      newPassword: string
    ) => Promise<boolean>
    isLoading: boolean
  }
  logout?: () => void
}

type State = {
  login: {
    token?: string | null
    isLoading: boolean
  }
  getAdminData: {
    data?: Admin
    isLoading: boolean
  }
  changePassword: {
    isLoading: boolean
  }
}

enum ActionType {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  ADMIN_DATA_READ_REQUEST,
  ADMIN_DATA_READ_SUCCESS,
  ADMIN_DATA_READ_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  LOGOUT,
}

type Action =
  | { type: ActionType.LOGIN_REQUEST }
  | {
      type: ActionType.LOGIN_SUCCESS
      payload: { token?: string | null }
    }
  | { type: ActionType.LOGIN_FAILURE }
  | { type: ActionType.ADMIN_DATA_READ_REQUEST }
  | {
      type: ActionType.ADMIN_DATA_READ_SUCCESS
      payload: { data?: Admin }
    }
  | { type: ActionType.ADMIN_DATA_READ_FAILURE }
  | { type: ActionType.CHANGE_PASSWORD_REQUEST }
  | {
      type: ActionType.CHANGE_PASSWORD_SUCCESS
      payload: { token?: string | null }
    }
  | { type: ActionType.CHANGE_PASSWORD_FAILURE }
  | { type: ActionType.LOGOUT }

export const AuthContext = React.createContext<ContextValue>({
  login: { isLoading: false },
  getAdminData: { isLoading: false },
  changePassword: { isLoading: false },
})

const AuthProvider = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const initialState: State = {
    login: {
      token: localStorage.getItem(StorageKeys.token),
      isLoading: false,
    },
    getAdminData: {
      data: undefined,
      isLoading: false,
    },
    changePassword: {
      isLoading: false,
    },
  }

  const reducer = (state: State, action: Action): State => {
    switch (action.type) {
      case ActionType.LOGIN_REQUEST:
        return {
          ...state,
          login: {
            ...state.login,
            isLoading: true,
          },
        }
      case ActionType.LOGIN_SUCCESS:
        return {
          ...state,
          login: {
            ...action.payload,
            isLoading: false,
          },
        }
      case ActionType.LOGIN_FAILURE:
        return {
          ...state,
          login: {
            token: null,
            isLoading: false,
          },
          getAdminData: initialState.getAdminData,
        }
      case ActionType.ADMIN_DATA_READ_REQUEST:
        return {
          ...state,
          getAdminData: {
            ...state.getAdminData,
            isLoading: true,
          },
        }
      case ActionType.ADMIN_DATA_READ_SUCCESS:
        return {
          ...state,
          getAdminData: {
            ...action.payload,
            isLoading: false,
          },
        }
      case ActionType.ADMIN_DATA_READ_FAILURE:
        return {
          ...state,
          login: {
            token: null,
            isLoading: false,
          },
          getAdminData: initialState.getAdminData,
        }
      case ActionType.CHANGE_PASSWORD_REQUEST:
        return {
          ...state,
          changePassword: {
            ...state.changePassword,
            isLoading: true,
          },
        }
      case ActionType.CHANGE_PASSWORD_SUCCESS:
        return {
          ...state,
          login: {
            ...action.payload,
            isLoading: false,
          },
          changePassword: {
            isLoading: false,
          },
        }
      case ActionType.CHANGE_PASSWORD_FAILURE:
        return {
          ...state,
          changePassword: initialState.changePassword,
        }
      case ActionType.LOGOUT:
        return {
          ...state,
          login: {
            token: null,
            isLoading: false,
          },
          getAdminData: initialState.getAdminData,
        }
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)
  const { token } = state.login

  const { t } = useTranslation()

  // Whenever the token changes, save it in storage and fetch user data
  useEffect(() => {
    if (!isUndefined(token) && !isNull(token)) {
      localStorage.setItem(StorageKeys.token, token)

      void getAdminData(token)
    }
  }, [token])

  const login = useCallback(async (email: string, password: string) => {
    dispatch({ type: ActionType.LOGIN_REQUEST })

    try {
      const adminData = await post<Admin>(`${serverUrl}/admin/login`, {
        email,
        password,
      })

      dispatch({
        type: ActionType.LOGIN_SUCCESS,
        payload: { token: adminData.token },
      })
      return adminData.token
    } catch (err) {
      alertService.showAlert(err, 'error')
      dispatch({ type: ActionType.LOGIN_FAILURE })
      return null
    }
  }, [])

  const getAdminData = useCallback(async (token: string) => {
    dispatch({ type: ActionType.ADMIN_DATA_READ_REQUEST })

    try {
      const adminData = await get<Admin>(`${serverUrl}/admin/data`, token)

      dispatch({
        type: ActionType.ADMIN_DATA_READ_SUCCESS,
        payload: { data: adminData },
      })
      return adminData
    } catch (err) {
      alertService.showAlert(err, 'error')
      dispatch({ type: ActionType.ADMIN_DATA_READ_FAILURE })
      return null
    }
  }, [])

  const changePassword = useCallback(
    async (currentPassword: string, newPassword: string) => {
      dispatch({ type: ActionType.CHANGE_PASSWORD_REQUEST })

      try {
        const adminData = await post<Admin>(
          `${serverUrl}/admin/change_password`,
          {
            current_password: currentPassword,
            new_password: newPassword,
          },
          token
        )

        dispatch({
          type: ActionType.CHANGE_PASSWORD_SUCCESS,
          payload: { token: adminData.token },
        })

        alertService.showAlert(t('changed_password'), 'success')
        return true
      } catch (err) {
        alertService.showAlert(err, 'error')
        dispatch({ type: ActionType.CHANGE_PASSWORD_FAILURE })
        return false
      }
    },
    [token]
  )

  const logout = useCallback(() => {
    localStorage.removeItem(StorageKeys.token)
    dispatch({ type: ActionType.LOGOUT })
    return false
  }, [])

  return (
    <AuthContext.Provider
      value={{
        login: { ...state.login, login },
        getAdminData: { ...state.getAdminData, getAdminData },
        changePassword: { ...state.changePassword, changePassword },
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
