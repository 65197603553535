import axios, { AxiosError } from 'axios'
import { isNull, isUndefined } from 'lodash'

import { clientId } from './api.config'

type APIResponse<T> = {
  data: T
}

export const get = <T>(url: string, token?: string | null): Promise<T> => {
  return new Promise((resolve, reject) => {
    axios
      .get<APIResponse<T>>(url, {
        headers: generateHeaders(token),
      })
      .then(function (response) {
        resolve(response.data.data)
      })
      .catch(function (error: AxiosError) {
        reject(error.message)
      })
  })
}

export const post = <T>(
  url: string,
  body: Record<string, unknown>,
  token?: string | null
): Promise<T> => {
  return new Promise((resolve, reject) => {
    axios
      .post<APIResponse<T>>(url, body, {
        headers: generateHeaders(token),
      })
      .then(function (response) {
        resolve(response.data.data)
      })
      .catch(function (error: AxiosError) {
        reject(error.message)
      })
  })
}

// Can't call that 'delete' because there's an operator called like this
export const deleteApi = <T>(
  url: string,
  token?: string | null
): Promise<T> => {
  return new Promise((resolve, reject) => {
    axios
      .delete<APIResponse<T>>(url, {
        headers: generateHeaders(token),
      })
      .then(function (response) {
        resolve(response.data.data)
      })
      .catch(function (error: AxiosError) {
        reject(error.message)
      })
  })
}

export const put = <T>(
  url: string,
  body: Record<string, unknown>,
  token?: string | null
): Promise<T> => {
  return new Promise((resolve, reject) => {
    axios
      .put<APIResponse<T>>(url, body, {
        headers: generateHeaders(token),
      })
      .then(function (response) {
        resolve(response.data.data)
      })
      .catch(function (error: AxiosError) {
        reject(error.message)
      })
  })
}

export const getLanguage = (): string => {
  return (
    (navigator.languages?.length && navigator.languages[0]) ||
    navigator.language ||
    'it-IT'
  )
}

const generateHeaders = (token?: string | null | null) => {
  if (!isUndefined(token) && !isNull(token)) {
    return {
      Authorization: `Bearer ${token}`,
      clientid: clientId,
      'Content-type': 'application/json',
      'X-localization': getLanguage(),
    }
  } else {
    return {
      clientid: clientId,
      'Content-type': 'application/json',
      'X-localization': getLanguage(),
    }
  }
}
