import { Link, Typography } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const OrderDetailHeader = (): JSX.Element => {
  const { t } = useTranslation()

  const history = useHistory()

  const goBack = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    event.preventDefault()
    history.push('/orders')
  }

  return (
    <Container>
      <Link href='/orders' onClick={goBack} variant='h4'>
        {t('orders')}
      </Link>
      <ChevronRightIcon fontSize='large' />
      <Typography variant='h4'>{t('orderDetail')}</Typography>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
`

export default OrderDetailHeader
