import { DialogProps } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { find, isEmpty, isNull, isUndefined, map, omit } from 'lodash'
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { get } from '../../../Api/Api'
import { serverUrl } from '../../../Api/api.config'
import {
  Courier,
  CouriersAction,
  CouriersActionType,
  couriersInitialState,
  CouriersState,
} from '../../../models/Courier'
import {
  CreateScheduleForm,
  EditScheduleForm,
  Schedule,
  SchedulesGrouped,
  ScheduleType,
  Weekday,
} from '../../../models/Schedule'
import { alertService } from '../../../providers/AlertProvider'
import { AuthContext } from '../../../providers/AuthProvider'
import { Colors } from '../../../styles/colors'
import { SpaceBox } from '../../../styles/commonStyledComponents'
import { ApiFilterManager } from '../../../utils/filters'
import { Dialog, DialogType } from '../../Common/Dialog'
import { Selector } from '../../Common/Selector'
import { TextInput } from '../../Common/TextInput'

interface ScheduleDialogProps extends DialogProps {
  close: () => void
  isEditMode?: boolean
  toEdit?: Schedule
  className?: string
  editSchedule: (
    id: number,
    edit: EditScheduleForm
  ) => Promise<Schedule | undefined>
  createSchedule: (create: CreateScheduleForm) => Promise<Schedule | undefined>
  readSchedules: () => Promise<SchedulesGrouped | undefined>
}

const ScheduleDialog = ({
  className,
  close,
  createSchedule,
  editSchedule,
  isEditMode,
  readSchedules,
  toEdit,
  ...props
}: ScheduleDialogProps): JSX.Element => {
  const reducer = (
    state: CouriersState,
    action: CouriersAction
  ): CouriersState => {
    switch (action.type) {
      case CouriersActionType.COURIERS_READ_REQUEST:
        return {
          ...state,
          readCouriers: {
            ...state.readCouriers,
            isLoading: true,
          },
        }
      case CouriersActionType.COURIERS_READ_SUCCESS:
        return {
          ...state,
          readCouriers: {
            ...action.payload,
            isLoading: false,
          },
        }
      default:
      case CouriersActionType.COURIERS_READ_FAILURE:
        return {
          ...state,
          readCouriers: couriersInitialState.readCouriers,
        }
    }
  }

  const [state, dispatch] = useReducer(reducer, couriersInitialState)

  const {
    readCouriers: { couriers },
  } = state

  const {
    login: { token },
  } = useContext(AuthContext)

  const readCouriers = useCallback(
    async (search?: string) => {
      dispatch({ type: CouriersActionType.COURIERS_READ_REQUEST })

      try {
        const filterManager = new ApiFilterManager()

        if (!isUndefined(search) && !isNull(search) && !isEmpty(search)) {
          filterManager.add('name', 'like', search)
        }

        const url = filterManager.encode(`${serverUrl}/couriers`)

        const couriers = await get<Courier[]>(url, token)

        dispatch({
          type: CouriersActionType.COURIERS_READ_SUCCESS,
          payload: {
            couriers,
            searchedText: search,
          },
        })
        return couriers
      } catch (err) {
        alertService.showAlert(err, 'error')
        dispatch({ type: CouriersActionType.COURIERS_READ_FAILURE })
        return undefined
      }
    },
    [token]
  )

  const { t } = useTranslation()

  const [form, setForm] = useState<CreateScheduleForm | EditScheduleForm>(
    isEditMode && toEdit
      ? {
          type: toEdit.type || 'pickup_day',
          courier_id: toEdit.courier_id || undefined,
          week_day: toEdit.week_day || undefined,
        }
      : {
          type: 'pickup_day',
          courier_id: undefined,
          week_day: undefined,
        }
  )
  const [errors, setErrors] = useState<{ [key: string]: string }>({})

  useEffect(() => {
    void readCouriers()
  }, [])

  // When the dialog is opened/closed, reset the state
  useEffect(() => {
    if (props.open) {
      setErrors({})
      setForm(
        isEditMode && toEdit
          ? {
              type: toEdit.type || 'pickup_day',
              courier_id: toEdit.courier_id || undefined,
              week_day: toEdit.week_day || undefined,
            }
          : {
              type: 'pickup_day',
              courier_id: undefined,
              week_day: undefined,
            }
      )
    }
  }, [props.open, isEditMode, toEdit])

  // It sucks, I know, but I didn't want to make it better, just collapse the function body and don't look inside ;)
  const validate = () => {
    let hasErrors = false

    // Courier
    if (isUndefined(form.courier_id)) {
      setErrors((prev) => ({ ...prev, courier: t('schedule_courier_empty') }))
      hasErrors = true
    } else {
      setErrors((prev) => omit(prev, ['courier']))
    }

    // Weekday
    if (isUndefined(form.week_day)) {
      setErrors((prev) => ({ ...prev, week_day: t('schedule_weekday_empty') }))
      hasErrors = true
    } else {
      setErrors((prev) => omit(prev, ['week_day']))
    }

    return !hasErrors
  }

  const onConfirmPressed = async () => {
    if (!validate()) {
      return
    }

    if (isEditMode && toEdit) {
      const edited = await editSchedule(toEdit.schedule_id, form)
      if (edited) {
        close()
      }
    } else {
      const created = await createSchedule(form as CreateScheduleForm)
      if (created) {
        close()
      }
    }

    void readSchedules()
  }

  const weekdays: Weekday[] = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ]

  return (
    <Dialog
      {...props}
      className={className}
      title={isEditMode && toEdit ? t('edit_schedule') : t('new_schedule')}
      mode={isEditMode && toEdit ? DialogType.EDIT : DialogType.CREATE}
      onClose={close}
      onCancelPressed={close}
      onConfirmPressed={onConfirmPressed}
      fullWidth
    >
      <Selector<ScheduleType>
        options={['pickup_day', 'delivery_day']}
        labelExtractor={(type) => t(type)}
        onSelect={(type) => !isUndefined(type) && setForm({ ...form, type })}
        selected={form.type}
        mandatory={true}
      />
      <SpaceBox height={24} />
      <Autocomplete
        getOptionLabel={(id) =>
          find(couriers, (courier) => courier.courier_id === id)?.name || ''
        }
        options={map(couriers, (courier) => courier.courier_id)}
        autoComplete
        value={form.courier_id}
        onChange={(_, courier_id) => {
          if (courier_id) {
            setForm({ ...form, courier_id })
          }
        }}
        renderInput={(params) => (
          <StyledTextInput {...params} label={t('pick_courier')} fullWidth />
        )}
      />
      <SpaceBox height={8} />
      <Error>{errors['courier']}</Error>
      <SpaceBox height={24} />
      <Autocomplete
        getOptionLabel={(weekday) => t(weekday)}
        options={weekdays}
        autoComplete
        value={form.week_day}
        onChange={(_, week_day) => {
          if (week_day) {
            setForm({ ...form, week_day })
          }
        }}
        renderInput={(params) => (
          <StyledTextInput {...params} label={t('pick_week_day')} fullWidth />
        )}
      />
      <SpaceBox height={8} />
      <Error>{errors['week_day']}</Error>
      {isEditMode && (
        <>
          <SpaceBox height={24} />
          <EditWarning>
            <Important>{t('important_warning_label')}</Important>
            <span>{t('schedule_edit_warning')}</span>
          </EditWarning>
        </>
      )}
    </Dialog>
  )
}

const StyledTextInput = styled(TextInput)`
  width: 100%;
`

const Error = styled.p`
  font-size: 14px;
  margin: 0px;
  color: ${Colors.red_500};
`

const EditWarning = styled.p`
  font-size: 14px;
  color: ${Colors.textColor_400};
`

const Important = styled.span`
  font-size: 14px;
  color: ${Colors.red_500};
`

export default ScheduleDialog
