import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Courier } from '../../../models/Courier'
import { Schedule } from '../../../models/Schedule'
import { Colors } from '../../../styles/colors'
import {
  Column,
  Row,
  SpaceBox,
  SpacedRow,
} from '../../../styles/commonStyledComponents'
import { BasicMenu } from '../../Common/BasicMenu'

interface CourierCardProps {
  courier: Courier | Schedule
  className?: string
  onDelete: () => void
  onEdit: () => void
}

const CourierCard = (props: CourierCardProps): JSX.Element => {
  const { className, courier, onDelete, onEdit } = props
  const { email, name, phone } = courier
  const { t } = useTranslation()

  return (
    <Container className={className}>
      <Section>
        <Column>
          <Name>{name}</Name>
        </Column>
        <Column>
          <BasicMenu onDelete={onDelete} onEdit={onEdit} />
        </Column>
      </Section>
      <Divider />
      <ContactContainer>
        <SpacedRow>
          <ContactLabel>{t('contacts')}</ContactLabel>
          <SpaceBox height={5} />
        </SpacedRow>
        <InfoBox>
          <Link href={`mailto:${email}`}>{email}</Link>
        </InfoBox>
        <SpaceBox height={5} />
        <InfoBox>
          <Link href={`tel:${phone}`}>{phone}</Link>
        </InfoBox>
      </ContactContainer>
    </Container>
  )
}

const Container = styled(Column)`
  width: 300px;
  background: ${Colors.white};
  border: 1px solid ${Colors.textColor_50};
  box-sizing: border-box;
  box-shadow: 0px 8px 8px ${Colors.textColor_50};
  border-radius: 6px;
`

const ContactContainer = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
`

const Section = styled(SpacedRow)`
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
`

const Name = styled(Row)`
  word-break: break-word;
  font-size: 18px;
  line-height: 23px;
  color: ${Colors.textColor_500};
`

const ContactLabel = styled(Column)`
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: ${Colors.textColor_300};
`

const Link = styled.a`
  color: ${Colors.primaryColor_500};
`

const InfoBox = styled(Row)`
  word-break: break-word;
`

const Divider = styled.div`
  align-self: stretch;
  height: 1px;
  background-color: ${Colors.textColor_50};
`

export default CourierCard
