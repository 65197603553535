import { Typography } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { find, isEmpty, isNull, isUndefined, map } from 'lodash'
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { get } from '../../../Api/Api'
import { serverUrl } from '../../../Api/api.config'
import {
  Courier,
  CouriersAction,
  CouriersActionType,
  couriersInitialState,
  CouriersState,
} from '../../../models/Courier'
import {
  LaundriesAction,
  LaundriesActionType,
  laundriesInitialState,
  LaundriesState,
  Laundry,
} from '../../../models/Laundry'
import { OrdersGrouped } from '../../../models/Order'
import { alertService } from '../../../providers/AlertProvider'
import { AuthContext } from '../../../providers/AuthProvider'
import { Row, SpaceBox } from '../../../styles/commonStyledComponents'
import { ApiFilterManager } from '../../../utils/filters'
import { TextInput } from '../../Common/TextInput'

export interface IOrdersHeaderProps {
  pickupCourierId?: number
  deliveryCourierId?: number
  laundryId?: number
  readOrders: (
    page?: number,
    pickupCourierId?: number,
    deliveryCourierId?: number,
    laundryId?: number
  ) => Promise<OrdersGrouped | undefined>
}

const OrdersHeader: FunctionComponent<IOrdersHeaderProps> = (
  props: IOrdersHeaderProps
) => {
  const { deliveryCourierId, laundryId, pickupCourierId, readOrders } = props

  const couriersReducer = (
    state: CouriersState,
    action: CouriersAction
  ): CouriersState => {
    switch (action.type) {
      case CouriersActionType.COURIERS_READ_REQUEST:
        return {
          ...state,
          readCouriers: {
            ...state.readCouriers,
            isLoading: true,
          },
        }
      case CouriersActionType.COURIERS_READ_SUCCESS:
        return {
          ...state,
          readCouriers: {
            ...action.payload,
            isLoading: false,
          },
        }
      default:
      case CouriersActionType.COURIERS_READ_FAILURE:
        return {
          ...state,
          readCouriers: couriersInitialState.readCouriers,
        }
    }
  }

  const laundriesReducer = (
    state: LaundriesState,
    action: LaundriesAction
  ): LaundriesState => {
    switch (action.type) {
      case LaundriesActionType.LAUNDRIES_READ_REQUEST:
        return {
          ...state,
          readLaundries: {
            ...state.readLaundries,
            isLoading: true,
          },
        }
      case LaundriesActionType.LAUNDRIES_READ_SUCCESS:
        return {
          ...state,
          readLaundries: {
            ...action.payload,
            isLoading: false,
          },
        }
      default:
      case LaundriesActionType.LAUNDRIES_READ_FAILURE:
        return {
          ...state,
          readLaundries: laundriesInitialState.readLaundries,
        }
    }
  }

  const [couriersState, dispatchCouriers] = useReducer(
    couriersReducer,
    couriersInitialState
  )
  const [laundriesState, dispatchLaundries] = useReducer(
    laundriesReducer,
    laundriesInitialState
  )

  const {
    readCouriers: { couriers },
  } = couriersState
  const {
    readLaundries: { laundries },
  } = laundriesState

  const {
    login: { token },
  } = useContext(AuthContext)

  const readCouriers = useCallback(
    async (search?: string) => {
      dispatchCouriers({ type: CouriersActionType.COURIERS_READ_REQUEST })

      try {
        const filterManager = new ApiFilterManager()

        if (!isUndefined(search) && !isNull(search) && !isEmpty(search)) {
          filterManager.add('name', 'like', search)
        }

        const url = filterManager.encode(`${serverUrl}/couriers`)

        const couriers = await get<Courier[]>(url, token)

        dispatchCouriers({
          type: CouriersActionType.COURIERS_READ_SUCCESS,
          payload: {
            couriers,
            searchedText: search,
          },
        })
        return couriers
      } catch (err) {
        alertService.showAlert(err, 'error')
        dispatchCouriers({ type: CouriersActionType.COURIERS_READ_FAILURE })
        return undefined
      }
    },
    [token]
  )

  const readLaundries = useCallback(
    async (search?: string) => {
      dispatchLaundries({ type: LaundriesActionType.LAUNDRIES_READ_REQUEST })

      try {
        const filterManager = new ApiFilterManager()

        if (!isUndefined(search) && !isNull(search) && !isEmpty(search)) {
          filterManager.add('name', 'like', search)
        }

        const url = filterManager.encode(`${serverUrl}/laundries`)
        const laundries = await get<Laundry[]>(url, token)

        dispatchLaundries({
          type: LaundriesActionType.LAUNDRIES_READ_SUCCESS,
          payload: {
            laundries,
            searchedText: search,
          },
        })
        return laundries
      } catch (err) {
        alertService.showAlert(err, 'error')
        dispatchLaundries({ type: LaundriesActionType.LAUNDRIES_READ_FAILURE })
        return undefined
      }
    },
    [token]
  )

  useEffect(() => {
    void readCouriers()
    void readLaundries()
  }, [])

  const { t } = useTranslation()

  const filterPickupCourier = (courierId?: number) => {
    if (readOrders) {
      void readOrders(1, courierId, deliveryCourierId, laundryId)
    }
  }

  const filterDeliveryCourier = (courierId?: number) => {
    if (readOrders) {
      void readOrders(1, pickupCourierId, courierId, laundryId)
    }
  }

  const filterLaundry = (laundryId?: number) => {
    if (readOrders) {
      void readOrders(1, pickupCourierId, deliveryCourierId, laundryId)
    }
  }

  return (
    <StyledRow>
      <Typography variant='h4'>{t('orders')}</Typography>
      <SpaceBox width={32} />
      <Autocomplete
        getOptionLabel={(id) =>
          find(couriers, (courier) => courier.courier_id === id)?.name || ''
        }
        options={map(couriers, (courier) => courier.courier_id)}
        autoComplete
        value={pickupCourierId}
        onChange={(_, courierId) => {
          filterPickupCourier(courierId || undefined)
        }}
        renderInput={(params) => (
          <StyledTextInput
            {...params}
            placeholder={t('pickup_courier_label')}
            fullWidth
          />
        )}
        size='small'
      />
      <SpaceBox width={32} />
      <Autocomplete
        getOptionLabel={(id) =>
          find(couriers, (courier) => courier.courier_id === id)?.name || ''
        }
        options={map(couriers, (courier) => courier.courier_id)}
        autoComplete
        value={deliveryCourierId}
        onChange={(_, courierId) => {
          filterDeliveryCourier(courierId || undefined)
        }}
        renderInput={(params) => (
          <StyledTextInput
            {...params}
            placeholder={t('delivery_courier_label')}
            fullWidth
          />
        )}
        size='small'
      />
      <SpaceBox width={32} />
      <Autocomplete
        getOptionLabel={(id) =>
          find(laundries, (laundry) => laundry.laundry_id === id)?.name || ''
        }
        options={map(laundries, (laundry) => laundry.laundry_id)}
        autoComplete
        value={laundryId}
        onChange={(_, laundryId) => {
          filterLaundry(laundryId || undefined)
        }}
        renderInput={(params) => (
          <StyledTextInput {...params} placeholder={t('laundry')} fullWidth />
        )}
        size='small'
      />
    </StyledRow>
  )
}

const StyledRow = styled(Row)`
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
`

const StyledTextInput = styled(TextInput)`
  width: 250px;
`

export default OrdersHeader
