import { createMuiTheme, ThemeProvider, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  Order,
  OrderedProduct,
  stateColors,
  stateShadowColors,
} from '../../../models/Order'
import { serviceSvgs } from '../../../models/Service'
import { Colors } from '../../../styles/colors'
import { Column, Row, SpaceBox } from '../../../styles/commonStyledComponents'
import { pricify } from '../../../utils/formatting'

interface OrderedProductCardProps {
  order: Order
  orderedProduct: OrderedProduct
  className?: string
}

const OrderedProductCard = ({
  className,
  order,
  orderedProduct,
}: OrderedProductCardProps): JSX.Element => {
  const { state } = order
  const {
    discount,
    name,
    original_price,
    price,
    quantity,
    service,
  } = orderedProduct
  const { t } = useTranslation()
  const color = stateColors[state] || Colors.textColor_500
  const shadowColor = stateShadowColors[state] || Colors.textColor_50
  const SvgIcon = serviceSvgs[service]
  const serviceDescription = t(`service_${service}`)
  const originalTotalPrice = pricify(original_price * quantity)
  const totalPrice = pricify(price * quantity)

  const pricesTheme = createMuiTheme({
    typography: {
      allVariants: {
        fontWeight: 500,
      },
    },
    palette: {
      primary: {
        main: color,
      },
      secondary: {
        main: Colors.textColor_100,
      },
    },
  })

  const infoTheme = createMuiTheme({
    typography: {
      allVariants: {
        fontWeight: 400,
      },
    },
    palette: {
      text: {
        primary: Colors.textColor_500,
      },
      primary: {
        main: color,
      },
      secondary: {
        main: Colors.textColor_400,
      },
    },
  })

  return (
    <Container color={color} shadowColor={shadowColor} className={className}>
      <ThemeProvider theme={infoTheme}>
        <Column>
          <Row>
            <SvgIcon height='16px' width='16px' fill={color} />
            <SpaceBox width={8} />
            <Typography variant='subtitle1' color='secondary'>
              {serviceDescription}
            </Typography>
          </Row>
          <Row>
            <Typography variant='h6'>{name}</Typography>
            <SpaceBox width={4} />
            {quantity > 1 && (
              <QuantityContainer>
                <Typography variant='subtitle1' color='primary'>
                  {quantity}
                </Typography>
              </QuantityContainer>
            )}
          </Row>
        </Column>
      </ThemeProvider>
      <ThemeProvider theme={pricesTheme}>
        <Row>
          {discount && (
            <OriginalPrice variant='h6' color='secondary'>
              {originalTotalPrice}
            </OriginalPrice>
          )}

          <Typography variant='h6' color='primary'>
            {totalPrice}
          </Typography>
        </Row>
      </ThemeProvider>
    </Container>
  )
}

interface StyleProps {
  color?: string
  shadowColor?: string
}

const Container = styled.div<StyleProps>`
  width: 410px;
  background: ${Colors.white};
  border: 1px solid ${Colors.textColor_50};
  box-sizing: border-box;
  box-shadow: 0px 8px 8px ${(props) => props.shadowColor || Colors.textColor_50};
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  color: ${Colors.textColor_500};
  font-size: 18px;
`

const QuantityContainer = styled.div<StyleProps>`
  height: 22px;
  width: 22px;
  border-radius: 11px;
  background-color: ${(props) => props.color || Colors.textColor_50};
  display: flex;
  justify-content: center;
  align-items: center;
`

const OriginalPrice = styled(Typography)<StyleProps>`
  text-decoration: line-through;
  margin-right: 16px !important;
`

export default OrderedProductCard
