/* eslint-disable react/prop-types */
import {
  BaseTextFieldProps,
  OutlinedInputProps,
  TextField,
  withStyles,
} from '@material-ui/core'
import { FormControl, InputLabel } from '@material-ui/core'
import React, { FunctionComponent } from 'react'

import { Colors } from '../../../styles/colors'

export interface ITextInputProps extends BaseTextFieldProps {
  label?: string
  onBlur?: OutlinedInputProps['onBlur']
  onChange?: OutlinedInputProps['onChange']
  onFocus?: OutlinedInputProps['onFocus']
  InputProps?: Partial<OutlinedInputProps>
  inputProps?: OutlinedInputProps['inputProps']
}

const TextInput: FunctionComponent<ITextInputProps> = ({
  className,
  id,
  label,
  ...props
}) => {
  return (
    <FormControl className={className}>
      {!!label && (
        <StyledInputLabel shrink htmlFor={id}>
          {label}
        </StyledInputLabel>
      )}
      <BootstrapField {...props} variant='outlined' />
    </FormControl>
  )
}

const StyledInputLabel = withStyles(() => ({
  root: {
    color: Colors.textColor_400,
  },
  focused: {
    color: `${Colors.textColor_500} !important`,
  },
}))(InputLabel)

const BootstrapField = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: Colors.white,
    border: `1px solid ${Colors.textColor_100}`,
    fontSize: 16,
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderColor: Colors.textColor_500,
    },
  },
}))(TextField)

export default TextInput
