import moment from 'moment'
import React from 'react'
import { Trans } from 'react-i18next'
import styled from 'styled-components'

import { Order } from '../../../models/Order'
import { Colors } from '../../../styles/colors'

export enum CourierDescriptionType {
  pickup,
  delivery,
}

interface CourierDescriptionProps {
  order: Order
  type: CourierDescriptionType
  className?: string
}

const CourierDescription = ({
  className,
  order,
  type,
}: CourierDescriptionProps): JSX.Element => {
  const { delivery_date, pickup_date, scheduled_delivery_date, state } = order

  const getDescription = (): JSX.Element => {
    switch (type) {
      case CourierDescriptionType.pickup: {
        switch (state) {
          case 'to_pay': {
            return <div />
          }
          case 'to_pickup': {
            const date = moment(pickup_date).format('MM/DD/YYYY')
            return (
              <Trans
                i18nKey='future_pickup_description'
                values={{ date }}
                components={{ bold: <strong /> }}
              />
            )
          }
          default: {
            const date = moment(pickup_date).format('MM/DD/YYYY')
            return (
              <Trans
                i18nKey='past_pickup_description'
                values={{ date }}
                components={{ bold: <strong /> }}
              />
            )
          }
        }
      }
      case CourierDescriptionType.delivery: {
        switch (state) {
          case 'to_pay':
          case 'to_pickup':
          case 'picking_up':
          case 'washing': {
            return <div />
          }
          case 'to_deliver':
          case 'delivering': {
            const date = moment(scheduled_delivery_date).format('MM/DD/YYYY')
            return (
              <Trans
                i18nKey='future_delivery_description'
                values={{ date }}
                components={{ bold: <strong /> }}
              />
            )
          }
          case 'completed': {
            const date = moment(delivery_date).format('MM/DD/YYYY')
            return (
              <Trans
                i18nKey='past_delivery_description'
                values={{ date }}
                components={{ bold: <strong /> }}
              />
            )
          }
        }
      }
    }
  }

  return <Label className={className}>{getDescription()}</Label>
}

const Label = styled.span`
  font-size: 18px;
  line-height: 21px;
  color: ${Colors.textColor_400};
`

export default CourierDescription
