/* eslint-disable react/prop-types */
import {
  Button as MuiButton,
  ButtonProps,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core'
import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

import { Colors } from '../../../styles/colors'

export enum ButtonType {
  PRIMARY,
  SUCCESS_MAIN,
  ALERT,
}

export interface IButtonProps extends ButtonProps {
  mode: ButtonType
  label: string
  fullWidth?: boolean
}

const Button: FunctionComponent<IButtonProps> = ({
  fullWidth = false,
  label,
  mode,
  ...props
}) => {
  const theme = createMuiTheme({
    palette: {
      primary: {
        main:
          mode === ButtonType.PRIMARY
            ? Colors.primaryColor_500
            : mode === ButtonType.SUCCESS_MAIN
            ? Colors.green_500
            : Colors.red_500,
        contrastText: Colors.white,
      },
    },
  })

  const Btn = fullWidth ? FullWidthButton : MuiButton

  return (
    <ThemeProvider theme={theme}>
      <Btn
        {...props}
        color='primary'
        variant={mode === ButtonType.ALERT ? 'text' : 'contained'}
        disableElevation
      >
        {label}
      </Btn>
    </ThemeProvider>
  )
}

export const FullWidthButton = styled(MuiButton)`
  width: 100%;
`

export default Button
