/* eslint-disable react/prop-types */
import {
  InputAdornment,
  InputBase,
  InputBaseProps,
  withStyles,
} from '@material-ui/core'
import { Search } from '@material-ui/icons'
import React, { FunctionComponent } from 'react'

import { Colors } from '../../../styles/colors'

export interface ISearchBarProps extends InputBaseProps {
  hint?: string
}

const SearchBar: FunctionComponent<ISearchBarProps> = ({ hint, ...props }) => {
  return (
    <BootstrapInput
      {...props}
      placeholder={hint}
      startAdornment={
        <InputAdornment position='start'>
          <Search fontSize='small' />
        </InputAdornment>
      }
    />
  )
}

const BootstrapInput = withStyles(() => ({
  root: {
    borderRadius: 4,
    position: 'relative',
    color: Colors.textColor_400,
    backgroundColor: Colors.textColor_50,
    fontSize: 16,
    height: 42,
    width: 300,
    padding: '10px 12px',
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
}))(InputBase)

export default SearchBar
