import { isEmpty, isNull, isUndefined, map } from 'lodash'
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { deleteApi, get, post, put } from '../../Api/Api'
import { serverUrl } from '../../Api/api.config'
import { BasePage } from '../../components/Common/BasePage'
import { CenterContainer } from '../../components/Common/CenterContainer'
import { Dialog, DialogType } from '../../components/Common/Dialog'
import { EmptySet } from '../../components/Common/EmptySet'
import { Loader } from '../../components/Common/Loader'
import { CourierCard } from '../../components/Courier/CourierCard'
import { CourierDialog } from '../../components/Courier/CourierDialog'
import { CouriersHeader } from '../../components/Courier/CouriersHeader'
import {
  Courier,
  CouriersAction,
  CouriersActionType,
  couriersInitialState,
  CouriersState,
  CreateCourierForm,
  EditCourierForm,
} from '../../models/Courier'
import { alertService } from '../../providers/AlertProvider'
import { AuthContext } from '../../providers/AuthProvider'
import { Colors } from '../../styles/colors'
import { Row } from '../../styles/commonStyledComponents'
import { ApiFilterManager } from '../../utils/filters'

const Couriers: FunctionComponent<Record<string, never>> = () => {
  const reducer = (
    state: CouriersState,
    action: CouriersAction
  ): CouriersState => {
    switch (action.type) {
      case CouriersActionType.COURIERS_READ_REQUEST:
        return {
          ...state,
          readCouriers: {
            ...state.readCouriers,
            isLoading: true,
          },
        }
      case CouriersActionType.COURIERS_READ_SUCCESS:
        return {
          ...state,
          readCouriers: {
            ...action.payload,
            isLoading: false,
          },
        }
      case CouriersActionType.COURIERS_READ_FAILURE:
        return {
          ...state,
          readCouriers: couriersInitialState.readCouriers,
        }
      case CouriersActionType.COURIER_CREATE_REQUEST:
        return {
          ...state,
          createCourier: {
            ...state.createCourier,
            isCreating: true,
          },
        }
      case CouriersActionType.COURIER_CREATE_SUCCESS:
        return {
          ...state,
          createCourier: {
            ...action.payload,
            isCreating: false,
          },
        }
      case CouriersActionType.COURIER_CREATE_FAILURE:
        return {
          ...state,
          createCourier: couriersInitialState.createCourier,
        }
      case CouriersActionType.COURIER_EDIT_REQUEST:
        return {
          ...state,
          editCourier: {
            ...state.editCourier,
            isEditing: true,
          },
        }
      case CouriersActionType.COURIER_EDIT_SUCCESS:
        return {
          ...state,
          editCourier: {
            ...action.payload,
            isEditing: false,
          },
        }
      case CouriersActionType.COURIER_EDIT_FAILURE:
        return {
          ...state,
          editCourier: couriersInitialState.editCourier,
        }
      case CouriersActionType.COURIER_DELETE_REQUEST:
        return {
          ...state,
          deleteCourier: {
            ...state.deleteCourier,
            isDeleting: true,
          },
        }
      case CouriersActionType.COURIER_DELETE_SUCCESS:
        return {
          ...state,
          deleteCourier: {
            ...action.payload,
            isDeleting: false,
          },
        }
      case CouriersActionType.COURIER_DELETE_FAILURE:
        return {
          ...state,
          deleteCourier: couriersInitialState.deleteCourier,
        }
    }
  }

  const [state, dispatch] = useReducer(reducer, couriersInitialState)

  const {
    readCouriers: { couriers },
  } = state

  const [dialogOpen, setDialogOpen] = useState(false)
  const [isEditMode, setEditMode] = useState(false)
  const [toEdit, setToEdit] = useState<Courier>()
  const [isFirstLoad, setFirstLoad] = useState(true)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [toDelete, setToDelete] = useState<Courier>()
  const { t } = useTranslation()

  const {
    login: { token },
  } = useContext(AuthContext)

  const readCouriers = useCallback(
    async (search?: string) => {
      dispatch({ type: CouriersActionType.COURIERS_READ_REQUEST })

      try {
        const filterManager = new ApiFilterManager()

        if (!isUndefined(search) && !isNull(search) && !isEmpty(search)) {
          filterManager.add('name', 'like', search)
        }

        const url = filterManager.encode(`${serverUrl}/couriers`)

        const couriers = await get<Courier[]>(url, token)

        dispatch({
          type: CouriersActionType.COURIERS_READ_SUCCESS,
          payload: {
            couriers,
            searchedText: search,
          },
        })
        return couriers
      } catch (err) {
        alertService.showAlert(err, 'error')
        dispatch({ type: CouriersActionType.COURIERS_READ_FAILURE })
        return undefined
      }
    },
    [token]
  )

  const createCourier = useCallback(
    async (create: CreateCourierForm) => {
      dispatch({ type: CouriersActionType.COURIER_CREATE_REQUEST })

      try {
        const createdCourier = await post<Courier>(
          `${serverUrl}/couriers`,
          create,
          token
        )

        dispatch({
          type: CouriersActionType.COURIER_CREATE_SUCCESS,
          payload: {
            createdCourier,
          },
        })
        return createdCourier
      } catch (err) {
        alertService.showAlert(err, 'error')
        dispatch({ type: CouriersActionType.COURIER_CREATE_FAILURE })
        return undefined
      }
    },
    [token]
  )

  const editCourier = useCallback(
    async (id: number, edit: EditCourierForm) => {
      dispatch({ type: CouriersActionType.COURIER_EDIT_REQUEST })

      try {
        const editedCourier = await put<Courier>(
          `${serverUrl}/couriers/${id}`,
          edit,
          token
        )

        dispatch({
          type: CouriersActionType.COURIER_EDIT_SUCCESS,
          payload: {
            editedCourier,
          },
        })
        return editedCourier
      } catch (err) {
        alertService.showAlert(err, 'error')
        dispatch({ type: CouriersActionType.COURIER_EDIT_FAILURE })
        return undefined
      }
    },
    [token]
  )

  const deleteCourier = useCallback(
    async (id: number) => {
      dispatch({ type: CouriersActionType.COURIER_DELETE_REQUEST })

      try {
        await deleteApi(`${serverUrl}/couriers/${id}`, token)

        dispatch({
          type: CouriersActionType.COURIER_DELETE_SUCCESS,
          payload: {
            deletedId: id,
          },
        })
      } catch (err) {
        alertService.showAlert(err, 'error')
        dispatch({ type: CouriersActionType.COURIER_DELETE_FAILURE })
      }
    },
    [token]
  )

  useEffect(() => {
    const readCouriersAsync = async () => {
      if (readCouriers) {
        await readCouriers()
      }

      setFirstLoad(false)
    }

    void readCouriersAsync()
  }, [state.deleteCourier.deletedId])

  const deleteCourierAsync = async () => {
    if (deleteCourier && toDelete) {
      await deleteCourier(toDelete.courier_id)
      setOpenDeleteModal(false)
    }
  }

  const handleClose = () => setDialogOpen(false)

  const onNewCourierPressed = () => {
    setEditMode(false)
    setToEdit(undefined)
    setDialogOpen(true)
  }

  const onEdit = (courier: Courier) => {
    setEditMode(true)
    setToEdit(courier)
    setDialogOpen(true)
  }

  const onDelete = (courier: Courier) => {
    setToDelete(courier)
    setOpenDeleteModal(true)
  }

  const wrapWithBasePage = (body: JSX.Element) => {
    return (
      <BasePage
        header={
          <CouriersHeader
            onNewCourierPressed={onNewCourierPressed}
            readCouriers={readCouriers}
          />
        }
        pageKey='couriers'
      >
        {body}
        <CourierDialog
          open={dialogOpen}
          close={handleClose}
          isEditMode={isEditMode}
          toEdit={toEdit}
          readCouriers={readCouriers}
          createCourier={createCourier}
          editCourier={editCourier}
        />
        <Dialog
          title={`${t('delete')} ${t('courier').toLocaleLowerCase()}`}
          mode={DialogType.DELETE}
          open={openDeleteModal}
          onCancelPressed={() => setOpenDeleteModal(false)}
          onConfirmPressed={deleteCourierAsync}
          onClose={() => setOpenDeleteModal(false)}
        >
          <ModalMessage>
            {t('delete_courier_text_modal')}
            <br />
            <br />
            <DangerText>{t('important')}</DangerText>:{' '}
            {t('delete_courier_important_text_modal')}
          </ModalMessage>
        </Dialog>
      </BasePage>
    )
  }

  if (isFirstLoad) {
    return wrapWithBasePage(
      <CenterContainer>
        <Loader />
      </CenterContainer>
    )
  }

  if (isNull(couriers) || isUndefined(couriers) || isEmpty(couriers)) {
    return wrapWithBasePage(<EmptySet description={t('couriers_empty_set')} />)
  }

  return wrapWithBasePage(
    <Container>
      {map(couriers, (item: Courier) => (
        <StyledCourierCard
          key={item.courier_id}
          courier={item}
          onDelete={() => onDelete(item)}
          onEdit={() => onEdit(item)}
        />
      ))}
    </Container>
  )
}

const Container = styled(Row)`
  flex-wrap: wrap;
  padding: 15px;
`

const StyledCourierCard = styled(CourierCard)`
  margin: 10px;
`

const DangerText = styled.span`
  color: ${Colors.red_500};
  font-weight: bold;
  text-transform: uppercase;
`

const ModalMessage = styled.div`
  white-space: pre-line;
`

export default Couriers
