import { ButtonBase, DialogProps } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import { isNull, isUndefined, map } from 'lodash'
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { get } from '../../../Api/Api'
import { serverUrl } from '../../../Api/api.config'
import {
  Photo,
  PhotosAction,
  PhotosActionType,
  photosInitialState,
  PhotosState,
} from '../../../models/Photo'
import { alertService } from '../../../providers/AlertProvider'
import { AuthContext } from '../../../providers/AuthProvider'
import { Colors } from '../../../styles/colors'
import { SpaceBox } from '../../../styles/commonStyledComponents'
import { Dialog, DialogType } from '../../Common/Dialog'
import { Loader } from '../../Common/Loader'

interface PhotosDialogProps extends DialogProps {
  close: () => void
  onConfirm: (photo: Photo | string) => void
  className?: string
}

const PhotosDialog = ({
  className,
  close,
  onConfirm,
  ...props
}: PhotosDialogProps): JSX.Element => {
  const { t } = useTranslation()
  const reducer = (state: PhotosState, action: PhotosAction): PhotosState => {
    switch (action.type) {
      case PhotosActionType.PHOTOS_READ_REQUEST:
        return {
          ...state,
          readPhotos: {
            ...state.readPhotos,
            isLoading: true,
          },
        }
      case PhotosActionType.PHOTOS_READ_SUCCESS:
        return {
          ...state,
          readPhotos: {
            ...action.payload,
            isLoading: false,
          },
        }
      case PhotosActionType.PHOTOS_READ_FAILURE:
        return {
          ...state,
          readPhotos: photosInitialState.readPhotos,
        }
    }
  }

  const [state, dispatch] = useReducer(reducer, photosInitialState)

  const {
    readPhotos: { isLoading, photos },
  } = state

  const {
    login: { token },
  } = useContext(AuthContext)

  const readPhotos = useCallback(async () => {
    dispatch({ type: PhotosActionType.PHOTOS_READ_REQUEST })

    try {
      const photos = await get<Photo[]>(`${serverUrl}/photos`, token)

      dispatch({
        type: PhotosActionType.PHOTOS_READ_SUCCESS,
        payload: {
          photos,
        },
      })
      return photos
    } catch (err) {
      alertService.showAlert(err, 'error')
      dispatch({ type: PhotosActionType.PHOTOS_READ_FAILURE })
      return undefined
    }
  }, [token])

  const [selected, setSelected] = useState<Photo>()
  const [uploaded, setUploaded] = useState<FileList | null>(null)
  const [base64, setBase64] = useState<string>()

  const getBase64 = (file: File, cb: (result: string | null) => void) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      cb(isNull(reader.result) ? null : (reader.result as string))
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  // When the dialog is opened, read photos list
  useEffect(() => {
    if (props.open && readPhotos) {
      void readPhotos()
    }
  }, [props.open])

  // When the dialog is opened, reset the state
  useEffect(() => {
    if (props.open) {
      setSelected(undefined)
    }
  }, [props.open])

  // When a new photo is picked from input file, convert it into base64
  useEffect(() => {
    if (uploaded && !isNull(uploaded.item(0))) {
      getBase64(
        uploaded.item(0) as File,
        (result) => !isNull(result) && setBase64(result)
      )
    }
  }, [uploaded])

  const onConfirmPressed = () => {
    if (!isUndefined(base64)) {
      onConfirm(base64)
      close()
    } else if (!isUndefined(selected)) {
      onConfirm(selected)
      close()
    }
  }

  return (
    <Dialog
      {...props}
      className={className}
      title={t('pick_image')}
      mode={DialogType.CONFIRM}
      onClose={close}
      onCancelPressed={close}
      onConfirmPressed={
        !isUndefined(selected) || !isUndefined(base64)
          ? onConfirmPressed
          : undefined
      }
    >
      <ImagesContainer>
        {isLoading ? (
          <Loader />
        ) : (
          map(photos, (photo) => {
            const isSelected = selected && selected.photo_id === photo.photo_id

            if (isSelected) {
              return (
                <ImagePressable onClick={() => setSelected(photo)}>
                  <img src={photo.link} height='80' width='80' />
                  <SelectedOverlay>
                    <CheckIcon htmlColor={Colors.white} />
                  </SelectedOverlay>
                </ImagePressable>
              )
            } else {
              return (
                <ImagePressable onClick={() => setSelected(photo)}>
                  <img src={photo.link} height='80' width='80' />
                </ImagePressable>
              )
            }
          })
        )}
      </ImagesContainer>
      <SpaceBox height={24} />
      <Label>{t('or_upload_new_photo')}</Label>
      <SpaceBox height={16} />
      <input
        type='file'
        accept='image/*'
        multiple={false}
        onChange={(e) => setUploaded(e.target.files)}
      />
    </Dialog>
  )
}

const ImagesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 10px 10px;
  max-height: 200px;
  width: 436px;
`

const ImagePressable = styled(ButtonBase)`
  height: 80px;
  width: 80px;
`

const SelectedOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.2);
`

const Label = styled.p`
  font-size: 14px;
  color: ${Colors.textColor_500};
`

export default PhotosDialog
