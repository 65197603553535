import { isEmpty, isNull } from 'lodash'

export enum FilterOperator {
  EQUALS,
  DIFFERENT,
  LESS_THEN,
  GREATER_THEN,
  LESS_OR_EQUAL_THEN,
  GREATER_OR_EQUAL_THEN,
  LIKE,
}

export type FilterOperatorRaw = '=' | '<>' | '<' | '>' | '<=' | '>=' | 'like'

class ApiFilter {
  field: string
  filterOperator: FilterOperator
  value: string

  constructor(field: string, filterOperator: FilterOperatorRaw, value: string) {
    this.field = field
    this.filterOperator = this.deserializeFilterOperator(filterOperator)
    this.value = value
  }

  deserializeFilterOperator(operatorRaw: FilterOperatorRaw): FilterOperator {
    const mapping: Record<FilterOperatorRaw, FilterOperator> = {
      '=': FilterOperator.EQUALS,
      '<>': FilterOperator.DIFFERENT,
      '<': FilterOperator.LESS_THEN,
      '>': FilterOperator.GREATER_THEN,
      '<=': FilterOperator.LESS_OR_EQUAL_THEN,
      '>=': FilterOperator.GREATER_OR_EQUAL_THEN,
      like: FilterOperator.LIKE,
    }

    return mapping[operatorRaw]
  }

  serializeFilterOperator(filterOperator: FilterOperator): FilterOperatorRaw {
    const mapping: Record<FilterOperator, FilterOperatorRaw> = {
      [FilterOperator.EQUALS]: '=',
      [FilterOperator.DIFFERENT]: '<>',
      [FilterOperator.LESS_THEN]: '<',
      [FilterOperator.GREATER_THEN]: '>',
      [FilterOperator.LESS_OR_EQUAL_THEN]: '<=',
      [FilterOperator.GREATER_OR_EQUAL_THEN]: '>=',
      [FilterOperator.LIKE]: 'like',
    }

    return mapping[filterOperator]
  }

  encode(index: number): string {
    const encodedField = encodeURI(this.field)
    const encodedOperator = this.serializeFilterOperator(this.filterOperator)
    const encodedValue = encodeURI(this.value)

    return `filter[${index}][field]=${encodedField}&filter[${index}][operator]=${encodedOperator}&filter[${index}][value]=${encodedValue}`
  }
}

export class ApiFilterManager {
  filters: ApiFilter[] = []

  add(field: string, filterOperator: FilterOperatorRaw, value: string): void {
    this.filters.push(new ApiFilter(field, filterOperator, value))
  }

  encode(baseUrl: string): string {
    if (isNull(baseUrl) || isNull(this.filters) || isEmpty(this.filters)) {
      return baseUrl
    }

    let url: string = baseUrl
    let index = 0

    this.filters.forEach((filter) => {
      const concatOperator = url.includes('?') ? '&' : '?'
      url = `${url}${concatOperator}${filter.encode(index)}`
      index++
    })

    return url
  }
}
