export type LaundryShort = {
  laundry_id: number
  name: string
  address: string
  email: string
  phone: string
  vat_code?: string | null
}

export type Laundry = {
  laundry_id: number
  name: string
  address: string
  latitude: number
  longitude: number
  email: string
  phone: string
  vat_code?: string | null
}

export type CreateLaundryForm = {
  name: string
  address: string
  latitude?: number
  longitude?: number
  email: string
  phone: string
  vat_code?: string
}

export type EditLaundryForm = {
  name?: string
  phone?: string
  vat_code?: string
}

export type LaundriesState = {
  readLaundries: {
    laundries?: Laundry[]
    searchedText?: string
    isLoading: boolean
  }
  createLaundry: {
    createdLaundry?: Laundry
    isCreating: boolean
  }
  editLaundry: {
    editedLaundry?: Laundry
    isEditing: boolean
  }
  deleteLaundry: {
    deletedId?: number
    isDeleting: boolean
  }
}

export const laundriesInitialState: LaundriesState = {
  readLaundries: {
    laundries: undefined,
    searchedText: undefined,
    isLoading: false,
  },
  createLaundry: {
    createdLaundry: undefined,
    isCreating: false,
  },
  editLaundry: {
    editedLaundry: undefined,
    isEditing: false,
  },
  deleteLaundry: {
    deletedId: undefined,
    isDeleting: false,
  },
}

export enum LaundriesActionType {
  LAUNDRIES_READ_REQUEST,
  LAUNDRIES_READ_SUCCESS,
  LAUNDRIES_READ_FAILURE,
  LAUNDRY_CREATE_REQUEST,
  LAUNDRY_CREATE_SUCCESS,
  LAUNDRY_CREATE_FAILURE,
  LAUNDRY_EDIT_REQUEST,
  LAUNDRY_EDIT_SUCCESS,
  LAUNDRY_EDIT_FAILURE,
  LAUNDRY_DELETE_REQUEST,
  LAUNDRY_DELETE_SUCCESS,
  LAUNDRY_DELETE_FAILURE,
}

export type LaundriesAction =
  | { type: LaundriesActionType.LAUNDRIES_READ_REQUEST }
  | {
      type: LaundriesActionType.LAUNDRIES_READ_SUCCESS
      payload: {
        laundries?: Laundry[]
        searchedText?: string
      }
    }
  | { type: LaundriesActionType.LAUNDRIES_READ_FAILURE }
  | { type: LaundriesActionType.LAUNDRY_CREATE_REQUEST }
  | {
      type: LaundriesActionType.LAUNDRY_CREATE_SUCCESS
      payload: { createdLaundry?: Laundry }
    }
  | { type: LaundriesActionType.LAUNDRY_CREATE_FAILURE }
  | { type: LaundriesActionType.LAUNDRY_EDIT_REQUEST }
  | {
      type: LaundriesActionType.LAUNDRY_EDIT_SUCCESS
      payload: { editedLaundry?: Laundry }
    }
  | { type: LaundriesActionType.LAUNDRY_EDIT_FAILURE }
  | { type: LaundriesActionType.LAUNDRY_DELETE_REQUEST }
  | {
      type: LaundriesActionType.LAUNDRY_DELETE_SUCCESS
      payload: { deletedId?: number }
    }
  | { type: LaundriesActionType.LAUNDRY_DELETE_FAILURE }
