import { Card, CardContent } from '@material-ui/core'
import { isUndefined } from 'lodash'
import React, { FunctionComponent, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { BasePage } from '../../components/Common/BasePage'
import { Button, ButtonType } from '../../components/Common/Button'
import { TextInput } from '../../components/Common/TextInput'
import { AuthContext } from '../../providers/AuthProvider'
import { Colors } from '../../styles/colors'

const Settings: FunctionComponent<Record<string, never>> = () => {
  const {
    changePassword: { changePassword },
  } = useContext(AuthContext)
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('')
  const { t } = useTranslation()

  const submit = () => {
    if (
      !!changePassword &&
      !isUndefined(newPassword) &&
      !isUndefined(newPasswordConfirm) &&
      !isUndefined(currentPassword) &&
      newPasswordConfirm === newPassword
    ) {
      void changePassword(currentPassword, newPassword)
    }
  }

  return (
    <BasePage pageKey='settings'>
      <Panel>
        <PanelContent>
          <StyledTitle>{t('change_password')}</StyledTitle>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              submit()
            }}
          >
            <StyledTextInput
              label={t('current_password')}
              type='password'
              value={currentPassword}
              onChange={(e: {
                target: { value: React.SetStateAction<string> }
              }) => setCurrentPassword(e.target.value)}
            />
            <StyledTextInput
              label={t('new_password')}
              type='password'
              value={newPassword}
              onChange={(e: {
                target: { value: React.SetStateAction<string> }
              }) => setNewPassword(e.target.value)}
            />
            <StyledTextInput
              label={t('new_password_confirm')}
              type='password'
              value={newPasswordConfirm}
              onChange={(e: {
                target: { value: React.SetStateAction<string> }
              }) => setNewPasswordConfirm(e.target.value)}
            />
            <HiddenSubmitButton type='submit'>Submit</HiddenSubmitButton>
          </form>
          <Button
            label='Cambia password'
            mode={ButtonType.SUCCESS_MAIN}
            fullWidth={true}
            disabled={
              !currentPassword ||
              !newPassword ||
              !newPasswordConfirm ||
              newPassword !== newPasswordConfirm
            }
            onClick={submit}
          />
        </PanelContent>
      </Panel>
    </BasePage>
  )
}

const Panel = styled(Card)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${Colors.white};
  width: 500px;
`

const StyledTitle = styled.h1`
  padding-bottom: 24px;
`

const PanelContent = styled(CardContent)`
  padding: 32px !important;
`

const HiddenSubmitButton = styled.button`
  display: none;
`

const StyledTextInput = styled(TextInput)`
  width: 100%;
  padding-bottom: 24px !important;
`

export default Settings
