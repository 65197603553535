import { Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Laundry } from '../../../models/Laundry'
import {
  Row,
  SpaceBox,
  SpacedRow,
} from '../../../styles/commonStyledComponents'
import { Button, ButtonType } from '../../Common/Button'
import { SearchBar } from '../../Common/SearchBar'

export interface ILaundryHeaderProps {
  onNewLaundryPressed: () => void
  readLaundries: (search?: string | undefined) => Promise<Laundry[] | undefined>
}

const LaundryHeader = (props: ILaundryHeaderProps): JSX.Element => {
  const { onNewLaundryPressed, readLaundries } = props

  const { t } = useTranslation()

  const search = (search: string) => {
    if (readLaundries) {
      void readLaundries(search)
    }
  }

  return (
    <StyledSpacedRow>
      <Row>
        <Typography variant='h4'>{t('laundries')}</Typography>
        <SpaceBox width={32} />
        <SearchBar
          hint={t('search_laundry_by_name')}
          onChange={(e) => search(e.target.value)}
        />
      </Row>
      <Button
        label={t('new_laundry')}
        mode={ButtonType.SUCCESS_MAIN}
        startIcon={<AddIcon />}
        onClick={onNewLaundryPressed}
      />
    </StyledSpacedRow>
  )
}

const StyledSpacedRow = styled(SpacedRow)`
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
`

export default LaundryHeader
