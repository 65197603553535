import { Courier } from './Courier'

export type Weekday =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'

export type ScheduleType = 'delivery_day' | 'pickup_day'

export type Schedule = {
  schedule_id: number
  type: ScheduleType
  week_day: Weekday
} & Courier

type ScheduleWeekday = {
  delivery_day?: Schedule[]
  pickup_day?: Schedule[]
}

export type SchedulesGrouped = {
  monday?: ScheduleWeekday
  tuesday?: ScheduleWeekday
  wednesday?: ScheduleWeekday
  thursday?: ScheduleWeekday
  friday?: ScheduleWeekday
  saturday?: ScheduleWeekday
  sunday?: ScheduleWeekday
}

export type CreateScheduleForm = {
  week_day: Weekday
  type: ScheduleType
  courier_id: number
}

export type EditScheduleForm = Partial<CreateScheduleForm>

export type SchedulesState = {
  readSchedules: {
    schedules?: SchedulesGrouped
    isLoading: boolean
  }
  createSchedule: {
    createdSchedule?: Schedule
    isCreating: boolean
  }
  editSchedule: {
    editedSchedule?: Schedule
    isEditing: boolean
  }
  deleteSchedule: {
    deletedId?: number
    isDeleting: boolean
  }
}

export const schedulesInitialState: SchedulesState = {
  readSchedules: {
    schedules: undefined,
    isLoading: false,
  },
  createSchedule: {
    createdSchedule: undefined,
    isCreating: false,
  },
  editSchedule: {
    editedSchedule: undefined,
    isEditing: false,
  },
  deleteSchedule: {
    deletedId: undefined,
    isDeleting: false,
  },
}

export enum SchedulesActionType {
  SCHEDULES_READ_REQUEST,
  SCHEDULES_READ_SUCCESS,
  SCHEDULES_READ_FAILURE,
  SCHEDULE_CREATE_REQUEST,
  SCHEDULE_CREATE_SUCCESS,
  SCHEDULE_CREATE_FAILURE,
  SCHEDULE_EDIT_REQUEST,
  SCHEDULE_EDIT_SUCCESS,
  SCHEDULE_EDIT_FAILURE,
  SCHEDULE_DELETE_REQUEST,
  SCHEDULE_DELETE_SUCCESS,
  SCHEDULE_DELETE_FAILURE,
}

export type SchedulesAction =
  | { type: SchedulesActionType.SCHEDULES_READ_REQUEST }
  | {
      type: SchedulesActionType.SCHEDULES_READ_SUCCESS
      payload: { schedules?: SchedulesGrouped }
    }
  | { type: SchedulesActionType.SCHEDULES_READ_FAILURE }
  | { type: SchedulesActionType.SCHEDULE_CREATE_REQUEST }
  | {
      type: SchedulesActionType.SCHEDULE_CREATE_SUCCESS
      payload: { createdSchedule?: Schedule }
    }
  | { type: SchedulesActionType.SCHEDULE_CREATE_FAILURE }
  | { type: SchedulesActionType.SCHEDULE_EDIT_REQUEST }
  | {
      type: SchedulesActionType.SCHEDULE_EDIT_SUCCESS
      payload: { editedSchedule?: Schedule }
    }
  | { type: SchedulesActionType.SCHEDULE_EDIT_FAILURE }
  | { type: SchedulesActionType.SCHEDULE_DELETE_REQUEST }
  | {
      type: SchedulesActionType.SCHEDULE_DELETE_SUCCESS
      payload: { deletedId?: number }
    }
  | { type: SchedulesActionType.SCHEDULE_DELETE_FAILURE }
