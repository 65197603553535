import { Grid, Typography } from '@material-ui/core'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import { Autocomplete } from '@material-ui/lab'
import parse from 'autosuggest-highlight/parse'
import { isNull, map, throttle } from 'lodash'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import {
  AutocompletePrediction,
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import styled from 'styled-components'

import { alertService } from '../../../providers/AlertProvider'
import { TextInput } from '../../Common/TextInput'
import { ITextInputProps } from '../../Common/TextInput/TextInput'

interface LocationPickerProps extends ITextInputProps {
  label: string
  onLocationPicked: (address: string, latLng: google.maps.LatLngLiteral) => void
}

const LocationPicker = (props: LocationPickerProps): JSX.Element => {
  const { className, label, onLocationPicked } = props

  const autocompleteService = useRef<google.maps.places.AutocompleteService | null>(
    null
  )

  const [value, setValue] = useState<AutocompletePrediction | null>(null)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<AutocompletePrediction[]>([])

  useEffect(() => {
    if (!isNull(value)) {
      geocodeByAddress(value.description)
        .then((results) => getLatLng(results[0]))
        .then((latLng) => {
          onLocationPicked(value.description, latLng)
        })
        .catch((error) => alertService.showAlert(error, 'error'))
    }
  }, [value])

  const fetch = useMemo(
    () =>
      throttle(
        (
          request: { input: string },
          callback: (result: AutocompletePrediction[]) => void
        ) => {
          autocompleteService.current?.getPlacePredictions(request, callback)
        },
        200
      ),
    []
  )

  useEffect(() => {
    let active = true

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return undefined
    }

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    fetch(
      { input: inputValue },
      (result: google.maps.places.AutocompletePrediction[]) => {
        if (active) {
          let newOptions = [] as AutocompletePrediction[]

          if (value) {
            newOptions = [value]
          }

          if (result) {
            newOptions = [...newOptions, ...result]
          }

          setOptions(newOptions)
        }
      }
    )

    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  return (
    <Autocomplete
      className={className}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options)
        setValue(newValue)
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue)
      }}
      renderInput={(params) => (
        <StyledTextInput {...params} label={label} fullWidth />
      )}
      renderOption={(option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings
        const parts = parse(
          option.structured_formatting.main_text,
          map(matches, (match) => [match.offset, match.offset + match.length])
        )

        return (
          <Grid container alignItems='center'>
            <Grid item>
              <LocationOnIcon />
            </Grid>
            <Grid item xs>
              {map(parts, (part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}

              <Typography variant='body2'>
                {option.structured_formatting.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        )
      }}
    />
  )
}

const StyledTextInput = styled(TextInput)`
  width: 100%;
`

export default LocationPicker
