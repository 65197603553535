export const Colors = {
  transparent: 'transparent',
  white: '#FFFFFF',
  primaryColor_50: '#F0F5FE',
  primaryColor_100: '#E1EBFD',
  primaryColor_200: '#C3D8FC',
  primaryColor_300: '#8EB6F8',
  primaryColor_400: '#689DF6',
  primaryColor_500: '#4285F4',
  primaryColor_600: '#336BC9',
  primaryColor_700: '#2B5EB2',
  primaryColor_800: '#1F488C',
  primaryColor_900: '#163974',
  textColor_50: '#EEF0F1',
  textColor_100: '#D6D7DA',
  textColor_200: '#A7ADB6',
  textColor_300: '#737E8E',
  textColor_400: '#4C5B70',
  textColor_500: '#263853',
  textColor_600: '#1E2D43',
  textColor_700: '#131C2A',
  textColor_800: '#0A1423',
  textColor_900: '#000000',
  green_50: '#EEF7F6',
  green_100: '#DCEBE9',
  green_200: '#A7CEC9',
  green_300: '#7BB5AE',
  green_400: '#4F9C93',
  green_500: '#2A9D8F',
  green_600: '#238478',
  green_700: '#1C7065',
  green_800: '#175F56',
  green_900: '#124F47',
  orange_50: '#FEF8F2',
  orange_100: '#FBEDE1',
  orange_200: '#F5D1B5',
  orange_300: '#F0BA8F',
  orange_400: '#EBA36A',
  orange_500: '#F4A261',
  orange_600: '#EE8837',
  orange_700: '#D7792F',
  orange_800: '#C16B27',
  orange_900: '#A6591D',
  red_50: '#FDF3F1',
  red_100: '#F8E4DF',
  red_200: '#EDBCAF',
  red_300: '#E49A87',
  red_400: '#DB785F',
  red_500: '#E76F51',
  red_600: '#D25637',
  red_700: '#B94B2F',
  red_800: '#A74228',
  red_900: '#8E3721',
}
