import styled from 'styled-components'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const SpacedRow = styled.div`
  display: flex;
  align-self: stretch;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`

interface SpaceBoxProps {
  height?: number
  width?: number
}

export const SpaceBox = styled.div<SpaceBoxProps>`
  height: ${(props) => `${props.height || 0}px`};
  width: ${(props) => `${props.width || 0}px`};
`
