import { Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { SideBar } from '../SideBar'

type BasePageProps = {
  header?: React.ReactNode
  children?: React.ReactNode
  pageKey?: string | null
}

const BasePage = ({
  children,
  header,
  pageKey,
}: BasePageProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <SideBar />
      <ContentBox>
        {header ||
          (pageKey && <PageTitle variant='h4'>{t(pageKey)}</PageTitle>)}
        {children}
      </ContentBox>
    </>
  )
}

const ContentBox = styled.div`
  margin-left: 200px;
`

const PageTitle = styled(Typography)`
  padding-left: 48px;
  padding-top: 32px;
`

export default BasePage
