/* eslint-disable react/prop-types */
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@material-ui/core'
import React, { FunctionComponent } from 'react'

import { Button, ButtonType } from '../Button'

export enum DialogType {
  CREATE,
  EDIT,
  DELETE,
  CONFIRM,
}

export interface IDialogProps extends DialogProps {
  mode: DialogType
  title: string
  onConfirmPressed?: () => void
  onCancelPressed?: () => void
}

const Dialog: FunctionComponent<IDialogProps> = ({
  children,
  mode,
  onCancelPressed,
  onConfirmPressed,
  title,
  ...props
}) => {
  const renderButtons = () => {
    switch (mode) {
      case DialogType.CREATE: {
        return (
          <>
            <Button
              label='Annulla'
              mode={ButtonType.ALERT}
              onClick={onCancelPressed}
              disabled={!onCancelPressed}
            />
            <Button
              label='Aggiungi'
              mode={ButtonType.SUCCESS_MAIN}
              onClick={onConfirmPressed}
              disabled={!onConfirmPressed}
            />
          </>
        )
      }
      case DialogType.EDIT: {
        return (
          <>
            <Button
              label='Annulla'
              mode={ButtonType.ALERT}
              onClick={onCancelPressed}
              disabled={!onCancelPressed}
            />
            <Button
              label='Modifica'
              mode={ButtonType.SUCCESS_MAIN}
              onClick={onConfirmPressed}
              disabled={!onConfirmPressed}
            />
          </>
        )
      }
      case DialogType.DELETE: {
        return (
          <>
            <Button
              label='Conferma'
              mode={ButtonType.ALERT}
              onClick={onConfirmPressed}
              disabled={!onConfirmPressed}
            />
            <Button
              label='Annulla'
              mode={ButtonType.SUCCESS_MAIN}
              onClick={onCancelPressed}
              disabled={!onCancelPressed}
            />
          </>
        )
      }
      case DialogType.CONFIRM: {
        return (
          <>
            <Button
              label='Annulla'
              mode={ButtonType.ALERT}
              onClick={onCancelPressed}
              disabled={!onCancelPressed}
            />
            <Button
              label='Conferma'
              mode={ButtonType.SUCCESS_MAIN}
              onClick={onConfirmPressed}
              disabled={!onConfirmPressed}
            />
          </>
        )
      }
    }
  }

  return (
    <MuiDialog {...props} aria-labelledby='simple-dialog-title' maxWidth='sm'>
      <DialogTitle id='dialog-title'>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>{renderButtons()}</DialogActions>
    </MuiDialog>
  )
}

export default Dialog
