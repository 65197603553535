import { Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Order, stateColors, stateShadowColors } from '../../../models/Order'
import { Colors } from '../../../styles/colors'
import { pricify } from '../../../utils/formatting'

interface TotalOrderProps {
  order: Order
  className?: string
}

const TotalOrder = ({ className, order }: TotalOrderProps): JSX.Element => {
  const { state, total_price } = order
  const { t } = useTranslation()
  const color = stateColors[state] || Colors.textColor_500
  const shadowColor = stateShadowColors[state] || Colors.textColor_50
  const totalPrice = pricify(total_price)

  return (
    <Container color={color} shadowColor={shadowColor} className={className}>
      <Total variant='h6' color='secondary'>
        {t('total_order')}
      </Total>
      <Typography variant='h6' color='secondary'>
        {totalPrice}
      </Typography>
    </Container>
  )
}

interface StyleProps {
  colors?: string
  shadowColor?: string
}

const Container = styled.div<StyleProps>`
  width: 410px;
  background: ${(props) => props.color || Colors.textColor_500};
  border: 1px solid ${Colors.textColor_50};
  box-sizing: border-box;
  box-shadow: 0px 8px 8px ${(props) => props.shadowColor || Colors.textColor_50};
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 12px;
  padding-bottom: 12px;
  color: ${Colors.white};
  font-size: 18px;
`

const Total = styled(Typography)`
  font-weight: 400 !important;
`

export default TotalOrder
