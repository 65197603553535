import { DiscountType } from './Discount'
import { Service } from './Service'

export type Gender = 'M' | 'F'

export type ProductGetList = {
  data: Product[]
  current_page?: number
  last_page?: number
}

export type Product = {
  product_id: number
  name: string // name to display based on x-localization
  italian_name: string
  french_name: string
  washing_price?: number | null
  ironing_price?: number | null
  full_price?: number | null
  category: string // category to display based on x-localization
  italian_category: string
  french_category: string
  category_translation_id: number
  gender?: Gender | null
  photo_link?: string | null
  promo_description?: string | null
  promo_washing_price?: number | null
  promo_ironing_price?: number | null
  promo_full_price?: number | null
  discount?: number | null
  promo_type?: DiscountType
}

type CreateProductFormBase = {
  italian_name: string
  french_name: string
  gender: Gender | null
  washing_price?: number
  ironing_price?: number
  full_price?: number
}

type WithNewCategory = {
  italian_category: string
  french_category: string
}

type WithExistingCategory = {
  category_translation_id: number
  italian_category: string
  french_category: string
}

type WithNewImage = {
  image?: string // base64 encoded
}

type WithExistingImage = {
  photo_id?: number
  photo_link?: string
}

export type CreateProductForm = CreateProductFormBase &
  (WithNewCategory | WithExistingCategory) &
  (WithNewImage | WithExistingImage)

export type EditProductForm = Partial<CreateProductForm>

export type ProductsState = {
  readProducts: {
    products?: Product[]
    searchedText?: string
    filteredService?: Service
    isLoading: boolean
    totalPage: number
    currentPage: number
  }
  createProduct: {
    createdProduct?: Product
    isCreating: boolean
  }
  editProduct: {
    editedProduct?: Product
    isEditing: boolean
  }
  deleteProduct: {
    deletedId?: number
    isDeleting: boolean
  }
}

export const productsInitialState: ProductsState = {
  readProducts: {
    products: undefined,
    searchedText: undefined,
    filteredService: undefined,
    isLoading: false,
    totalPage: 1,
    currentPage: 1,
  },
  createProduct: {
    createdProduct: undefined,
    isCreating: false,
  },
  editProduct: {
    editedProduct: undefined,
    isEditing: false,
  },
  deleteProduct: {
    deletedId: undefined,
    isDeleting: false,
  },
}

export enum ProductsActionType {
  PRODUCTS_READ_REQUEST,
  PRODUCTS_READ_SUCCESS,
  PRODUCTS_READ_FAILURE,
  PRODUCT_CREATE_REQUEST,
  PRODUCT_CREATE_SUCCESS,
  PRODUCT_CREATE_FAILURE,
  PRODUCT_EDIT_REQUEST,
  PRODUCT_EDIT_SUCCESS,
  PRODUCT_EDIT_FAILURE,
  PRODUCT_DELETE_REQUEST,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DELETE_FAILURE,
}

export type ProductsAction =
  | { type: ProductsActionType.PRODUCTS_READ_REQUEST }
  | {
      type: ProductsActionType.PRODUCTS_READ_SUCCESS
      payload: {
        products?: Product[]
        searchedText?: string
        filteredService?: Service
        currentPage: number
        total: number
      }
    }
  | { type: ProductsActionType.PRODUCTS_READ_FAILURE }
  | { type: ProductsActionType.PRODUCT_CREATE_REQUEST }
  | {
      type: ProductsActionType.PRODUCT_CREATE_SUCCESS
      payload: { createdProduct?: Product }
    }
  | { type: ProductsActionType.PRODUCT_CREATE_FAILURE }
  | { type: ProductsActionType.PRODUCT_EDIT_REQUEST }
  | {
      type: ProductsActionType.PRODUCT_EDIT_SUCCESS
      payload: { editedProduct?: Product }
    }
  | { type: ProductsActionType.PRODUCT_EDIT_FAILURE }
  | { type: ProductsActionType.PRODUCT_DELETE_REQUEST }
  | {
      type: ProductsActionType.PRODUCT_DELETE_SUCCESS
      payload: { deletedId?: number }
    }
  | { type: ProductsActionType.PRODUCT_DELETE_FAILURE }
