import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { AuthContext } from '../../../providers/AuthProvider'
import { RouteItem, routes } from '../../../routing/routes'
import { Colors } from '../../../styles/colors'
import { BackgroundPattern } from '../BackgroundPattern'

const SideBar = (): JSX.Element => {
  const {
    getAdminData: { data },
    login: { token },
  } = useContext(AuthContext)
  const location = useLocation()
  const history = useHistory()

  const { t } = useTranslation()

  const allItems = routes.map((routeData: RouteItem) => {
    const { path, sidebar } = routeData

    if (!sidebar || !routeData.private) {
      return null
    }

    return (
      <Item
        key={path}
        selected={location.pathname.includes(path)}
        onClick={() => history.push(path)}
        disabled={data?.first_login && path !== '/settings'}
      >
        <sidebar.icon />
        <Name>{t(sidebar.localization_key)}</Name>
      </Item>
    )
  })

  return (
    <>
      {token && (
        <Container>
          <BackgroundPattern />
          <Title>Pressing App</Title>
          <ItemList>{allItems}</ItemList>
        </Container>
      )}
    </>
  )
}

const Container = styled.div`
  height: 100%;
  width: 200px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${Colors.primaryColor_500};
  z-index: 1;
`

const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: ${Colors.white};
  text-align: center;
  margin-top: 32px;
  margin-bottom: 32px;
`

const ItemList = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  color: ${Colors.white};
`

interface ItemProps {
  selected?: boolean
  disabled?: boolean
}

const Item = styled.div<ItemProps>`
  height: 42px;
  border-radius: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  background: ${(props) =>
    props.selected ? Colors.primaryColor_600 : 'trasparent'};
  font-weight: 400;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'unset')};
  color: ${(props) =>
    props.disabled ? Colors.primaryColor_300 : Colors.white};

  &:hover {
    background: ${Colors.primaryColor_300};
    cursor: pointer;
  }
`

const Name = styled.span`
  font-size: 18px;
  line-height: 21px;
  margin-left: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default SideBar
