import { DialogProps } from '@material-ui/core'
import { isEmpty, omit } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  Courier,
  CreateCourierForm,
  EditCourierForm,
} from '../../../models/Courier'
import { Colors } from '../../../styles/colors'
import { SpaceBox } from '../../../styles/commonStyledComponents'
import { Dialog, DialogType } from '../../Common/Dialog'
import { TextInput } from '../../Common/TextInput'

interface CourierDialogProps extends DialogProps {
  close: () => void
  isEditMode?: boolean
  toEdit?: Courier
  className?: string
  editCourier: (
    id: number,
    edit: EditCourierForm
  ) => Promise<Courier | undefined>
  createCourier: (create: CreateCourierForm) => Promise<Courier | undefined>
  readCouriers: (search?: string | undefined) => Promise<Courier[] | undefined>
}

const CourierDialog = ({
  className,
  close,
  createCourier,
  editCourier,
  isEditMode,
  readCouriers,
  toEdit,
  ...props
}: CourierDialogProps): JSX.Element => {
  const { t } = useTranslation()

  const [form, setForm] = useState<CreateCourierForm | EditCourierForm>(
    isEditMode && toEdit
      ? {
          name: toEdit.name || '',
          phone: toEdit.phone || '',
        }
      : {
          name: '',
          email: '',
          phone: '',
        }
  )
  const [errors, setErrors] = useState<{ [key: string]: string }>({})

  // When the dialog is opened/closed, reset the state
  useEffect(() => {
    if (props.open) {
      setErrors({})
      setForm(
        isEditMode && toEdit
          ? {
              name: toEdit.name || '',
              phone: toEdit.phone || '',
            }
          : {
              name: '',
              email: '',
              phone: '',
            }
      )
    }
  }, [props.open, isEditMode, toEdit])

  // It sucks, I know, but I didn't want to make it better, just collapse the function body and don't look inside ;)
  const validate = () => {
    let hasErrors = false

    // Email
    if (!isEditMode && isEmpty((form as CreateCourierForm).email)) {
      setErrors((prev) => ({ ...prev, email: t('courier_email_empty') }))
      hasErrors = true
    } else {
      setErrors((prev) => omit(prev, ['email']))
    }

    // Name
    if (isEmpty(form.name)) {
      setErrors((prev) => ({ ...prev, name: t('courier_name_empty') }))
      hasErrors = true
    } else {
      setErrors((prev) => omit(prev, ['name']))
    }

    // Phone
    if (isEmpty(form.phone)) {
      setErrors((prev) => ({ ...prev, phone: t('courier_phone_empty') }))
      hasErrors = true
    } else {
      setErrors((prev) => omit(prev, ['phone']))
    }

    return !hasErrors
  }

  const onConfirmPressed = async () => {
    if (!validate()) {
      return
    }

    if (isEditMode && toEdit) {
      const edited = await editCourier(toEdit.courier_id, form)
      if (edited) {
        close()
      }
    } else {
      const created = await createCourier(form)
      if (created) {
        close()
      }
    }

    void readCouriers()
  }

  return (
    <Dialog
      {...props}
      className={className}
      title={isEditMode && toEdit ? t('edit_courier') : t('new_courier')}
      mode={isEditMode && toEdit ? DialogType.EDIT : DialogType.CREATE}
      onClose={close}
      onCancelPressed={close}
      onConfirmPressed={onConfirmPressed}
      fullWidth
    >
      <StyledTextInput
        label={t('name_label')}
        type='text'
        value={form.name}
        onChange={(e) => setForm({ ...form, name: e.target.value })}
      />
      <SpaceBox height={8} />
      <Error>{errors['name']}</Error>
      <SpaceBox height={24} />
      {!isEditMode && (
        <>
          <StyledTextInput
            label={t('email_label')}
            type='text'
            value={(form as CreateCourierForm).email}
            onChange={(e) => setForm({ ...form, email: e.target.value })}
          />
          <SpaceBox height={8} />
          <Error>{errors['email']}</Error>
          <SpaceBox height={24} />
        </>
      )}
      <StyledTextInput
        label={t('phone_label')}
        type='text'
        value={form.phone}
        onChange={(e) => setForm({ ...form, phone: e.target.value })}
      />
      <SpaceBox height={8} />
      <Error>{errors['phone']}</Error>
    </Dialog>
  )
}

const StyledTextInput = styled(TextInput)`
  width: 100%;
`

const Error = styled.p`
  font-size: 14px;
  margin: 0px;
  color: ${Colors.red_500};
`

export default CourierDialog
