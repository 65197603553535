import { ButtonBase } from '@material-ui/core'
import { isUndefined, map } from 'lodash'
import React from 'react'
import styled from 'styled-components'

import { Colors } from '../../../styles/colors'

export interface ISelectorProps<T> {
  options: T[]
  labelExtractor: (item: T) => string // Used to extract from the option the label to show in the selector
  onSelect: (selected: T | undefined) => void
  selected?: T
  mandatory?: boolean
}

function Selector<T>(props: ISelectorProps<T>): React.ReactElement | null {
  const { labelExtractor, mandatory, onSelect, options, selected } = props

  const select = (option: T) => {
    if (!isUndefined(selected) && selected === option) {
      // deselect only if it is not mandatory
      if (!mandatory) {
        onSelect(undefined)
      }
    } else {
      onSelect(option)
    }
  }

  return (
    <Container>
      {map(options, (option, index) => {
        const isSelected = option === selected
        const isLast = index === options.length - 1

        const textColor = isSelected ? Colors.white : Colors.textColor_500
        const backgroundColor = isSelected
          ? Colors.primaryColor_500
          : Colors.transparent

        const label = labelExtractor(option)

        return (
          <>
            <Option
              key={label}
              color={textColor}
              backgroundColor={backgroundColor}
              onClick={() => select(option)}
            >
              {label}
            </Option>
            {!isLast && <VerticalSeparator />}
          </>
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 48px;
  border: 1px solid ${Colors.textColor_50};
`

interface IOptionProps {
  color: string
  backgroundColor: string
}

const Option = styled(ButtonBase)<IOptionProps>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  font-size: 16px;
  color: ${(props) => props.color} !important;
  background-color: ${(props) => props.backgroundColor} !important;
`

const VerticalSeparator = styled.div`
  height: 48px;
  width: 1px;
  background-color: ${Colors.textColor_50};
`

export default Selector
