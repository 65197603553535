import { isNull, isUndefined } from 'lodash'
import React, { useContext } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

import { CenterContainer } from '../../components/Common/CenterContainer'
import { Loader } from '../../components/Common/Loader'
import { AuthContext } from '../../providers/AuthProvider'
import { RouteItem, routes } from '../routes'

const Routing = (): JSX.Element => {
  const {
    getAdminData: { data, isLoading: isGettingAdminData },
    login: { isLoading: isLoggingIn, token },
  } = useContext(AuthContext)

  const isLogged = !isLoggingIn && !!token && !isGettingAdminData && !!data
  const shouldShowLoader =
    isLoggingIn ||
    isGettingAdminData ||
    isUndefined(token) || // token undefined means that the app is still not initialized
    (!isNull(token) && isUndefined(data))

  const allRoutes = routes.map((routeData: RouteItem) => {
    const { component, path } = routeData

    // User isn't logged in and the route is private
    if (routeData.private && !isLogged) {
      return null
    }

    return <Route key={path} exact path={path} component={component} />
  })

  if (shouldShowLoader) {
    return (
      <CenterContainer>
        <Loader />
      </CenterContainer>
    )
  }

  return (
    <BrowserRouter>
      <Switch>
        {allRoutes}
        <Redirect to='/login' />
      </Switch>
    </BrowserRouter>
  )
}

export default Routing
