import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useReducer,
} from 'react'

import { get } from '../../Api/Api'
import { serverUrl } from '../../Api/api.config'
import { Category } from '../../models/Category'
import { alertService } from '../AlertProvider'
import { AuthContext } from '../AuthProvider'

type ContextValue = {
  readCategories: {
    categories?: Category[]
    readCategories?: () => Promise<Category[] | undefined>
    isLoading: boolean
  }
}

type State = {
  readCategories: {
    categories?: Category[]
    isLoading: boolean
  }
}

const initialState: State = {
  readCategories: {
    categories: undefined,
    isLoading: false,
  },
}

enum ActionType {
  CATEGORIES_READ_REQUEST,
  CATEGORIES_READ_SUCCESS,
  CATEGORIES_READ_FAILURE,
}

type Action =
  | { type: ActionType.CATEGORIES_READ_REQUEST }
  | {
      type: ActionType.CATEGORIES_READ_SUCCESS
      payload: { categories?: Category[] }
    }
  | { type: ActionType.CATEGORIES_READ_FAILURE }

export const CategoriesContext = React.createContext<ContextValue>({
  readCategories: { isLoading: false },
})

const CategoriesProvider = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const reducer = (state: State, action: Action): State => {
    switch (action.type) {
      case ActionType.CATEGORIES_READ_REQUEST:
        return {
          ...state,
          readCategories: {
            ...state.readCategories,
            isLoading: true,
          },
        }
      case ActionType.CATEGORIES_READ_SUCCESS:
        return {
          ...state,
          readCategories: {
            ...action.payload,
            isLoading: false,
          },
        }
      case ActionType.CATEGORIES_READ_FAILURE:
        return {
          ...state,
          readCategories: initialState.readCategories,
        }
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  const {
    login: { token },
  } = useContext(AuthContext)

  const readCategories = useCallback(async () => {
    dispatch({ type: ActionType.CATEGORIES_READ_REQUEST })

    try {
      const categories = await get<Category[]>(
        `${serverUrl}/products/category`,
        token
      )

      dispatch({
        type: ActionType.CATEGORIES_READ_SUCCESS,
        payload: {
          categories,
        },
      })
      return categories
    } catch (err) {
      alertService.showAlert(err, 'error')
      dispatch({ type: ActionType.CATEGORIES_READ_FAILURE })
      return undefined
    }
  }, [token])

  return (
    <CategoriesContext.Provider
      value={{
        readCategories: {
          ...state.readCategories,
          readCategories,
        },
      }}
    >
      {children}
    </CategoriesContext.Provider>
  )
}

export default CategoriesProvider
