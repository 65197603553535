import { Chip, Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import DoneIcon from '@material-ui/icons/Done'
import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { ProductGetList } from '../../../models/Product'
import { Service, serviceSvgs } from '../../../models/Service'
import { Colors } from '../../../styles/colors'
import {
  Row,
  SpaceBox,
  SpacedRow,
} from '../../../styles/commonStyledComponents'
import { Button, ButtonType } from '../../Common/Button'
import { SearchBar } from '../../Common/SearchBar'

export interface IProductsHeaderProps {
  onNewProductPressed: () => void
  searchedText?: string
  filteredService?: Service
  readProducts: (
    search?: string | undefined,
    service?: Service | undefined,
    page?: number
  ) => Promise<ProductGetList | undefined>
}

const ProductsHeader: FunctionComponent<IProductsHeaderProps> = (
  props: IProductsHeaderProps
) => {
  const {
    filteredService,
    onNewProductPressed,
    readProducts,
    searchedText,
  } = props

  const { t } = useTranslation()

  const WashSvg = serviceSvgs['wash']
  const IronSvg = serviceSvgs['iron']

  const search = (search: string) => {
    if (readProducts) {
      void readProducts(search, filteredService)
    }
  }

  const filterService = (service?: Service) => {
    if (readProducts) {
      void readProducts(searchedText, service)
    }
  }

  const washSelected = filteredService === 'wash'
  const ironSelected = filteredService === 'iron'

  return (
    <StyledSpacedRow>
      <Row>
        <Typography variant='h4'>{t('products')}</Typography>
        <SpaceBox width={32} />
        <SearchBar
          hint={t('search_product_by_name')}
          onChange={(e) => search(e.target.value)}
        />
        <SpaceBox width={32} />
        <Chip
          label={t('product_wash_service')}
          color={washSelected ? 'primary' : 'default'}
          avatar={
            <Circle
              backgroundColor={
                washSelected ? Colors.primaryColor_400 : Colors.textColor_200
              }
            >
              {washSelected ? (
                <DoneIcon fontSize='small' />
              ) : (
                <WashSvg
                  height='14px'
                  width='14px'
                  color={Colors.textColor_500}
                />
              )}
            </Circle>
          }
          onClick={
            washSelected ? () => filterService() : () => filterService('wash')
          }
        />
        <SpaceBox width={8} />
        <Chip
          label={t('product_iron_service')}
          color={ironSelected ? 'primary' : 'default'}
          avatar={
            <Circle
              backgroundColor={
                ironSelected ? Colors.primaryColor_400 : Colors.textColor_200
              }
            >
              {ironSelected ? (
                <DoneIcon fontSize='small' />
              ) : (
                <IronSvg
                  height='14px'
                  width='14px'
                  color={Colors.textColor_500}
                />
              )}
            </Circle>
          }
          onClick={
            ironSelected ? () => filterService() : () => filterService('iron')
          }
        />
      </Row>
      <Button
        label={t('new_product')}
        mode={ButtonType.SUCCESS_MAIN}
        startIcon={<AddIcon />}
        onClick={onNewProductPressed}
      />
    </StyledSpacedRow>
  )
}

const StyledSpacedRow = styled(SpacedRow)`
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
`

interface ICircleProps {
  backgroundColor: string
}

const Circle = styled.div<ICircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background-color: ${(props) => props.backgroundColor};
`

export default ProductsHeader
