import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import itTranslation from '../locales/it/translation.json'

// the translations
const resources = {
  it: {
    translation: itTranslation,
  },
}

void i18n.use(initReactI18next).init({
  resources,
  lng: 'it',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
