import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { OrderShort, stateColors } from '../../../models/Order'
import { Colors } from '../../../styles/colors'
import { pricify } from '../../../utils/formatting'

interface OrderCardProps {
  order: OrderShort
  className?: string
}

const OrderCard = ({ className, order }: OrderCardProps): JSX.Element => {
  const { client, order_id, products_number, state, total_price } = order
  const { t } = useTranslation()
  const color = stateColors[state] || Colors.textColor_500
  const totalPrice = pricify(total_price)
  const itemLabel = products_number > 1 ? 'items' : 'item'
  const history = useHistory()

  const openDetail = () => {
    history.push(`/orders/${order_id}`)
  }

  return (
    <Container onClick={openDetail} className={className}>
      <State color={color}>{t(state)}</State>
      <ClientInfo>
        {client.surname} {client.name}
      </ClientInfo>
      <Quantity>
        {products_number} {t(itemLabel)}
      </Quantity>
      <Total color={color}>{totalPrice}</Total>
      <Line color={color} />
    </Container>
  )
}

export default OrderCard

interface StyleProps {
  colors?: string
}

const Container = styled.div`
  width: 190px;
  height: 110px;
  background: ${Colors.white};
  border: 1px solid ${Colors.textColor_50};
  box-sizing: border-box;
  box-shadow: 0px 8px 8px ${Colors.textColor_50};
  border-radius: 6px;
  position: relative;
  cursor: pointer;
`

const State = styled.div<StyleProps>`
  position: absolute;
  top: 10px;
  left: 8px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  color: ${(props) => props.color || Colors.textColor_500};
`
const ClientInfo = styled.div`
  position: absolute;
  top: 30px;
  left: 8px;
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  right: 8px;
`

const Quantity = styled.div`
  position: absolute;
  top: 55px;
  left: 8px;
  color: ${Colors.textColor_400};
  text-transform: lowercase;
  font-size: 14px;
`

const Total = styled.div<StyleProps>`
  position: absolute;
  top: 55px;
  right: 8px;
  color: ${(props) => props.color || Colors.textColor_500};
  font-size: 18px;
`

const Line = styled.div<StyleProps>`
  position: absolute;
  right: 8px;
  left: 8px;
  bottom: 15px;
  background-color: ${(props) => props.color || Colors.textColor_500};
  height: 8px;
  border-radius: 2px;
`
