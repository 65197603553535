import { Card, CardContent, Typography } from '@material-ui/core'
import { isNull, isUndefined } from 'lodash'
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { BackgroundPattern } from '../../components/Common/BackgroundPattern'
import { Button, ButtonType } from '../../components/Common/Button'
import { CenterContainer } from '../../components/Common/CenterContainer'
import { Loader } from '../../components/Common/Loader'
import { TextInput } from '../../components/Common/TextInput'
import { AuthContext } from '../../providers/AuthProvider'
import { Colors } from '../../styles/colors'

const Login: FunctionComponent<Record<string, never>> = () => {
  const history = useHistory()
  const {
    getAdminData: { data },
    login: { isLoading: isLoggingIn, login, token },
  } = useContext(AuthContext)

  const { t } = useTranslation()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const shouldShowLoader = isLoggingIn || (!isNull(token) && isUndefined(data))

  useEffect(() => {
    if (!isUndefined(data) && !isNull(data)) {
      if (data.first_login) {
        // if is the first login, we should route the user to the password change screen
        history.push('/settings')
      } else {
        history.push('/orders')
      }
    }
  }, [data])

  if (shouldShowLoader) {
    return (
      <CenterContainer>
        <Loader />
      </CenterContainer>
    )
  }

  const submit = () => {
    if (!!login && !isUndefined(email) && !isUndefined(password)) {
      void login(email, password)
    }
  }

  return (
    <>
      <StyledBackgroundPattern />
      <LogoContainer>
        <Typography variant='h5' color='textSecondary'>
          {t('app_name')}
        </Typography>
      </LogoContainer>
      <Panel>
        <PanelContent>
          <StyledTitle variant='h5' color='textPrimary'>
            {t('control_panel')}
          </StyledTitle>
          <form
            onSubmit={(e) => {
              e.preventDefault()
              submit()
            }}
          >
            <StyledTextInput
              label={t('email_label')}
              type='text'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <StyledTextInput
              label={t('password_label')}
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <HiddenSubmitButton type='submit'>Submit</HiddenSubmitButton>
          </form>
          <Button
            label={t('login_button')}
            mode={ButtonType.SUCCESS_MAIN}
            fullWidth={true}
            disabled={!email || !password}
            onClick={submit}
          />
        </PanelContent>
      </Panel>
    </>
  )
}

const StyledTitle = styled(Typography)`
  padding-bottom: 24px;
`

const StyledTextInput = styled(TextInput)`
  width: 100%;
  padding-bottom: 24px !important;
`

const StyledBackgroundPattern = styled(BackgroundPattern)`
  height: 100vh;
  width: 100%;
`

const LogoContainer = styled.div`
  position: absolute;
  left: 32px;
  top: 32px;
`

const Panel = styled(Card)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${Colors.white};
  width: 500px;
`

const PanelContent = styled(CardContent)`
  padding: 32px !important;
`

const HiddenSubmitButton = styled.button`
  display: none;
`

export default Login
