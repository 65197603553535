import CheckIcon from '@material-ui/icons/Check'
import { map } from 'lodash'
import { parse, stringify } from 'query-string'
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { get, post } from '../../Api/Api'
import { serverUrl } from '../../Api/api.config'
import { Button, ButtonType } from '../../components/Common/Button'
import { CenterContainer } from '../../components/Common/CenterContainer'
import { Dialog, DialogType } from '../../components/Common/Dialog'
import { Loader } from '../../components/Common/Loader'
import { NotFound } from '../../components/Common/NotFound'
import { ClientInfo } from '../../components/Orders/ClientInfo'
import { HistoryInfo } from '../../components/Orders/HistoryInfo'
import { OrderedProductCard } from '../../components/Orders/OrderedProductCard'
import {
  ChangeStateParams,
  Order,
  OrderStateChangeAction,
  OrderStateChangeActionType,
  orderStateChangeInitialState,
  OrderStateChangeState,
} from '../../models/Order'
import { alertService } from '../../providers/AlertProvider'
import { Colors } from '../../styles/colors'
import { Column, SpacedRow } from '../../styles/commonStyledComponents'

const OrderStateChange: FunctionComponent<Record<string, never>> = () => {
  const reducer = (
    state: OrderStateChangeState,
    action: OrderStateChangeAction
  ): OrderStateChangeState => {
    switch (action.type) {
      case OrderStateChangeActionType.ORDER_READ_REQUEST:
        return {
          ...state,
          readOrder: {
            ...state.readOrder,
            isLoading: true,
          },
        }
      case OrderStateChangeActionType.ORDER_READ_SUCCESS:
        return {
          ...state,
          readOrder: {
            ...action.payload,
            order: action.payload.order,
            isLoading: false,
          },
        }
      case OrderStateChangeActionType.ORDER_READ_FAILURE:
        return {
          ...state,
          readOrder: orderStateChangeInitialState.readOrder,
        }
      case OrderStateChangeActionType.CHANGE_STATE_REQUEST:
        return {
          ...state,
          changeState: {
            ...state.changeState,
            isChangingState: true,
          },
        }
      case OrderStateChangeActionType.CHANGE_STATE_SUCCESS:
        return {
          ...state,
          changeState: {
            ...action.payload,
            isChangingState: false,
          },
        }
      case OrderStateChangeActionType.CHANGE_STATE_FAILURE:
        return {
          ...state,
          changeState: orderStateChangeInitialState.changeState,
        }
    }
  }

  const [state, dispatch] = useReducer(reducer, orderStateChangeInitialState)

  const {
    changeState: { changedStateOrderId },
    readOrder: { order },
  } = state

  const parsed = parse(location.search)

  const [isFirstLoad, setFirstLoad] = useState(true)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const { t } = useTranslation()
  const description =
    parsed.type === 'laundry'
      ? t('order_state_change_laundry_description')
      : parsed.next_state === 'washing'
      ? t('order_state_change_pickup_courier_description')
      : t('order_state_change_delivery_courier_description')
  const buttonDescription =
    parsed.type === 'laundry'
      ? t('washing_completed')
      : parsed.next_state === 'washing'
      ? t('pickup_completed')
      : t('delivery_completed')

  const readOrder = useCallback(async (params: ChangeStateParams) => {
    dispatch({ type: OrderStateChangeActionType.ORDER_READ_REQUEST })

    try {
      const order = await get<Order>(
        `${serverUrl}/order_change_status/check?${stringify(params)}`
      )

      dispatch({
        type: OrderStateChangeActionType.ORDER_READ_SUCCESS,
        payload: { order },
      })
      return order
    } catch (err) {
      dispatch({ type: OrderStateChangeActionType.ORDER_READ_FAILURE })
      return undefined
    }
  }, [])

  const changeState = useCallback(async (params: ChangeStateParams) => {
    dispatch({ type: OrderStateChangeActionType.CHANGE_STATE_REQUEST })

    try {
      await post<void>(`${serverUrl}/order_change_status/confirm`, params)

      dispatch({
        type: OrderStateChangeActionType.CHANGE_STATE_SUCCESS,
        payload: { changedStateOrderId: params.order_id },
      })
      alertService.showAlert('Ottimo lavoro!', 'success')
    } catch (err) {
      alertService.showAlert(err, 'error')
      dispatch({ type: OrderStateChangeActionType.CHANGE_STATE_FAILURE })
    }
  }, [])

  useEffect(() => {
    const readOrderAsync = async () => {
      if (readOrder) {
        await readOrder(parsed)
      }

      setFirstLoad(false)
    }

    void readOrderAsync()
  }, [])

  const changeStateAsync = async () => {
    if (changeState) {
      await changeState(parsed)
      setOpenConfirmModal(false)
    }
  }

  if (isFirstLoad) {
    return (
      <CenterContainer>
        <Loader />
      </CenterContainer>
    )
  }

  return (
    <>
      <Navbar>Pressing App</Navbar>
      <Container>
        {order ? (
          <>
            <ResponsiveSpacedRow>
              <Header>
                <HiLabel>{t('hi_message')}</HiLabel>
                <Info>{description}</Info>
              </Header>
              {!changedStateOrderId && (
                <Button
                  label={buttonDescription}
                  mode={ButtonType.SUCCESS_MAIN}
                  onClick={() => setOpenConfirmModal(true)}
                  startIcon={<CheckIcon />}
                  style={{ fontWeight: 'bold' }}
                />
              )}
            </ResponsiveSpacedRow>
            <HistoryColumn>
              <LabelSection>Storico</LabelSection>
              <ResponsiveHistoryInfo order={order} />
            </HistoryColumn>
            <ResponsiveColumn>
              <ResponsiveSpacedRow>
                <ClientInfoSection>
                  <LabelSection>Informazioni cliente</LabelSection>
                  <ResponsiveClientInfo order={order} />
                </ClientInfoSection>
                <ProductsList>
                  <LabelSection>Servizi acquistati</LabelSection>
                  <div>
                    {map(order.detail, (item) => (
                      <StyledOrderedProductCard
                        key={item.product_id}
                        order={order}
                        orderedProduct={item}
                      />
                    ))}
                  </div>
                </ProductsList>
              </ResponsiveSpacedRow>
            </ResponsiveColumn>
          </>
        ) : (
          <NotFound description={t('notfound_token')} />
        )}
      </Container>
      <Dialog
        title={`Conferma operazione`}
        mode={DialogType.CONFIRM}
        open={openConfirmModal}
        onCancelPressed={() => setOpenConfirmModal(false)}
        onConfirmPressed={() => changeStateAsync()}
      >
        <ModalMessage>
          {t('order_state_change_confirm_message_modal')}
        </ModalMessage>
      </Dialog>
    </>
  )
}

const Navbar = styled.div`
  background: ${Colors.primaryColor_500};
  height: 60px;
  justify-content: center;
  flex-direction: column;
  display: flex;
  font-size: 24px;
  line-height: 28px;
  color: ${Colors.white};
  padding-left: 20px;
  font-weight: bold;
`

const Container = styled(Column)`
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 25px;
  margin-bottom: 25px;
  @media only screen and (max-width: 770px) {
    padding-left: 10px;
    padding-right: 10px;
    width: 100% !important;
  }

  @media screen and (max-width: 1300px) {
    width: 75% !important;
  }

  @media screen and (max-width: 1500px) {
    width: 60% !important;
  }
`

const HiLabel = styled.div`
  font-weight: normal;
  font-size: 45px;
  line-height: 70px;
  color: ${Colors.textColor_500};
`

const Info = styled.div`
  font-weight: normal;
  font-size: 25px;
  line-height: 35px;
  color: ${Colors.textColor_400};
`

const Header = styled(Column)`
  max-width: 70%;

  @media only screen and (max-width: 770px) {
    max-width: unset;
    width: 340px;
    margin-bottom: 20px;
  }
`

const HistoryColumn = styled(Column)`
  margin-top: 45px;
  margin-bottom: 45px;
  @media only screen and (max-width: 770px) {
    align-self: center;
  }
`

const LabelSection = styled.div`
  font-size: 20px;
  line-height: 23px;
  color: ${Colors.textColor_500};
  margin-bottom: 15px;
`

const StyledOrderedProductCard = styled(OrderedProductCard)`
  margin-top: 10px;
  margin-bottom: 10px;
  &:first-child {
    margin-top: 0px;
  }
  @media only screen and (max-width: 770px) {
    width: 340px;
  }
`

const ProductsList = styled(Column)`
  align-self: baseline;
  padding-left: 15px;
  @media only screen and (max-width: 770px) {
    padding-left: 0px;
    margin-top: 30px;
    align-self: unset;
  }
`

const ClientInfoSection = styled(Column)`
  align-self: baseline;
  padding-right: 15px;
  @media only screen and (max-width: 770px) {
    padding-right: 0px;
    align-self: unset;
  }
`

const ResponsiveSpacedRow = styled(SpacedRow)`
  @media only screen and (max-width: 770px) {
    flex-direction: column;
  }
`

const ResponsiveClientInfo = styled(ClientInfo)`
  @media only screen and (max-width: 770px) {
    width: 340px;
  }
`

const ResponsiveHistoryInfo = styled(HistoryInfo)`
  @media only screen and (max-width: 770px) {
    width: 340px;
  }
`

const ResponsiveColumn = styled(Column)`
  width: 100%;
  @media only screen and (max-width: 770px) {
    align-self: center;
  }
`

const ModalMessage = styled.div`
  white-space: pre-line;
`

export default OrderStateChange
