import { isEmpty, isNull, isUndefined, map } from 'lodash'
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { deleteApi, get, post, put } from '../../Api/Api'
import { serverUrl } from '../../Api/api.config'
import { BasePage } from '../../components/Common/BasePage'
import { CenterContainer } from '../../components/Common/CenterContainer'
import { Dialog, DialogType } from '../../components/Common/Dialog'
import { EmptySet } from '../../components/Common/EmptySet'
import { Loader } from '../../components/Common/Loader'
import { LaundryCard } from '../../components/Laundry/LaundryCard'
import { LaundryDialog } from '../../components/Laundry/LaundryDialog'
import { LaundryHeader } from '../../components/Laundry/LaundryHeader'
import {
  CreateLaundryForm,
  EditLaundryForm,
  LaundriesAction,
  LaundriesActionType,
  laundriesInitialState,
  LaundriesState,
  Laundry,
} from '../../models/Laundry'
import { alertService } from '../../providers/AlertProvider'
import { AuthContext } from '../../providers/AuthProvider'
import { Colors } from '../../styles/colors'
import { Row } from '../../styles/commonStyledComponents'
import { ApiFilterManager } from '../../utils/filters'

const Laundries: FunctionComponent<Record<string, never>> = () => {
  const reducer = (
    state: LaundriesState,
    action: LaundriesAction
  ): LaundriesState => {
    switch (action.type) {
      case LaundriesActionType.LAUNDRIES_READ_REQUEST:
        return {
          ...state,
          readLaundries: {
            ...state.readLaundries,
            isLoading: true,
          },
        }
      case LaundriesActionType.LAUNDRIES_READ_SUCCESS:
        return {
          ...state,
          readLaundries: {
            ...action.payload,
            isLoading: false,
          },
        }
      case LaundriesActionType.LAUNDRIES_READ_FAILURE:
        return {
          ...state,
          readLaundries: laundriesInitialState.readLaundries,
        }
      case LaundriesActionType.LAUNDRY_CREATE_REQUEST:
        return {
          ...state,
          createLaundry: {
            ...state.createLaundry,
            isCreating: true,
          },
        }
      case LaundriesActionType.LAUNDRY_CREATE_SUCCESS:
        return {
          ...state,
          createLaundry: {
            ...action.payload,
            isCreating: false,
          },
        }
      case LaundriesActionType.LAUNDRY_CREATE_FAILURE:
        return {
          ...state,
          createLaundry: laundriesInitialState.createLaundry,
        }
      case LaundriesActionType.LAUNDRY_EDIT_REQUEST:
        return {
          ...state,
          editLaundry: {
            ...state.editLaundry,
            isEditing: true,
          },
        }
      case LaundriesActionType.LAUNDRY_EDIT_SUCCESS:
        return {
          ...state,
          editLaundry: {
            ...action.payload,
            isEditing: false,
          },
        }
      case LaundriesActionType.LAUNDRY_EDIT_FAILURE:
        return {
          ...state,
          editLaundry: laundriesInitialState.editLaundry,
        }
      case LaundriesActionType.LAUNDRY_DELETE_REQUEST:
        return {
          ...state,
          deleteLaundry: {
            ...state.deleteLaundry,
            isDeleting: true,
          },
        }
      case LaundriesActionType.LAUNDRY_DELETE_SUCCESS:
        return {
          ...state,
          deleteLaundry: {
            ...action.payload,
            isDeleting: false,
          },
        }
      case LaundriesActionType.LAUNDRY_DELETE_FAILURE:
        return {
          ...state,
          deleteLaundry: laundriesInitialState.deleteLaundry,
        }
    }
  }

  const [state, dispatch] = useReducer(reducer, laundriesInitialState)

  const {
    readLaundries: { laundries },
  } = state

  const [dialogOpen, setDialogOpen] = useState(false)
  const [isEditMode, setEditMode] = useState(false)
  const [toEdit, setToEdit] = useState<Laundry>()
  const [isFirstLoad, setFirstLoad] = useState(true)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [toDelete, setToDelete] = useState<Laundry>()
  const { t } = useTranslation()

  const {
    login: { token },
  } = useContext(AuthContext)

  const readLaundries = useCallback(
    async (search?: string) => {
      dispatch({ type: LaundriesActionType.LAUNDRIES_READ_REQUEST })

      try {
        const filterManager = new ApiFilterManager()

        if (!isUndefined(search) && !isNull(search) && !isEmpty(search)) {
          filterManager.add('name', 'like', search)
        }

        const url = filterManager.encode(`${serverUrl}/laundries`)
        const laundries = await get<Laundry[]>(url, token)

        dispatch({
          type: LaundriesActionType.LAUNDRIES_READ_SUCCESS,
          payload: {
            laundries,
            searchedText: search,
          },
        })
        return laundries
      } catch (err) {
        alertService.showAlert(err, 'error')
        dispatch({ type: LaundriesActionType.LAUNDRIES_READ_FAILURE })
        return undefined
      }
    },
    [token]
  )

  const createLaundry = useCallback(
    async (create: CreateLaundryForm) => {
      dispatch({ type: LaundriesActionType.LAUNDRY_CREATE_REQUEST })

      try {
        const createdLaundry = await post<Laundry>(
          `${serverUrl}/laundries`,
          create,
          token
        )

        dispatch({
          type: LaundriesActionType.LAUNDRY_CREATE_SUCCESS,
          payload: {
            createdLaundry,
          },
        })
        return createdLaundry
      } catch (err) {
        alertService.showAlert(err, 'error')
        dispatch({ type: LaundriesActionType.LAUNDRY_CREATE_FAILURE })
        return undefined
      }
    },
    [token]
  )

  const editLaundry = useCallback(
    async (id: number, edit: EditLaundryForm) => {
      dispatch({ type: LaundriesActionType.LAUNDRY_EDIT_REQUEST })

      try {
        const editedLaundry = await put<Laundry>(
          `${serverUrl}/laundries/${id}`,
          edit,
          token
        )

        dispatch({
          type: LaundriesActionType.LAUNDRY_EDIT_SUCCESS,
          payload: {
            editedLaundry,
          },
        })
        return editedLaundry
      } catch (err) {
        alertService.showAlert(err, 'error')
        dispatch({ type: LaundriesActionType.LAUNDRY_EDIT_FAILURE })
        return undefined
      }
    },
    [token]
  )

  const deleteLaundry = useCallback(
    async (id: number) => {
      dispatch({ type: LaundriesActionType.LAUNDRY_DELETE_REQUEST })

      try {
        await deleteApi(`${serverUrl}/laundries/${id}`, token)

        dispatch({
          type: LaundriesActionType.LAUNDRY_DELETE_SUCCESS,
          payload: {
            deletedId: id,
          },
        })
      } catch (err) {
        alertService.showAlert(err, 'error')
        dispatch({ type: LaundriesActionType.LAUNDRY_DELETE_FAILURE })
      }
    },
    [token]
  )

  useEffect(() => {
    const readLaundriesAsync = async () => {
      if (readLaundries) {
        await readLaundries()
      }

      setFirstLoad(false)
    }

    void readLaundriesAsync()
  }, [state.deleteLaundry.deletedId])

  const onNewLaundryPressed = () => {
    setEditMode(false)
    setToEdit(undefined)
    setDialogOpen(true)
  }

  const handleClose = () => setDialogOpen(false)

  const onEdit = (laundry: Laundry) => {
    setEditMode(true)
    setToEdit(laundry)
    setDialogOpen(true)
  }

  const onDelete = (laundry: Laundry) => {
    setToDelete(laundry)
    setOpenDeleteModal(true)
  }

  const deleteLaundryAsync = async () => {
    if (deleteLaundry && toDelete) {
      await deleteLaundry(toDelete.laundry_id)
      setOpenDeleteModal(false)
    }
  }

  const wrapWithBasePage = (body: JSX.Element) => {
    return (
      <BasePage
        header={
          <LaundryHeader
            onNewLaundryPressed={onNewLaundryPressed}
            readLaundries={readLaundries}
          />
        }
        pageKey='laundries'
      >
        {body}
        <LaundryDialog
          open={dialogOpen}
          close={handleClose}
          isEditMode={isEditMode}
          toEdit={toEdit}
          readLaundries={readLaundries}
          createLaundry={createLaundry}
          editLaundry={editLaundry}
        />
        <Dialog
          title={`${t('delete')} ${t('laundry').toLocaleLowerCase()}`}
          mode={DialogType.DELETE}
          open={openDeleteModal}
          onCancelPressed={() => setOpenDeleteModal(false)}
          onConfirmPressed={deleteLaundryAsync}
          onClose={() => setOpenDeleteModal(false)}
        >
          <ModalMessage>
            {t('delete_laundry_text_modal')}
            <br />
            <br />
            <DangerText>{t('important')}</DangerText>:{' '}
            {t('delete_laundry_important_text_modal')}
          </ModalMessage>
        </Dialog>
      </BasePage>
    )
  }

  if (isFirstLoad) {
    return wrapWithBasePage(
      <CenterContainer>
        <Loader />
      </CenterContainer>
    )
  }

  if (isNull(laundries) || isUndefined(laundries) || isEmpty(laundries)) {
    return wrapWithBasePage(<EmptySet description={t('laundries_empty_set')} />)
  }

  return wrapWithBasePage(
    <Container>
      {map(laundries, (item: Laundry) => (
        <StyledLaundryCard
          key={item.laundry_id}
          laundry={item}
          onDelete={() => onDelete(item)}
          onEdit={() => onEdit(item)}
        />
      ))}
    </Container>
  )
}

const Container = styled(Row)`
  flex-wrap: wrap;
  padding: 15px;
`

const DangerText = styled.span`
  color: ${Colors.red_500};
  font-weight: bold;
  text-transform: uppercase;
`

const ModalMessage = styled.div`
  white-space: pre-line;
`

const StyledLaundryCard = styled(LaundryCard)`
  margin: 10px;
`

export default Laundries
