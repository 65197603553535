import { ThemeProvider } from '@material-ui/core'
import React from 'react'

import { AlertProvider } from './providers/AlertProvider'
import { AuthProvider } from './providers/AuthProvider'
import { CategoriesProvider } from './providers/CategoriesProvider'
import { Routing } from './routing/Routing'
import { defaultTheme } from './styles/muiTheme'

const App = (): JSX.Element => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <AlertProvider>
        <AuthProvider>
          <CategoriesProvider>
            <Routing />
          </CategoriesProvider>
        </AuthProvider>
      </AlertProvider>
    </ThemeProvider>
  )
}

export default App
