import { map } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Gender, Product } from '../../../models/Product'
import { Service, servicesList, serviceSvgs } from '../../../models/Service'
import { Colors } from '../../../styles/colors'
import {
  Column,
  Row,
  SpaceBox,
  SpacedRow,
} from '../../../styles/commonStyledComponents'
import { pricify } from '../../../utils/formatting'
import { ProductMenu } from '../ProductMenu'

interface ProcuctCardProps {
  product: Product
  onEdit: () => void
  onDelete: () => void
  onDuplicate: () => void
  className?: string
}

const ProductCard = ({
  className,
  onDelete,
  onDuplicate,
  onEdit,
  product,
}: ProcuctCardProps): JSX.Element => {
  const {
    category,
    full_price,
    gender,
    ironing_price,
    name,
    photo_link,
    washing_price,
  } = product

  const { t } = useTranslation()

  const priceValues: Record<Service, number | null | undefined> = {
    wash: washing_price,
    iron: ironing_price,
    full: full_price,
  }

  return (
    <Container className={className}>
      <Image src={photo_link || '/assets/png/broken_link.png'} />
      <Divider />
      <Body>
        <SpacedRow>
          <CategoryLabel gender={gender}>{category}</CategoryLabel>
          <Column>
            <ProductMenu
              onEdit={onEdit}
              onDelete={onDelete}
              onDuplicate={onDuplicate}
            />
          </Column>
        </SpacedRow>
        <SpaceBox height={5} />
        <Name>{name}</Name>
        <SpaceBox height={15} />
        <InfoPriceLabel>{t('info_service_price')}:</InfoPriceLabel>
        <SpaceBox height={5} />
        {map(servicesList, (service: Service) => {
          const value = priceValues[service]
          const Icon = serviceSvgs[service]
          return (
            <>
              <SpaceBox height={5} />
              <SpacedRow key={service}>
                <ServiceLabel withLine={!value}>
                  <Icon height='16px' width='16px' /> <SpaceBox width={5} />{' '}
                  {t(`product_${service}_service`)}:
                </ServiceLabel>
                {value ? (
                  <Price>{pricify(value)}</Price>
                ) : (
                  <ServiceLabel withLine={!value}>N/A</ServiceLabel>
                )}
              </SpacedRow>
            </>
          )
        })}
      </Body>
    </Container>
  )
}

const Container = styled(Column)`
  width: 200px;
  background: ${Colors.white};
  border: 1px solid ${Colors.textColor_50};
  box-sizing: border-box;
  box-shadow: 0px 8px 8px ${Colors.textColor_50};
  border-radius: 6px;
`

const Image = styled.img`
  border-radius: 6px;
  width: 125px;
  height: 125px;
  margin: auto;
  padding: 12px;
  object-fit: scale-down;
`

const Divider = styled.div`
  align-self: stretch;
  height: 1px;
  background-color: ${Colors.textColor_50};
`

const Body = styled(Column)`
  padding: 12px;
  align-self: stretch;
`

interface GenderLabelProps {
  gender?: Gender | null
}

const CategoryLabel = styled(Column)<GenderLabelProps>`
  background: ${(props) =>
    props.gender === 'M'
      ? Colors.primaryColor_50
      : props.gender === 'F'
      ? Colors.red_50
      : Colors.textColor_50};
  border-radius: 2px;
  color: ${(props) =>
    props.gender === 'M'
      ? Colors.primaryColor_500
      : props.gender === 'F'
      ? Colors.red_500
      : Colors.textColor_500};
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  padding: 5px;
  max-width: 125px;
`

const Name = styled(SpacedRow)`
  word-break: break-all;
  text-transform: capitalize;
  color: ${Colors.textColor_500};
  font-size: 18px;
  line-height: 19px;
`

const InfoPriceLabel = styled(SpacedRow)`
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: ${Colors.textColor_300};
`

interface ServiceLabelProps {
  withLine?: boolean
}

const ServiceLabel = styled(Row)<ServiceLabelProps>`
  font-size: 14px;
  line-height: 14px;
  color: ${Colors.textColor_400};
  text-decoration-line: ${(props) =>
    props.withLine ? 'line-through' : 'none'};
`

const Price = styled(Row)`
  font-size: 14px;
  line-height: 14px;
  text-align: right;
  font-weight: 600;
  color: ${Colors.primaryColor_500};
`

export default ProductCard
