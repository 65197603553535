import SvgIcon from '@material-ui/core/SvgIcon'
import EventNoteIcon from '@material-ui/icons/EventNote'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ListAltIcon from '@material-ui/icons/ListAlt'
import LocalLaundryServiceIcon from '@material-ui/icons/LocalLaundryService'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import SettingsIcon from '@material-ui/icons/Settings'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'

import { Couriers } from '../pages/Couriers'
import { Discounts } from '../pages/Discounts'
import { Laundries } from '../pages/Laundries'
import { Login } from '../pages/Login'
import { Logout } from '../pages/Logout'
import { OrderDetail } from '../pages/OrderDetail'
import { Orders } from '../pages/Orders'
import { OrderStateChange } from '../pages/OrderStateChange'
import { Products } from '../pages/Products'
import { Schedules } from '../pages/Schedules'
import { Settings } from '../pages/Settings'

export type RouteItem = {
  path: string
  component: React.ComponentType
  private?: boolean // Routes for authorized client
  sidebar?: SidebarType
}

export type SidebarType = {
  localization_key: string
  icon: typeof SvgIcon
}

export const routes: RouteItem[] = [
  {
    path: '/login',
    component: Login,
    private: false,
  },
  {
    path: '/change-order-state',
    component: OrderStateChange,
    private: false,
  },
  {
    path: '/orders',
    component: Orders,
    private: true,
    sidebar: {
      localization_key: 'orders',
      icon: ShoppingCartIcon,
    },
  },
  {
    path: '/orders/:id',
    component: OrderDetail,
    private: true,
  },
  {
    path: '/products',
    component: Products,
    private: true,
    sidebar: {
      localization_key: 'products',
      icon: ListAltIcon,
    },
  },
  {
    path: '/discounts',
    component: Discounts,
    private: true,
    sidebar: {
      localization_key: 'discounts',
      icon: LocalOfferIcon,
    },
  },
  {
    path: '/schedules',
    component: Schedules,
    private: true,
    sidebar: {
      localization_key: 'schedules',
      icon: EventNoteIcon,
    },
  },
  {
    path: '/couriers',
    component: Couriers,
    private: true,
    sidebar: {
      localization_key: 'couriers',
      icon: LocalShippingIcon,
    },
  },
  {
    path: '/laundries',
    component: Laundries,
    private: true,
    sidebar: {
      localization_key: 'laundries',
      icon: LocalLaundryServiceIcon,
    },
  },
  {
    path: '/settings',
    component: Settings,
    private: true,
    sidebar: {
      localization_key: 'settings',
      icon: SettingsIcon,
    },
  },
  {
    path: '/logout',
    component: Logout,
    private: true,
    sidebar: {
      localization_key: 'logout',
      icon: ExitToAppIcon,
    },
  },
]
