import { ReactComponent as CrownSvg } from '../assets/svg/crown.svg'
import { ReactComponent as IronSvg } from '../assets/svg/iron.svg'
import { ReactComponent as WashSvg } from '../assets/svg/washer.svg'

export type Service = 'iron' | 'wash' | 'full'

export const servicesList: Service[] = ['wash', 'iron', 'full']

export const serviceIcons: Record<Service, string> = {
  wash: '/assets/svg/washer.svg',
  iron: '/assets/svg/iron.svg',
  full: '/assets/svg/crown.svg',
}

export const serviceSvgs: Record<
  Service,
  React.FunctionComponent<React.SVGProps<SVGSVGElement>>
> = {
  wash: WashSvg,
  iron: IronSvg,
  full: CrownSvg,
}
