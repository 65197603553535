import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Discount } from '../../../models/Discount'
import { serviceSvgs } from '../../../models/Service'
import { Colors } from '../../../styles/colors'
import {
  Column,
  Row,
  SpaceBox,
  SpacedRow,
} from '../../../styles/commonStyledComponents'
import { toItalianDate } from '../../../utils/formatting'
import { BasicMenu } from '../../Common/BasicMenu'

interface DiscountCardProps {
  discount: Discount
  className?: string
  onDelete: () => void
  onEdit: () => void
}

const DiscountCard = (props: DiscountCardProps): JSX.Element => {
  const { className, discount, onDelete, onEdit } = props
  const {
    category,
    description,
    expire_date,
    product_name,
    quantity,
    service,
    start_date,
  } = discount
  const { t } = useTranslation()

  const SvgIcon = service && serviceSvgs[service]
  const serviceDescription = service && t(`service_${service}`)

  return (
    <Container className={className}>
      <SpacedRow>
        <Column>
          <DiscountBox>{discount.discount}%</DiscountBox>
        </Column>
        <MenuBox>
          <BasicMenu onDelete={onDelete} onEdit={onEdit} />
        </MenuBox>
      </SpacedRow>
      <SpaceBox height={5} />
      <Body>
        <SpacedRow>
          <Column>
            <Name>{description}</Name>
          </Column>
        </SpacedRow>
        <SpaceBox height={10} />
        <SpacedRow>
          <Column>
            <SubTitle>Inizio: {toItalianDate(start_date)}</SubTitle>
            <SpaceBox height={5} />
            <SubTitle>Fine: {toItalianDate(expire_date)}</SubTitle>
          </Column>
        </SpacedRow>

        {category && (
          <>
            <SpaceBox height={10} />
            <SpacedRow>
              <Column>
                <SubTitle>{t('category')}</SubTitle>
                <SpaceBox height={3} />
                <Value>{category}</Value>
              </Column>
            </SpacedRow>
          </>
        )}
        {quantity && (
          <>
            <SpaceBox height={10} />
            <SpacedRow>
              <Column>
                <SubTitle>{t('minimum_quantity')}</SubTitle>
                <SpaceBox height={3} />
                <Value>
                  {quantity} {quantity == 1 ? t('item') : t('items')}
                </Value>
              </Column>
            </SpacedRow>
          </>
        )}
        {product_name && (
          <>
            <SpaceBox height={10} />
            <SpacedRow>
              <Column>
                <SubTitle>{t(`product`)}</SubTitle>
                <SpaceBox height={3} />
                <Value>{product_name}</Value>
              </Column>
            </SpacedRow>
          </>
        )}
        {service && SvgIcon && (
          <>
            <SpaceBox height={10} />
            <SpacedRow>
              <Column>
                <SubTitle>{t(`service`)}</SubTitle>
                <SpaceBox height={3} />
                <Value>
                  <SvgIcon
                    height='16px'
                    width='16px'
                    fill={Colors.textColor_500}
                    style={{ marginRight: '5px' }}
                  />
                  {serviceDescription}
                </Value>
              </Column>
            </SpacedRow>
          </>
        )}
      </Body>
    </Container>
  )
}

const Container = styled(Column)`
  width: 200px;
  background: ${Colors.white};
  border: 1px solid ${Colors.textColor_50};
  box-sizing: border-box;
  box-shadow: 0px 8px 8px ${Colors.textColor_50};
  border-radius: 6px;
  align-items: baseline;
  padding-bottom: 15px;
`

const DiscountBox = styled.div`
  background: ${Colors.red_500};
  border-radius: 4px 0px 4px 0px;
  font-weight: 500;
  color: ${Colors.white};
  padding: 5px;
`

const Name = styled(Row)`
  word-break: break-word;
  font-size: 20px;
  line-height: 23px;
  color: ${Colors.textColor_500};
`

const SubTitle = styled(Column)`
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: ${Colors.textColor_300};
`

const Value = styled(Row)`
  word-break: break-word;
`

const MenuBox = styled(Column)`
  padding-right: 10px;
`

const Body = styled(Column)`
  padding-right: 10px;
  padding-left: 10px;
`

export default DiscountCard
