export type Photo = {
  photo_id: number
  link: string
  // FIXME: Set the correct literals for type
  type: string
}

export type PhotosState = {
  readPhotos: {
    photos?: Photo[]
    isLoading: boolean
  }
}

export const photosInitialState: PhotosState = {
  readPhotos: {
    photos: undefined,
    isLoading: false,
  },
}

export enum PhotosActionType {
  PHOTOS_READ_REQUEST,
  PHOTOS_READ_SUCCESS,
  PHOTOS_READ_FAILURE,
}

export type PhotosAction =
  | { type: PhotosActionType.PHOTOS_READ_REQUEST }
  | {
      type: PhotosActionType.PHOTOS_READ_SUCCESS
      payload: { photos?: Photo[] }
    }
  | { type: PhotosActionType.PHOTOS_READ_FAILURE }
