import { isEmpty, isNull, isUndefined, map, union } from 'lodash'
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import InfiniteScroll from 'react-infinite-scroll-component'
import styled from 'styled-components'

import { deleteApi, get, post, put } from '../../Api/Api'
import { serverUrl } from '../../Api/api.config'
import { BasePage } from '../../components/Common/BasePage'
import { CenterContainer } from '../../components/Common/CenterContainer'
import { Dialog, DialogType } from '../../components/Common/Dialog'
import { EmptySet } from '../../components/Common/EmptySet'
import { Loader } from '../../components/Common/Loader'
import { ProductCard } from '../../components/Products/ProductCard'
import { ProductDialog } from '../../components/Products/ProductDialog'
import ProductsHeader from '../../components/Products/ProductsHeader/ProductsHeader'
import {
  CreateProductForm,
  EditProductForm,
  Product,
  ProductGetList,
  ProductsAction,
  ProductsActionType,
  productsInitialState,
  ProductsState,
} from '../../models/Product'
import { Service } from '../../models/Service'
import { alertService } from '../../providers/AlertProvider'
import { AuthContext } from '../../providers/AuthProvider'
import { Row } from '../../styles/commonStyledComponents'
import { ApiFilterManager } from '../../utils/filters'

const Products: FunctionComponent<Record<string, never>> = () => {
  const reducer = (
    state: ProductsState,
    action: ProductsAction
  ): ProductsState => {
    switch (action.type) {
      case ProductsActionType.PRODUCTS_READ_REQUEST:
        return {
          ...state,
          readProducts: {
            ...state.readProducts,
            isLoading: true,
          },
        }
      case ProductsActionType.PRODUCTS_READ_SUCCESS:
        return {
          ...state,
          readProducts: {
            ...action.payload,
            products:
              action.payload.currentPage > 1
                ? union(
                    state.readProducts.products || [],
                    action.payload.products || []
                  )
                : action.payload.products,
            totalPage: action.payload.total,
            isLoading: false,
          },
        }
      case ProductsActionType.PRODUCTS_READ_FAILURE:
        return {
          ...state,
          readProducts: productsInitialState.readProducts,
        }
      case ProductsActionType.PRODUCT_CREATE_REQUEST:
        return {
          ...state,
          createProduct: {
            ...state.createProduct,
            isCreating: true,
          },
        }
      case ProductsActionType.PRODUCT_CREATE_SUCCESS:
        return {
          ...state,
          createProduct: {
            ...action.payload,
            isCreating: false,
          },
        }
      case ProductsActionType.PRODUCT_CREATE_FAILURE:
        return {
          ...state,
          createProduct: productsInitialState.createProduct,
        }
      case ProductsActionType.PRODUCT_EDIT_REQUEST:
        return {
          ...state,
          editProduct: {
            ...state.editProduct,
            isEditing: true,
          },
        }
      case ProductsActionType.PRODUCT_EDIT_SUCCESS:
        return {
          ...state,
          editProduct: {
            ...action.payload,
            isEditing: false,
          },
        }
      case ProductsActionType.PRODUCT_EDIT_FAILURE:
        return {
          ...state,
          editProduct: productsInitialState.editProduct,
        }
      case ProductsActionType.PRODUCT_DELETE_REQUEST:
        return {
          ...state,
          deleteProduct: {
            ...state.deleteProduct,
            isDeleting: true,
          },
        }
      case ProductsActionType.PRODUCT_DELETE_SUCCESS:
        return {
          ...state,
          deleteProduct: {
            ...action.payload,
            isDeleting: false,
          },
        }
      case ProductsActionType.PRODUCT_DELETE_FAILURE:
        return {
          ...state,
          deleteProduct: productsInitialState.deleteProduct,
        }
    }
  }

  const [state, dispatch] = useReducer(reducer, productsInitialState)

  const {
    readProducts: {
      currentPage,
      filteredService,
      products,
      searchedText,
      totalPage,
    },
  } = state

  const { t } = useTranslation()

  const [isFirstLoad, setFirstLoad] = useState(true)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [isEditMode, setEditMode] = useState(false)
  const [toEdit, setToEdit] = useState<Product>()
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [toDelete, setToDelete] = useState<Product>()

  const {
    login: { token },
  } = useContext(AuthContext)

  const readProducts = useCallback(
    async (search?: string, service?: Service, page = 1) => {
      dispatch({ type: ProductsActionType.PRODUCTS_READ_REQUEST })

      try {
        const filterManager = new ApiFilterManager()

        if (!isUndefined(search) && !isNull(search) && !isEmpty(search)) {
          filterManager.add('name', 'like', search)
        }

        if (!isUndefined(service) && !isNull(service)) {
          switch (service) {
            case 'wash': {
              filterManager.add('washing_price', '>', '0')
              break
            }
            case 'iron': {
              filterManager.add('ironing_price', '>', '0')
              break
            }
            case 'full': {
              filterManager.add('full_price', '>', '0')
              break
            }
          }
        }

        const url = filterManager.encode(
          `${serverUrl}/products/admin?page=${page as number}`
        )
        const productsList = await get<ProductGetList>(url, token)

        dispatch({
          type: ProductsActionType.PRODUCTS_READ_SUCCESS,
          payload: {
            products: productsList.data,
            currentPage: productsList.current_page || 1,
            total: productsList.last_page || 1,
            searchedText: search,
            filteredService: service,
          },
        })
        return productsList
      } catch (err) {
        alertService.showAlert(err, 'error')
        dispatch({ type: ProductsActionType.PRODUCTS_READ_FAILURE })
        return undefined
      }
    },
    [token]
  )

  const createProduct = useCallback(
    async (create: CreateProductForm) => {
      dispatch({ type: ProductsActionType.PRODUCT_CREATE_REQUEST })

      try {
        const createdProduct = await post<Product>(
          `${serverUrl}/products`,
          create,
          token
        )

        dispatch({
          type: ProductsActionType.PRODUCT_CREATE_SUCCESS,
          payload: { createdProduct },
        })
        return createdProduct
      } catch (err) {
        alertService.showAlert(err, 'error')
        dispatch({ type: ProductsActionType.PRODUCT_CREATE_FAILURE })
        return undefined
      }
    },
    [token]
  )

  const editProduct = useCallback(
    async (id: number, edit: EditProductForm) => {
      dispatch({ type: ProductsActionType.PRODUCT_EDIT_REQUEST })

      try {
        const editedProduct = await put<Product>(
          `${serverUrl}/products/${id}`,
          edit,
          token
        )

        dispatch({
          type: ProductsActionType.PRODUCT_EDIT_SUCCESS,
          payload: { editedProduct },
        })
        return editedProduct
      } catch (err) {
        alertService.showAlert(err, 'error')
        dispatch({ type: ProductsActionType.PRODUCT_EDIT_FAILURE })
        return undefined
      }
    },
    [token]
  )

  const deleteProduct = useCallback(
    async (id: number) => {
      dispatch({ type: ProductsActionType.PRODUCT_DELETE_REQUEST })

      try {
        await deleteApi(`${serverUrl}/products/${id}`, token)

        dispatch({
          type: ProductsActionType.PRODUCT_DELETE_SUCCESS,
          payload: { deletedId: id },
        })
      } catch (err) {
        alertService.showAlert(err, 'error')
        dispatch({ type: ProductsActionType.PRODUCT_DELETE_FAILURE })
      }
    },
    [token]
  )

  useEffect(() => {
    const readProductsAsync = async () => {
      if (readProducts) {
        await readProducts()
      }

      setFirstLoad(false)
    }

    void readProductsAsync()
  }, [state.deleteProduct.deletedId])

  const handleClose = () => setDialogOpen(false)

  const onNewProductPressed = () => {
    setEditMode(false)
    setToEdit(undefined)
    setDialogOpen(true)
  }

  const onEdit = (product: Product) => {
    setEditMode(true)
    setToEdit(product)
    setDialogOpen(true)
  }

  const onDelete = (product: Product) => {
    setToDelete(product)
    setOpenDeleteModal(true)
  }

  const onDuplicate = async (product: Product) => {
    await createProduct({
      italian_name: product.italian_name,
      french_name: product.french_name,
      category_translation_id: product.category_translation_id,
      gender: product.gender || null,
      washing_price: product.washing_price || undefined,
      ironing_price: product.ironing_price || undefined,
      full_price: product.full_price || undefined,
      french_category: product.french_category,
      italian_category: product.italian_category,
      photo_link: product.photo_link || undefined,
    })
    void readProducts()
  }

  const deleteProductAsync = async () => {
    if (deleteProduct && toDelete) {
      await deleteProduct(toDelete.product_id)
      setOpenDeleteModal(false)
    }
  }

  const wrapWithBasePage = (body: JSX.Element) => {
    return (
      <BasePage
        header={
          <ProductsHeader
            onNewProductPressed={onNewProductPressed}
            readProducts={readProducts}
            filteredService={filteredService}
            searchedText={searchedText}
          />
        }
        pageKey='products'
      >
        {body}
        <ProductDialog
          open={dialogOpen}
          close={handleClose}
          isEditMode={isEditMode}
          toEdit={toEdit}
          readProducts={readProducts}
          createProduct={createProduct}
          editProduct={editProduct}
        />
        <Dialog
          title={`${t('delete')} ${toDelete ? toDelete.name : ''}`}
          mode={DialogType.DELETE}
          open={openDeleteModal}
          onCancelPressed={() => setOpenDeleteModal(false)}
          onConfirmPressed={deleteProductAsync}
          onClose={() => setOpenDeleteModal(false)}
        >
          <ModalMessage>{t('delete_product_text_modal')}</ModalMessage>
        </Dialog>
      </BasePage>
    )
  }

  const fetchData = async () => {
    await readProducts(undefined, undefined, currentPage ? currentPage + 1 : 1)
  }

  if (isFirstLoad) {
    return wrapWithBasePage(
      <CenterContainer>
        <Loader />
      </CenterContainer>
    )
  }

  if (isNull(products) || isUndefined(products) || isEmpty(products)) {
    return wrapWithBasePage(<EmptySet description={t('products_empty_set')} />)
  }

  return wrapWithBasePage(
    <InfiniteScroll
      dataLength={totalPage ? totalPage : 1}
      next={fetchData}
      hasMore={currentPage < totalPage}
      loader={<PaginationLabel>{t('products_in_loading')}</PaginationLabel>}
      endMessage={<PaginationLabel>{t('all_products_loaded')}</PaginationLabel>}
    >
      <Container>
        {map(products, (item: Product) => (
          <StyledProductCard
            key={item.product_id}
            onEdit={() => onEdit(item)}
            onDelete={() => onDelete(item)}
            onDuplicate={() => onDuplicate(item)}
            product={item}
          />
        ))}
      </Container>
    </InfiniteScroll>
  )
}

const Container = styled(Row)`
  flex-wrap: wrap;
  padding: 15px;
`

const StyledProductCard = styled(ProductCard)`
  margin: 10px;
`

const PaginationLabel = styled.p`
  text-align: center;
  font-weight: 400;
`

const ModalMessage = styled.div`
  white-space: pre-line;
`

export default Products
