import { DialogProps } from '@material-ui/core'
import { isEmpty, isUndefined, omit } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import {
  CreateLaundryForm,
  EditLaundryForm,
  Laundry,
} from '../../../models/Laundry'
import { Colors } from '../../../styles/colors'
import { SpaceBox } from '../../../styles/commonStyledComponents'
import { Dialog, DialogType } from '../../Common/Dialog'
import { TextInput } from '../../Common/TextInput'
import { LocationPicker } from '../LocationPicker'

interface LaundryDialogProps extends DialogProps {
  close: () => void
  isEditMode?: boolean
  toEdit?: Laundry
  className?: string
  editLaundry: (
    id: number,
    edit: EditLaundryForm
  ) => Promise<Laundry | undefined>
  createLaundry: (create: CreateLaundryForm) => Promise<Laundry | undefined>
  readLaundries: (search?: string | undefined) => Promise<Laundry[] | undefined>
}

const LaundryDialog = ({
  className,
  close,
  createLaundry,
  editLaundry,
  isEditMode,
  readLaundries,
  toEdit,
  ...props
}: LaundryDialogProps): JSX.Element => {
  const { t } = useTranslation()

  const [form, setForm] = useState<CreateLaundryForm | EditLaundryForm>(
    isEditMode && toEdit
      ? {
          name: toEdit.name || '',
          phone: toEdit.phone || '',
          vat_code: toEdit.vat_code || '',
        }
      : {
          name: '',
          email: '',
          phone: '',
          vat_code: '',
          latitude: undefined,
          longitude: undefined,
          address: '',
        }
  )
  const [errors, setErrors] = useState<{ [key: string]: string }>({})

  // When the dialog is opened/closed, reset the state
  useEffect(() => {
    if (props.open) {
      setErrors({})
      setForm(
        isEditMode && toEdit
          ? {
              name: toEdit.name || '',
              phone: toEdit.phone || '',
              vat_code: toEdit.vat_code || '',
            }
          : {
              name: '',
              email: '',
              phone: '',
              vat_code: '',
              latitude: undefined,
              longitude: undefined,
              address: '',
            }
      )
    }
  }, [props.open, isEditMode, toEdit])

  // It sucks, I know, but I didn't want to make it better, just collapse the function body and don't look inside ;)
  const validate = () => {
    let hasErrors = false

    // Email
    if (!isEditMode && isEmpty((form as CreateLaundryForm).email)) {
      setErrors((prev) => ({ ...prev, email: t('laundry_email_empty') }))
      hasErrors = true
    } else {
      setErrors((prev) => omit(prev, ['email']))
    }

    // Name
    if (isEmpty(form.name)) {
      setErrors((prev) => ({ ...prev, name: t('laundry_name_empty') }))
      hasErrors = true
    } else {
      setErrors((prev) => omit(prev, ['name']))
    }

    // Phone
    if (isEmpty(form.phone)) {
      setErrors((prev) => ({ ...prev, phone: t('laundry_phone_empty') }))
      hasErrors = true
    } else {
      setErrors((prev) => omit(prev, ['phone']))
    }

    // Location
    if (
      !isEditMode &&
      (isUndefined((form as CreateLaundryForm).latitude) ||
        isUndefined((form as CreateLaundryForm).longitude) ||
        isEmpty((form as CreateLaundryForm).address))
    ) {
      setErrors((prev) => ({ ...prev, location: t('laundry_location_empty') }))
      hasErrors = true
    } else {
      setErrors((prev) => omit(prev, ['location']))
    }

    return !hasErrors
  }

  const onConfirmPressed = async () => {
    if (!validate()) {
      return
    }

    if (isEditMode && toEdit) {
      const edited = await editLaundry(toEdit.laundry_id, form)
      if (edited) {
        close()
      }
    } else {
      const created = await createLaundry(form as CreateLaundryForm)
      if (created) {
        close()
      }
    }

    void readLaundries()
  }

  return (
    <Dialog
      {...props}
      className={className}
      title={isEditMode && toEdit ? t('edit_laundry') : t('new_laundry')}
      mode={isEditMode && toEdit ? DialogType.EDIT : DialogType.CREATE}
      onClose={close}
      onCancelPressed={close}
      onConfirmPressed={onConfirmPressed}
      fullWidth
    >
      <StyledTextInput
        label={t('name_label')}
        type='text'
        value={form.name}
        onChange={(e) => setForm({ ...form, name: e.target.value })}
      />
      <SpaceBox height={8} />
      <Error>{errors['name']}</Error>
      <SpaceBox height={24} />
      {!isEditMode && (
        <>
          <StyledTextInput
            label={t('email_label')}
            type='text'
            value={(form as CreateLaundryForm).email}
            onChange={(e) => setForm({ ...form, email: e.target.value })}
          />
          <SpaceBox height={8} />
          <Error>{errors['email']}</Error>
          <SpaceBox height={24} />
        </>
      )}
      <StyledTextInput
        label={t('phone_label')}
        type='text'
        value={form.phone}
        onChange={(e) => setForm({ ...form, phone: e.target.value })}
      />
      <SpaceBox height={8} />
      <Error>{errors['phone']}</Error>
      <SpaceBox height={24} />
      <StyledTextInput
        label={t('vat_code_label')}
        type='text'
        value={form.vat_code}
        onChange={(e) => setForm({ ...form, vat_code: e.target.value })}
      />
      {!isEditMode && (
        <>
          <SpaceBox height={24} />
          <StyledLocationPicker
            label={t('location_label')}
            onLocationPicked={(address, latLng) =>
              setForm({
                ...form,
                address,
                latitude: latLng.lat,
                longitude: latLng.lng,
              })
            }
          />
          <SpaceBox height={8} />
          <Error>{errors['location']}</Error>
        </>
      )}
    </Dialog>
  )
}

const StyledTextInput = styled(TextInput)`
  width: 100%;
`

const StyledLocationPicker = styled(LocationPicker)`
  width: 100%;
`

const Error = styled.p`
  font-size: 14px;
  margin: 0px;
  color: ${Colors.red_500};
`

export default LaundryDialog
