import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Order } from '../../../models/Order'
import { Colors } from '../../../styles/colors'
import {
  Column,
  SpaceBox,
  SpacedRow,
} from '../../../styles/commonStyledComponents'

interface ClientInfoProps {
  order: Order
  className?: string
}

const ClientInfo = ({ className, order }: ClientInfoProps): JSX.Element => {
  const { email, gender, name, phone, surname } = order.client
  const { address } = order
  const { t } = useTranslation()
  return (
    <Container className={className}>
      <Section>
        <StyledColumn>
          <SectionHeader>{t('client')}</SectionHeader>
          <Name>{`${surname} ${name}`}</Name>
        </StyledColumn>
        {gender && (
          <GenderImage
            src={
              gender == 'M' ? '/assets/svg/male.svg' : '/assets/svg/female.svg'
            }
          />
        )}
      </Section>
      <Divider />
      <Section>
        <Column>
          <SectionHeader>{t('contact')}</SectionHeader>
          <Info>
            <Label>{t('email_contact_label')}</Label>{' '}
            <Link href={`mailto:${email}`}>{email}</Link>
          </Info>
          <SpaceBox height={4} />
          <Info>
            <Label>{t('phone_contact_label')}</Label>{' '}
            <Link href={`tel:${phone}`}>{phone}</Link>
          </Info>
        </Column>
      </Section>
      <Divider />
      <Section>
        <Column>
          <SectionHeader>{t('delivery')}</SectionHeader>
          <Info>
            <Label>{t('address_delivery_label')}</Label>
            <Value>{address.address}</Value>
          </Info>
          <SpaceBox height={4} />
          <Info>
            <Label>{t('doorbell_delivery_label')}</Label>
            <Value>{address.doorbell_name}</Value>
          </Info>
        </Column>
      </Section>
    </Container>
  )
}

const Container = styled.div`
  width: 410px;
  background: ${Colors.white};
  border: 1px solid ${Colors.textColor_50};
  box-sizing: border-box;
  box-shadow: 0px 8px 8px ${Colors.textColor_50};
  border-radius: 6px;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Section = styled(SpacedRow)`
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
`

const SectionHeader = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.textColor_400};
  margin-bottom: 5px;
`

const Divider = styled.div`
  align-self: stretch;
  height: 1px;
  background-color: ${Colors.textColor_50};
`

const StyledColumn = styled.div`
  flex: 1;
  min-width: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const GenderImage = styled.img`
  width: 40px;
  height: 40px;
`

const Info = styled.span`
  display: flex;
`

const Label = styled.span`
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.textColor_300};
  margin-right: 8px;
`

const Value = styled.span`
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.textColor_500};
`

const Link = styled.a`
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.primaryColor_500};
`

const Name = styled.div`
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  line-height: 21px;
  color: ${Colors.textColor_500};
`

export default ClientInfo
