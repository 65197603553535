import { Chip, DialogProps, InputLabel, withStyles } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import { Autocomplete } from '@material-ui/lab'
import { find, isEmpty, isNull, isUndefined, map, omit, union } from 'lodash'
import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { get } from '../../../Api/Api'
import { serverUrl } from '../../../Api/api.config'
import {
  CreateDiscountForm,
  Discount,
  DiscountsGrouped,
  DiscountType,
  EditDiscountForm,
} from '../../../models/Discount'
import {
  ProductGetList,
  ProductsAction,
  ProductsActionType,
  productsInitialState,
  ProductsState,
} from '../../../models/Product'
import { Service, serviceSvgs } from '../../../models/Service'
import { alertService } from '../../../providers/AlertProvider'
import { AuthContext } from '../../../providers/AuthProvider'
import { CategoriesContext } from '../../../providers/CategoriesProvider'
import { Colors } from '../../../styles/colors'
import { Row, SpaceBox } from '../../../styles/commonStyledComponents'
import { ApiFilterManager } from '../../../utils/filters'
import { Dialog, DialogType } from '../../Common/Dialog'
import { Selector } from '../../Common/Selector'
import { TextInput } from '../../Common/TextInput'

interface DiscountDialogProps extends DialogProps {
  close: () => void
  isEditMode?: boolean
  toEdit?: Discount
  className?: string
  editDiscount: (
    id: number,
    edit: EditDiscountForm
  ) => Promise<Discount | undefined>
  createDiscount: (create: CreateDiscountForm) => Promise<Discount | undefined>
  readDiscounts: () => Promise<DiscountsGrouped | undefined>
}

const DiscountDialog = ({
  className,
  close,
  createDiscount,
  editDiscount,
  isEditMode,
  readDiscounts,
  toEdit,
  ...props
}: DiscountDialogProps): JSX.Element => {
  const reducer = (
    state: ProductsState,
    action: ProductsAction
  ): ProductsState => {
    switch (action.type) {
      case ProductsActionType.PRODUCTS_READ_REQUEST:
        return {
          ...state,
          readProducts: {
            ...state.readProducts,
            isLoading: true,
          },
        }
      case ProductsActionType.PRODUCTS_READ_SUCCESS:
        return {
          ...state,
          readProducts: {
            ...action.payload,
            products:
              action.payload.currentPage > 1
                ? union(
                    state.readProducts.products || [],
                    action.payload.products || []
                  )
                : action.payload.products,
            totalPage: action.payload.total,
            isLoading: false,
          },
        }
      default:
      case ProductsActionType.PRODUCTS_READ_FAILURE:
        return {
          ...state,
          readProducts: productsInitialState.readProducts,
        }
    }
  }

  const [state, dispatch] = useReducer(reducer, productsInitialState)

  const {
    readProducts: { products },
  } = state

  const {
    login: { token },
  } = useContext(AuthContext)

  const readProducts = useCallback(
    async (search?: string, service?: Service, page = 1) => {
      dispatch({ type: ProductsActionType.PRODUCTS_READ_REQUEST })

      try {
        const filterManager = new ApiFilterManager()

        if (!isUndefined(search) && !isNull(search) && !isEmpty(search)) {
          filterManager.add('name', 'like', search)
        }

        if (!isUndefined(service) && !isNull(service)) {
          switch (service) {
            case 'wash': {
              filterManager.add('washing_price', '>', '0')
              break
            }
            case 'iron': {
              filterManager.add('ironing_price', '>', '0')
              break
            }
            case 'full': {
              filterManager.add('full_price', '>', '0')
              break
            }
          }
        }

        const url = filterManager.encode(
          `${serverUrl}/products/admin?page=${page as number}`
        )
        const productsList = await get<ProductGetList>(url, token)

        dispatch({
          type: ProductsActionType.PRODUCTS_READ_SUCCESS,
          payload: {
            products: productsList.data,
            currentPage: productsList.current_page || 1,
            total: productsList.last_page || 1,
            searchedText: search,
            filteredService: service,
          },
        })
        return productsList
      } catch (err) {
        alertService.showAlert(err, 'error')
        dispatch({ type: ProductsActionType.PRODUCTS_READ_FAILURE })
        return undefined
      }
    },
    [token]
  )

  const { t } = useTranslation()

  const {
    readCategories: { categories, readCategories },
  } = useContext(CategoriesContext)

  const [form, setForm] = useState<CreateDiscountForm | EditDiscountForm>(
    isEditMode && toEdit
      ? {
          ...toEdit,
        }
      : {
          type: 'category',
          start_date: undefined,
          expire_date: undefined,
          italian_description: '',
          french_description: '',
        }
  )
  const [errors, setErrors] = useState<{ [key: string]: string }>({})

  // When the dialog is created, read the possible categories
  useEffect(() => {
    if (readCategories) {
      void readCategories()
    }

    void readProducts()
  }, [])

  // When the dialog is opened/closed, reset the state
  useEffect(() => {
    if (props.open) {
      setErrors({})
      setForm(
        isEditMode && toEdit
          ? {
              ...toEdit,
            }
          : {
              type: 'category',
              start_date: undefined,
              expire_date: undefined,
              italian_description: '',
              french_description: '',
            }
      )
    }
  }, [props.open, isEditMode, toEdit])

  // It sucks, I know, but I didn't want to make it better, just collapse the function body and don't look inside ;)
  const validate = () => {
    let hasErrors = false

    // Start date
    if (isUndefined(form.start_date) || isEmpty(form.start_date)) {
      setErrors((prev) => ({
        ...prev,
        start_date: t('discount_start_date_empty'),
      }))
      hasErrors = true
    } else {
      setErrors((prev) => omit(prev, ['start_date']))
    }

    // Expire date
    if (isUndefined(form.expire_date) || isEmpty(form.expire_date)) {
      setErrors((prev) => ({
        ...prev,
        expire_date: t('discount_expire_date_empty'),
      }))
      hasErrors = true
    } else {
      setErrors((prev) => omit(prev, ['expire_date']))
    }

    // Italian description
    if (!form.italian_description || isEmpty(form.italian_description)) {
      setErrors((prev) => ({
        ...prev,
        italian_description: t('discount_italian_description_empty'),
      }))
      hasErrors = true
    } else {
      setErrors((prev) => omit(prev, ['italian_description']))
    }

    // French description
    if (!form.french_description || isEmpty(form.french_description)) {
      setErrors((prev) => ({
        ...prev,
        french_description: t('discount_french_description_empty'),
      }))
      hasErrors = true
    } else {
      setErrors((prev) => omit(prev, ['french_description']))
    }

    // Discount
    if (isUndefined(form.discount)) {
      setErrors((prev) => ({
        ...prev,
        discount: t('discount_percentage_empty'),
      }))
      hasErrors = true
    } else if (form.discount <= 0) {
      setErrors((prev) => ({
        ...prev,
        discount: t('discount_percentage_positive'),
      }))
      hasErrors = true
    } else {
      setErrors((prev) => omit(prev, ['discount']))
    }

    // Type specific
    switch (form.type) {
      case 'category': {
        if (isUndefined(form.category_translation_id)) {
          setErrors((prev) => ({
            ...prev,
            category: t('discount_category_empty'),
          }))
          hasErrors = true
        } else {
          setErrors((prev) => omit(prev, ['category']))
        }
        break
      }
      case 'product': {
        if (isUndefined(form.product_id)) {
          setErrors((prev) => ({
            ...prev,
            product: t('discount_product_empty'),
          }))
          hasErrors = true
        } else {
          setErrors((prev) => omit(prev, ['product']))
        }
        break
      }
      case 'service': {
        if (isUndefined(form.product_id)) {
          setErrors((prev) => ({
            ...prev,
            product: t('discount_product_empty'),
          }))
          hasErrors = true
        } else {
          setErrors((prev) => omit(prev, ['product']))
        }

        if (isUndefined(form.service)) {
          setErrors((prev) => ({
            ...prev,
            service: t('discount_service_empty'),
          }))
          hasErrors = true
        } else {
          setErrors((prev) => omit(prev, ['service']))
        }
        break
      }
      case 'quantity': {
        if (isUndefined(form.product_id)) {
          setErrors((prev) => ({
            ...prev,
            product: t('discount_product_empty'),
          }))
          hasErrors = true
        } else {
          setErrors((prev) => omit(prev, ['product']))
        }

        if (isUndefined(form.quantity)) {
          setErrors((prev) => ({
            ...prev,
            quantity: t('discount_quantity_empty'),
          }))
          hasErrors = true
        } else if (form.quantity <= 0) {
          setErrors((prev) => ({
            ...prev,
            quantity: t('discount_quantity_positive'),
          }))
          hasErrors = true
        } else {
          setErrors((prev) => omit(prev, ['quantity']))
        }
        break
      }
    }

    return !hasErrors
  }

  const onConfirmPressed = async () => {
    if (!validate()) {
      return
    }

    if (isEditMode && toEdit) {
      const edited = await editDiscount(toEdit.discount_id, form)
      if (edited) {
        close()
      }
    } else {
      const created = await createDiscount(form as CreateDiscountForm)
      if (created) {
        close()
      }
    }

    void readDiscounts()
  }

  const WashSvg = serviceSvgs['wash']
  const IronSvg = serviceSvgs['iron']

  const washSelected = form.service === 'wash' || form.service === 'full'
  const ironSelected = form.service === 'iron' || form.service === 'full'

  return (
    <Dialog
      {...props}
      className={className}
      title={isEditMode && toEdit ? t('edit_discount') : t('new_discount')}
      mode={isEditMode && toEdit ? DialogType.EDIT : DialogType.CREATE}
      onClose={close}
      onCancelPressed={close}
      onConfirmPressed={onConfirmPressed}
      fullWidth
    >
      <Row>
        <StyledTextInput
          label={t('discount_start_date_label')}
          type='date'
          value={form.start_date}
          onChange={(e) => setForm({ ...form, start_date: e.target.value })}
        />
        <SpaceBox width={32} />
        <StyledTextInput
          label={t('discount_expire_date_label')}
          type='date'
          value={form.expire_date}
          onChange={(e) => setForm({ ...form, expire_date: e.target.value })}
        />
      </Row>
      <SpaceBox height={8} />
      <Error>{errors['start_date'] || errors['expire_date']}</Error>
      <SpaceBox height={24} />
      <StyledTextInput
        label={t('discount_italian_description_label')}
        type='text'
        value={form.italian_description}
        onChange={(e) =>
          setForm({ ...form, italian_description: e.target.value })
        }
      />
      <SpaceBox height={8} />
      <Error>{errors['italian_description']}</Error>
      <SpaceBox height={24} />
      <StyledTextInput
        label={t('discount_french_description_label')}
        type='text'
        value={form.french_description}
        onChange={(e) =>
          setForm({ ...form, french_description: e.target.value })
        }
      />
      <SpaceBox height={8} />
      <Error>{errors['french_description']}</Error>
      <SpaceBox height={24} />
      <Selector<DiscountType>
        options={['category', 'product', 'service', 'quantity']}
        labelExtractor={(type) => t(type)}
        onSelect={(type) => !isUndefined(type) && setForm({ ...form, type })}
        selected={form.type}
        mandatory={true}
      />
      <SpaceBox height={24} />
      {form.type === 'category' && (
        <>
          <Autocomplete
            options={map(categories, (category) => category.translation_id)}
            getOptionLabel={(id) =>
              find(categories, (category) => category.translation_id === id)
                ?.italian || ''
            }
            autoComplete
            value={form.category_translation_id}
            onChange={(_, category_translation_id) => {
              if (category_translation_id) {
                setForm({ ...form, category_translation_id })
              }
            }}
            renderInput={(params) => (
              <StyledTextInput
                {...params}
                label={t('discount_category_label')}
                fullWidth
              />
            )}
          />
          <SpaceBox height={8} />
          <Error>{errors['category']}</Error>
          <SpaceBox height={24} />
          <StyledTextInput
            label={t('discount_percentage_label')}
            type='number'
            value={form.discount}
            onChange={(e) =>
              setForm({ ...form, discount: Number(e.target.value) })
            }
          />
          <SpaceBox height={8} />
          <Error>{errors['discount']}</Error>
        </>
      )}
      {form.type === 'product' && (
        <>
          <Autocomplete
            options={map(products, (product) => product.product_id)}
            getOptionLabel={(id) =>
              find(products, (product) => product.product_id === id)
                ?.italian_name || ''
            }
            autoComplete
            value={form.product_id}
            onChange={(_, product_id) => {
              if (product_id) {
                setForm({ ...form, product_id })
              }
            }}
            renderInput={(params) => (
              <StyledTextInput
                {...params}
                label={t('discount_product_label')}
                fullWidth
              />
            )}
          />
          <SpaceBox height={8} />
          <Error>{errors['product']}</Error>
          <SpaceBox height={24} />
          <StyledTextInput
            label={t('discount_percentage_label')}
            type='number'
            value={form.discount}
            onChange={(e) =>
              setForm({ ...form, discount: Number(e.target.value) })
            }
          />
          <SpaceBox height={8} />
          <Error>{errors['discount']}</Error>
        </>
      )}
      {form.type === 'service' && (
        <>
          <Autocomplete
            options={map(products, (product) => product.product_id)}
            getOptionLabel={(id) =>
              find(products, (product) => product.product_id === id)
                ?.italian_name || ''
            }
            autoComplete
            value={form.product_id}
            onChange={(_, product_id) => {
              if (product_id) {
                setForm({ ...form, product_id })
              }
            }}
            renderInput={(params) => (
              <StyledTextInput
                {...params}
                label={t('discount_product_label')}
                fullWidth
              />
            )}
          />
          <SpaceBox height={8} />
          <Error>{errors['product']}</Error>
          <SpaceBox height={24} />
          <StyledInputLabel shrink>{t('pick_service')}</StyledInputLabel>
          <SpaceBox height={8} />
          <Row>
            <Chip
              label={t('product_wash_service')}
              color={washSelected ? 'primary' : 'default'}
              avatar={
                <Circle
                  backgroundColor={
                    washSelected
                      ? Colors.primaryColor_400
                      : Colors.textColor_200
                  }
                >
                  {washSelected ? (
                    <DoneIcon fontSize='small' />
                  ) : (
                    <WashSvg
                      height='14px'
                      width='14px'
                      color={Colors.textColor_500}
                    />
                  )}
                </Circle>
              }
              onClick={() => {
                if (form.service === 'wash') {
                  setForm({ ...form, service: undefined })
                } else if (form.service === 'iron') {
                  setForm({ ...form, service: 'full' })
                } else if (form.service === 'full') {
                  setForm({ ...form, service: 'iron' })
                } else {
                  setForm({ ...form, service: 'wash' })
                }
              }}
            />
            <SpaceBox width={8} />
            <Chip
              label={t('product_iron_service')}
              color={ironSelected ? 'primary' : 'default'}
              avatar={
                <Circle
                  backgroundColor={
                    ironSelected
                      ? Colors.primaryColor_400
                      : Colors.textColor_200
                  }
                >
                  {ironSelected ? (
                    <DoneIcon fontSize='small' />
                  ) : (
                    <IronSvg
                      height='14px'
                      width='14px'
                      color={Colors.textColor_500}
                    />
                  )}
                </Circle>
              }
              onClick={() => {
                if (form.service === 'wash') {
                  setForm({ ...form, service: 'full' })
                } else if (form.service === 'iron') {
                  setForm({ ...form, service: undefined })
                } else if (form.service === 'full') {
                  setForm({ ...form, service: 'wash' })
                } else {
                  setForm({ ...form, service: 'iron' })
                }
              }}
            />
          </Row>
          <SpaceBox height={8} />
          <Error>{errors['service']}</Error>
          <SpaceBox height={24} />
          <StyledTextInput
            label={t('discount_percentage_label')}
            type='number'
            value={form.discount}
            onChange={(e) =>
              setForm({ ...form, discount: Number(e.target.value) })
            }
          />
          <SpaceBox height={8} />
          <Error>{errors['discount']}</Error>
        </>
      )}
      {form.type === 'quantity' && (
        <>
          <Autocomplete
            options={map(products, (product) => product.product_id)}
            getOptionLabel={(id) =>
              find(products, (product) => product.product_id === id)
                ?.italian_name || ''
            }
            autoComplete
            value={form.product_id}
            onChange={(_, product_id) => {
              if (product_id) {
                setForm({ ...form, product_id })
              }
            }}
            renderInput={(params) => (
              <StyledTextInput
                {...params}
                label={t('discount_product_label')}
                fullWidth
              />
            )}
          />
          <SpaceBox height={8} />
          <Error>{errors['product']}</Error>
          <SpaceBox height={24} />
          <Row>
            <StyledTextInput
              label={t('discount_quantity_label')}
              type='number'
              value={form.quantity}
              onChange={(e) =>
                setForm({ ...form, quantity: Number(e.target.value) })
              }
            />
            <SpaceBox width={32} />
            <StyledTextInput
              label={t('discount_percentage_label')}
              type='number'
              value={form.discount}
              onChange={(e) =>
                setForm({ ...form, discount: Number(e.target.value) })
              }
            />
          </Row>
          <SpaceBox height={8} />
          <Error>{errors['quantity'] || errors['discount']}</Error>
        </>
      )}
    </Dialog>
  )
}

const StyledInputLabel = withStyles(() => ({
  root: {
    fontSize: 16,
    color: Colors.textColor_500,
    fontWeight: 500,
  },
}))(InputLabel)

const StyledTextInput = styled(TextInput)`
  width: 100%;
`

interface ICircleProps {
  backgroundColor: string
}

const Circle = styled.div<ICircleProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 12px;
  background-color: ${(props) => props.backgroundColor};
`

const Error = styled.p`
  font-size: 14px;
  margin: 0px;
  color: ${Colors.red_500};
`

export default DiscountDialog
