import { isNaN, isUndefined, map } from 'lodash'
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { get } from '../../Api/Api'
import { serverUrl } from '../../Api/api.config'
import { BasePage } from '../../components/Common/BasePage'
import { CenterContainer } from '../../components/Common/CenterContainer'
import { EmptySet } from '../../components/Common/EmptySet'
import { Loader } from '../../components/Common/Loader'
import { ClientInfo } from '../../components/Orders/ClientInfo'
import { CourierDescription } from '../../components/Orders/CourierDescription'
import { CourierDescriptionType } from '../../components/Orders/CourierDescription/CourierDescription'
import { CourierInfo } from '../../components/Orders/CourierInfo'
import { CurrentState } from '../../components/Orders/CurrentState'
import { HistoryInfo } from '../../components/Orders/HistoryInfo'
import { LaundryInfo } from '../../components/Orders/LaundryInfo'
import { OrderDetailHeader } from '../../components/Orders/OrderDetailHeader'
import { OrderedProductCard } from '../../components/Orders/OrderedProductCard'
import { TotalOrder } from '../../components/Orders/TotalOrder'
import {
  Order,
  OrderDetailAction,
  OrderDetailActionType,
  orderDetailInitialState,
  OrderDetailState,
} from '../../models/Order'
import { alertService } from '../../providers/AlertProvider'
import { AuthContext } from '../../providers/AuthProvider'
import { Colors } from '../../styles/colors'
import { Column } from '../../styles/commonStyledComponents'

export interface IOrderDetailNavigationParams {
  id?: string
}

const OrderDetail: FunctionComponent<Record<string, never>> = () => {
  const reducer = (
    state: OrderDetailState,
    action: OrderDetailAction
  ): OrderDetailState => {
    switch (action.type) {
      case OrderDetailActionType.ORDER_DETAIL_READ_REQUEST:
        return {
          ...state,
          readOrderDetail: {
            ...state.readOrderDetail,
            isLoading: true,
          },
        }
      case OrderDetailActionType.ORDER_DETAIL_READ_SUCCESS:
        return {
          ...state,
          readOrderDetail: {
            ...action.payload,
            isLoading: false,
          },
        }
      case OrderDetailActionType.ORDER_DETAIL_READ_FAILURE:
        return {
          ...state,
          readOrderDetail: orderDetailInitialState.readOrderDetail,
        }
    }
  }

  const [state, dispatch] = useReducer(reducer, orderDetailInitialState)

  const {
    readOrderDetail: { order },
  } = state

  const {
    login: { token },
  } = useContext(AuthContext)

  const readOrderDetail = useCallback(
    async (id: number) => {
      dispatch({ type: OrderDetailActionType.ORDER_DETAIL_READ_REQUEST })

      try {
        const orderDetail = await get<Order>(
          `${serverUrl}/admin/orders/${id}`,
          token
        )

        dispatch({
          type: OrderDetailActionType.ORDER_DETAIL_READ_SUCCESS,
          payload: {
            order: orderDetail,
          },
        })
        return orderDetail
      } catch (err) {
        alertService.showAlert(err, 'error')
        dispatch({ type: OrderDetailActionType.ORDER_DETAIL_READ_FAILURE })
        return undefined
      }
    },
    [token]
  )

  const { id } = useParams<IOrderDetailNavigationParams>()
  const { t } = useTranslation()

  const [isFirstLoad, setFirstLoad] = useState(true)

  // Reads order detail using id in navigation params
  useEffect(() => {
    const readOrderDetailAsync = async () => {
      if (!isUndefined(id) && !isNaN(id) && !!readOrderDetail) {
        await readOrderDetail(Number(id))
      }

      setFirstLoad(false)
    }

    void readOrderDetailAsync()
  }, [])

  const wrapWithBasePage = (body: JSX.Element) => {
    return (
      <BasePage header={<OrderDetailHeader />} pageKey='orderDetail'>
        {body}
      </BasePage>
    )
  }

  if (isFirstLoad) {
    return wrapWithBasePage(
      <CenterContainer>
        <Loader />
      </CenterContainer>
    )
  }

  if (!order) {
    return wrapWithBasePage(
      <EmptySet description={t('order_detail_empty_set')} />
    )
  }

  return wrapWithBasePage(
    <Container>
      <StyledColumn>
        <Label>{t('general_information')}</Label>
        <StyledCurrentState order={order} />
        <StyledClientInfo order={order} />
        {order.laundry && <StyledLaundryInfo order={order} />}
        <Label>{t('history')}</Label>
        <StyledHistoryInfo order={order} />
      </StyledColumn>
      <StyledColumn>
        <Label>{t('shipping_information')}</Label>
        {order.pickup_courier && (
          <>
            <StyledCourierDescription
              order={order}
              type={CourierDescriptionType.pickup}
            />
            <StyledCourierInfo courier={order.pickup_courier} />
          </>
        )}
        {order.delivery_courier && (
          <>
            <StyledCourierDescription
              order={order}
              type={CourierDescriptionType.delivery}
            />
            <StyledCourierInfo courier={order.delivery_courier} />
          </>
        )}
      </StyledColumn>
      <StyledColumn>
        <Label>{t('buyed_services')}</Label>
        <div>
          {map(order.detail, (item) => (
            <StyledOrderedProductCard
              key={`${item.product_id}_${item.service}`}
              order={order}
              orderedProduct={item}
            />
          ))}
        </div>
        <StyledTotalOrder order={order} />
      </StyledColumn>
    </Container>
  )
}

const StyledColumn = styled(Column)`
  align-items: center;
  flex-grow: 1;
  padding: 10px;
  border-right: 1px solid ${Colors.textColor_50};
  &:last-child {
    border-right: none;
  }
`

const StyledCurrentState = styled(CurrentState)`
  margin-bottom: 10px;
`

const StyledClientInfo = styled(ClientInfo)`
  margin-top: 10px;
  margin-bottom: 10px;
`

const StyledLaundryInfo = styled(LaundryInfo)`
  margin-top: 10px;
  margin-bottom: 10px;
`

const StyledHistoryInfo = styled(HistoryInfo)`
  margin-bottom: 10px;
`

const StyledCourierDescription = styled(CourierDescription)`
  margin-bottom: 16px;
`

const StyledCourierInfo = styled(CourierInfo)`
  margin-bottom: 32px;
`

const StyledOrderedProductCard = styled(OrderedProductCard)`
  margin-top: 10px;
  margin-bottom: 10px;
  &:first-child {
    margin-top: 0px;
  }
`

const StyledTotalOrder = styled(TotalOrder)`
  margin-top: 10px;
  margin-bottom: 10px;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
`

const Label = styled.div`
  font-size: 20px;
  line-height: 23px;
  color: ${Colors.textColor_500};
  margin-bottom: 20px;
  margin-top: 20px;
`

export default OrderDetail
