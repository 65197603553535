import React, { FunctionComponent } from 'react'
import { ClipLoader } from 'react-spinners'
import styled from 'styled-components'

import { Colors } from '../../../styles/colors'

const Loader: FunctionComponent<Record<string, never>> = () => {
  return (
    <Container>
      <ClipLoader color={Colors.primaryColor_500} loading={true} size={140} />
    </Container>
  )
}

const Container = styled.div`
  height: 200px;
  width: 200px;
`

export default Loader
