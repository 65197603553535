import React from 'react'
import styled from 'styled-components'

import { Colors } from '../../../styles/colors'

interface IBackgroundPattern {
  children?: JSX.Element
  className?: string
}

const BackgroundPattern = ({
  children,
  className,
}: IBackgroundPattern): JSX.Element => {
  return (
    <BackgroundColor className={className}>
      <Pattern />
      {children}
    </BackgroundColor>
  )
}

const BackgroundColor = styled.div`
  background-color: ${Colors.primaryColor_500};
`

const Pattern = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('/assets/png/background_pattern.png') repeat;
  background-size: 120px;
  opacity: 0.1;
  pointer-events: none;
`

export default BackgroundPattern
