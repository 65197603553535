export type Courier = {
  courier_id: number
  name: string
  phone: string
  email: string
}

export type CreateCourierForm = {
  name?: string
  phone?: string
  email?: string
}

export type EditCourierForm = {
  name?: string
  phone?: string
}

export type CouriersState = {
  readCouriers: {
    couriers?: Courier[]
    searchedText?: string
    isLoading: boolean
  }
  createCourier: {
    createdCourier?: Courier
    isCreating: boolean
  }
  editCourier: {
    editedCourier?: Courier
    isEditing: boolean
  }
  deleteCourier: {
    deletedId?: number
    isDeleting: boolean
  }
}

export const couriersInitialState: CouriersState = {
  readCouriers: {
    couriers: undefined,
    searchedText: undefined,
    isLoading: false,
  },
  createCourier: {
    createdCourier: undefined,
    isCreating: false,
  },
  editCourier: {
    editedCourier: undefined,
    isEditing: false,
  },
  deleteCourier: {
    deletedId: undefined,
    isDeleting: false,
  },
}

export enum CouriersActionType {
  COURIERS_READ_REQUEST,
  COURIERS_READ_SUCCESS,
  COURIERS_READ_FAILURE,
  COURIER_CREATE_REQUEST,
  COURIER_CREATE_SUCCESS,
  COURIER_CREATE_FAILURE,
  COURIER_EDIT_REQUEST,
  COURIER_EDIT_SUCCESS,
  COURIER_EDIT_FAILURE,
  COURIER_DELETE_REQUEST,
  COURIER_DELETE_SUCCESS,
  COURIER_DELETE_FAILURE,
}

export type CouriersAction =
  | { type: CouriersActionType.COURIERS_READ_REQUEST }
  | {
      type: CouriersActionType.COURIERS_READ_SUCCESS
      payload: {
        couriers?: Courier[]
        searchedText?: string
      }
    }
  | { type: CouriersActionType.COURIERS_READ_FAILURE }
  | { type: CouriersActionType.COURIER_CREATE_REQUEST }
  | {
      type: CouriersActionType.COURIER_CREATE_SUCCESS
      payload: { createdCourier?: Courier }
    }
  | { type: CouriersActionType.COURIER_CREATE_FAILURE }
  | { type: CouriersActionType.COURIER_EDIT_REQUEST }
  | {
      type: CouriersActionType.COURIER_EDIT_SUCCESS
      payload: { editedCourier?: Courier }
    }
  | { type: CouriersActionType.COURIER_EDIT_FAILURE }
  | { type: CouriersActionType.COURIER_DELETE_REQUEST }
  | {
      type: CouriersActionType.COURIER_DELETE_SUCCESS
      payload: { deletedId?: number }
    }
  | { type: CouriersActionType.COURIER_DELETE_FAILURE }
