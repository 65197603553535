import { Service } from './Service'

export type DiscountType = 'category' | 'product' | 'service' | 'quantity'

type BaseDiscount = {
  discount_id: number
  discount: number
  start_date: string
  expire_date: string
  description: string | null
  type: string
  category?: string
  product_id?: number
  service?: Service
  quantity?: number
  italian_description?: string | null
  french_description?: string | null
  category_translation_id?: number
  product_name?: string
}

export type CategoryDiscount = BaseDiscount & {
  type: 'category'
  category: string
}

export type ProductDiscount = BaseDiscount & {
  type: 'product'
  product_id: number
}

export type ServiceDiscount = BaseDiscount & {
  type: 'service'
  service: Service
}

export type QuantityDiscount = BaseDiscount & {
  type: 'quantity'
  quantity: number
  category: string
}

export type Discount =
  | CategoryDiscount
  | ProductDiscount
  | ServiceDiscount
  | QuantityDiscount

export type DiscountsGrouped = {
  category?: CategoryDiscount[] | null
  product?: ProductDiscount[] | null
  service?: ServiceDiscount[] | null
  quantity?: QuantityDiscount[] | null
}

export type CreateDiscountForm = {
  type: DiscountType
  discount: number
  start_date: string
  expire_date: string
  italian_description?: string | null
  french_description?: string | null
  category_translation_id?: number
  product_id?: number
  service?: Service
  quantity?: number
}

export type EditDiscountForm = Partial<CreateDiscountForm>

export type DiscountsState = {
  readDiscounts: {
    discounts?: DiscountsGrouped
    isLoading: boolean
  }
  createDiscount: {
    createdDiscount?: Discount
    isCreating: boolean
  }
  editDiscount: {
    editedDiscount?: Discount
    isEditing: boolean
  }
  deleteDiscount: {
    deletedId?: number
    isDeleting: boolean
  }
}

export const discountsInitialState: DiscountsState = {
  readDiscounts: {
    discounts: undefined,
    isLoading: false,
  },
  createDiscount: {
    createdDiscount: undefined,
    isCreating: false,
  },
  editDiscount: {
    editedDiscount: undefined,
    isEditing: false,
  },
  deleteDiscount: {
    deletedId: undefined,
    isDeleting: false,
  },
}

export enum DiscountsActionType {
  DISCOUNTS_READ_REQUEST,
  DISCOUNTS_READ_SUCCESS,
  DISCOUNTS_READ_FAILURE,
  DISCOUNT_CREATE_REQUEST,
  DISCOUNT_CREATE_SUCCESS,
  DISCOUNT_CREATE_FAILURE,
  DISCOUNT_EDIT_REQUEST,
  DISCOUNT_EDIT_SUCCESS,
  DISCOUNT_EDIT_FAILURE,
  DISCOUNT_DELETE_REQUEST,
  DISCOUNT_DELETE_SUCCESS,
  DISCOUNT_DELETE_FAILURE,
}

export type DiscountsAction =
  | { type: DiscountsActionType.DISCOUNTS_READ_REQUEST }
  | {
      type: DiscountsActionType.DISCOUNTS_READ_SUCCESS
      payload: { discounts?: DiscountsGrouped }
    }
  | { type: DiscountsActionType.DISCOUNTS_READ_FAILURE }
  | { type: DiscountsActionType.DISCOUNT_CREATE_REQUEST }
  | {
      type: DiscountsActionType.DISCOUNT_CREATE_SUCCESS
      payload: { createdDiscount?: Discount }
    }
  | { type: DiscountsActionType.DISCOUNT_CREATE_FAILURE }
  | { type: DiscountsActionType.DISCOUNT_EDIT_REQUEST }
  | {
      type: DiscountsActionType.DISCOUNT_EDIT_SUCCESS
      payload: { editedDiscount?: Discount }
    }
  | { type: DiscountsActionType.DISCOUNT_EDIT_FAILURE }
  | { type: DiscountsActionType.DISCOUNT_DELETE_REQUEST }
  | {
      type: DiscountsActionType.DISCOUNT_DELETE_SUCCESS
      payload: { deletedId?: number }
    }
  | { type: DiscountsActionType.DISCOUNT_DELETE_FAILURE }
