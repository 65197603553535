import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Laundry } from '../../../models/Laundry'
import { Colors } from '../../../styles/colors'
import {
  Column,
  SpaceBox,
  SpacedRow,
} from '../../../styles/commonStyledComponents'
import { BasicMenu } from '../../Common/BasicMenu'

interface LaundryCardProps {
  laundry: Laundry
  className?: string
  onDelete: () => void
  onEdit: () => void
}

const LaundryCard = (props: LaundryCardProps): JSX.Element => {
  const { className, laundry, onDelete, onEdit } = props
  const { address, email, name, phone, vat_code } = laundry
  const { t } = useTranslation()
  return (
    <Container className={className}>
      <Section>
        <Column>
          <Name>{name}</Name>
        </Column>
        <Column>
          <BasicMenu onDelete={onDelete} onEdit={onEdit} />
        </Column>
      </Section>
      {vat_code && (
        <>
          <Divider />
          <Section>
            <Column>
              <SectionHeader>{t('vat_code')}</SectionHeader>
              <Value>{vat_code}</Value>
            </Column>
          </Section>
        </>
      )}
      <Divider />
      <Section>
        <Column>
          <SectionHeader>{t('contact')}</SectionHeader>
          <Info>
            <Link href={`mailto:${email}`}>{email}</Link>
          </Info>
          <SpaceBox height={4} />
          <Info>
            <Link href={`tel:${phone}`}>{phone}</Link>
          </Info>
        </Column>
      </Section>
      <Divider />
      <Section>
        <Column>
          <SectionHeader>{t('address')}</SectionHeader>
          <Value>{address}</Value>
        </Column>
      </Section>
    </Container>
  )
}

const Container = styled.div`
  width: 410px;
  background: ${Colors.white};
  border: 1px solid ${Colors.textColor_50};
  box-sizing: border-box;
  box-shadow: 0px 8px 8px ${Colors.textColor_50};
  border-radius: 6px;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const Section = styled(SpacedRow)`
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
`

const SectionHeader = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${Colors.textColor_400};
  margin-bottom: 5px;
`

const Divider = styled.div`
  align-self: stretch;
  height: 1px;
  background-color: ${Colors.textColor_50};
`

const Info = styled.span`
  display: flex;
`

const Value = styled.span`
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.textColor_500};
`

const Link = styled.a`
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.primaryColor_500};
`

const Name = styled.div`
  text-transform: capitalize;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 18px;
  line-height: 21px;
  color: ${Colors.textColor_500};
`

export default LaundryCard
