import { DialogProps, InputLabel, Switch, withStyles } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import {
  capitalize,
  find,
  isEmpty,
  isNull,
  isString,
  isUndefined,
  omit,
} from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Category } from '../../../models/Category'
import { Language, supportedLanguages } from '../../../models/Languages'
import { Photo } from '../../../models/Photo'
import {
  CreateProductForm,
  EditProductForm,
  Gender,
  Product,
  ProductGetList,
} from '../../../models/Product'
import { Service } from '../../../models/Service'
import { CategoriesContext } from '../../../providers/CategoriesProvider'
import { Colors } from '../../../styles/colors'
import {
  Column,
  Row,
  SpaceBox,
  SpacedRow,
} from '../../../styles/commonStyledComponents'
import { Dialog, DialogType } from '../../Common/Dialog'
import { Selector } from '../../Common/Selector'
import { TextInput } from '../../Common/TextInput'
import { PhotoPickerField } from '../PhotoPickerField'
import { PhotosDialog } from '../PhotosDialog'

interface ProductDialogProps extends DialogProps {
  close: () => void
  isEditMode?: boolean
  toEdit?: Product
  className?: string
  editProduct: (
    id: number,
    edit: EditProductForm
  ) => Promise<Product | undefined>
  createProduct: (create: CreateProductForm) => Promise<Product | undefined>
  readProducts: (
    search?: string | undefined,
    service?: Service | undefined,
    page?: number
  ) => Promise<ProductGetList | undefined>
}

const ProductDialog = ({
  className,
  close,
  createProduct,
  editProduct,
  isEditMode,
  readProducts,
  toEdit,
  ...props
}: ProductDialogProps): JSX.Element => {
  const { t } = useTranslation()

  const {
    readCategories: { categories, readCategories },
  } = useContext(CategoriesContext)

  const [language, setLanguage] = useState<Language>('italian')
  const [form, setForm] = useState<CreateProductForm>({
    italian_name: isEditMode && toEdit ? toEdit.italian_name : '',
    french_name: isEditMode && toEdit ? toEdit.french_name : '',
    gender: isEditMode && toEdit ? toEdit.gender || null : null,
    italian_category: isEditMode && toEdit ? toEdit.italian_category : '',
    french_category: isEditMode && toEdit ? toEdit.french_category : '',
    category_translation_id:
      isEditMode && toEdit ? toEdit.category_translation_id : undefined,
    washing_price:
      isEditMode && toEdit ? toEdit.washing_price || undefined : undefined,
    ironing_price:
      isEditMode && toEdit ? toEdit.ironing_price || undefined : undefined,
    full_price:
      isEditMode && toEdit ? toEdit.full_price || undefined : undefined,
  })
  const [washEnabled, setWashEnabled] = useState(
    isEditMode && toEdit ? toEdit.washing_price !== null : false
  )
  const [ironEnabled, setIronEnabled] = useState(
    isEditMode && toEdit ? toEdit.ironing_price !== null : false
  )
  const [photosDialogOpen, setPhotosDialogOpen] = useState(false)
  const [pickedPhoto, setPickedPhoto] = useState<Photo | string>()
  const [errors, setErrors] = useState<{ [key: string]: string }>({})

  const fullEnabled = washEnabled && ironEnabled

  // When the dialog is created, read the possible categories
  useEffect(() => {
    if (readCategories) {
      void readCategories()
    }
  }, [])

  // When wash is disabled, remove washing_price (and maybe full_price too)
  useEffect(() => {
    if (!washEnabled) {
      setForm((prev) =>
        omit(
          prev,
          !ironEnabled ? ['washing_price', 'full_price'] : ['washing_price']
        )
      )
    }
  }, [washEnabled])

  // When iron is disabled, remove ironing_price (and maybe full_price too)
  useEffect(() => {
    if (!ironEnabled) {
      setForm((prev) =>
        omit(
          prev,
          !washEnabled ? ['ironing_price', 'full_price'] : ['ironing_price']
        )
      )
    }
  }, [ironEnabled])

  // When the dialog is opened/closed, reset the state
  useEffect(() => {
    if (props.open) {
      setErrors({})
      setForm({
        italian_name: isEditMode && toEdit ? toEdit.italian_name : '',
        french_name: isEditMode && toEdit ? toEdit.french_name : '',
        gender: isEditMode && toEdit ? toEdit.gender || null : null,
        italian_category: isEditMode && toEdit ? toEdit.italian_category : '',
        french_category: isEditMode && toEdit ? toEdit.french_category : '',
        category_translation_id:
          isEditMode && toEdit ? toEdit.category_translation_id : undefined,
        image: undefined,
        photo_id: undefined,
        washing_price:
          isEditMode && toEdit ? toEdit.washing_price || undefined : undefined,
        ironing_price:
          isEditMode && toEdit ? toEdit.ironing_price || undefined : undefined,
        full_price:
          isEditMode && toEdit ? toEdit.full_price || undefined : undefined,
      })
      setWashEnabled(
        isEditMode && toEdit ? toEdit.washing_price !== null : false
      )
      setIronEnabled(
        isEditMode && toEdit ? toEdit.ironing_price !== null : false
      )
      setPickedPhoto(undefined)
    }
  }, [props.open, isEditMode, toEdit])

  // It sucks, I know, but I didn't want to make it better, just collapse the function body and don't look inside ;)
  const validate = () => {
    let hasErrors = false

    // Italian name
    if (isEmpty(form.italian_name)) {
      setErrors((prev) => ({ ...prev, italian_name: t('italian_name_empty') }))
      hasErrors = true
    } else {
      setErrors((prev) => omit(prev, ['italian_name']))
    }

    // French name
    if (isEmpty(form.french_name)) {
      setErrors((prev) => ({ ...prev, french_name: t('french_name_empty') }))
      hasErrors = true
    } else {
      setErrors((prev) => omit(prev, ['french_name']))
    }

    // Italian category
    if (isEmpty(form.italian_category)) {
      setErrors((prev) => ({
        ...prev,
        italian_category: t('italian_category_empty'),
      }))
      hasErrors = true
    } else {
      setErrors((prev) => omit(prev, ['italian_category']))
    }

    // French name
    if (isEmpty(form.french_category)) {
      setErrors((prev) => ({
        ...prev,
        french_category: t('french_category_empty'),
      }))
      hasErrors = true
    } else {
      setErrors((prev) => omit(prev, ['french_category']))
    }

    // Prices
    if (
      isUndefined(form.washing_price) &&
      isUndefined(form.ironing_price) &&
      isUndefined(form.full_price)
    ) {
      setErrors((prev) => ({
        ...prev,
        prices: t('prices_empty'),
      }))
      hasErrors = true
    } else if (washEnabled && isUndefined(form.washing_price)) {
      setErrors((prev) => ({
        ...prev,
        prices: t('washing_price_empty'),
      }))
      hasErrors = true
    } else if (ironEnabled && isUndefined(form.ironing_price)) {
      setErrors((prev) => ({
        ...prev,
        prices: t('ironing_price_empty'),
      }))
      hasErrors = true
    } else if (washEnabled && ironEnabled && isUndefined(form.full_price)) {
      setErrors((prev) => ({
        ...prev,
        prices: t('full_price_empty'),
      }))
      hasErrors = true
    } else {
      setErrors((prev) => omit(prev, ['prices']))
    }

    return !hasErrors
  }

  const onConfirmPressed = async () => {
    if (!validate()) {
      return
    }

    if (isEditMode && toEdit) {
      if (editProduct) {
        const edited = await editProduct(toEdit.product_id, form)
        if (edited) {
          close()
        }
      }
    } else {
      if (createProduct) {
        const created = await createProduct(form)
        if (created) {
          close()
        }
      }
    }

    if (readProducts) {
      void readProducts()
    }
  }

  const isItalian = language === 'italian'

  return (
    <Dialog
      {...props}
      className={className}
      title={isEditMode && toEdit ? t('edit_product') : t('new_product')}
      mode={isEditMode && toEdit ? DialogType.EDIT : DialogType.CREATE}
      onClose={close}
      onCancelPressed={close}
      onConfirmPressed={onConfirmPressed}
    >
      <Selector<Language>
        options={supportedLanguages}
        labelExtractor={(lang) => t(lang)}
        onSelect={(lang) => !isUndefined(lang) && setLanguage(lang)}
        selected={language}
        mandatory={true}
      />
      <SpaceBox height={16} />
      <StyledTextInput
        label={t('name_label')}
        type='text'
        value={isItalian ? form.italian_name : form.french_name}
        onChange={(e) =>
          setForm(
            isItalian
              ? { ...form, italian_name: e.target.value }
              : { ...form, french_name: e.target.value }
          )
        }
      />
      <SpaceBox height={8} />
      <Error>{errors[isItalian ? 'italian_name' : 'french_name']}</Error>
      <SpaceBox height={16} />
      <Autocomplete<Category, false, false, true>
        freeSolo
        options={categories || []}
        getOptionLabel={(category) =>
          capitalize(isItalian ? category.italian : category.french)
        }
        onChange={(_, value) => {
          // When a new value is picked in autocomplete, set the values in form
          if (isNull(value)) {
            setForm({
              ...form,
              italian_category: '',
              french_category: '',
              category_translation_id: undefined,
            })
          } else {
            setForm({
              ...form,
              italian_category: (value as Category).italian,
              french_category: (value as Category).french,
              category_translation_id: (value as Category).translation_id,
            })
          }
        }}
        inputValue={capitalize(
          isItalian ? form.italian_category : form.french_category
        )}
        onInputChange={(e, value) => {
          if (isNull(e)) {
            return
          }

          // When a new value is typed in the input
          // -> if it matches a category name, set that category to selected, with all its props
          // -> otherwise just fill the translation of the category in the form
          const selectedCategory = find(
            categories,
            (category) =>
              value === (isItalian ? category.italian : category.french)
          )

          if (!isUndefined(selectedCategory)) {
            setForm({
              ...form,
              italian_category: selectedCategory.italian,
              french_category: selectedCategory.french,
              category_translation_id: selectedCategory.translation_id,
            })
          } else {
            if (isItalian) {
              setForm({
                ...form,
                italian_category: value,
                category_translation_id: undefined,
              })
            } else {
              setForm({
                ...form,
                french_category: value,
                category_translation_id: undefined,
              })
            }
          }
        }}
        renderInput={(params) => (
          <StyledTextInput
            {...params}
            label={t('category_label')}
            type='text'
          />
        )}
      />
      <SpaceBox height={8} />
      <Error>
        {errors[isItalian ? 'italian_category' : 'french_category']}
      </Error>
      <SpaceBox height={24} />
      <Selector<Gender | null>
        options={['M', 'F', null]}
        labelExtractor={(gender) => {
          switch (gender) {
            case 'M':
              return t('man_gender')
            case 'F':
              return t('woman_gender')
            default:
              return t('other_gender')
          }
        }}
        onSelect={(gender) =>
          !isUndefined(gender) && setForm({ ...form, gender })
        }
        selected={form.gender}
        mandatory={true}
      />
      <SpaceBox height={24} />
      <StyledInputLabel shrink>{t('image_label')}</StyledInputLabel>
      <SpaceBox height={2} />
      <ImageDescription>{t('image_picker_description')}</ImageDescription>
      <SpaceBox height={8} />
      <PhotoPickerField
        onClick={() => setPhotosDialogOpen(true)}
        picked={pickedPhoto}
      />
      <SpaceBox height={16} />
      <SpacedRow>
        <Column>
          <StyledInputLabel shrink>
            {t('product_wash_service')}
          </StyledInputLabel>
          <SpaceBox height={2} />
          <Row>
            <Label>{t('available')}</Label>
            <SpaceBox width={16} />
            <Switch
              checked={washEnabled}
              onChange={(e) => setWashEnabled(e.target.checked)}
              color='primary'
            />
          </Row>
        </Column>
        {washEnabled && (
          <TextInput
            label={t('price')}
            type='number'
            value={form.washing_price}
            onChange={(e) =>
              !isNaN(Number(e.target.value)) &&
              setForm({ ...form, washing_price: Number(e.target.value) })
            }
          />
        )}
      </SpacedRow>
      <SpaceBox height={24} />
      <SpacedRow>
        <Column>
          <StyledInputLabel shrink>
            {t('product_iron_service')}
          </StyledInputLabel>
          <SpaceBox height={2} />
          <Row>
            <Label>{t('available')}</Label>
            <SpaceBox width={16} />
            <Switch
              checked={ironEnabled}
              onChange={(e) => setIronEnabled(e.target.checked)}
              color='primary'
            />
          </Row>
        </Column>
        {ironEnabled && (
          <TextInput
            label={t('price')}
            type='number'
            value={form.ironing_price}
            onChange={(e) =>
              !isNaN(Number(e.target.value)) &&
              setForm({ ...form, ironing_price: Number(e.target.value) })
            }
          />
        )}
      </SpacedRow>
      {fullEnabled && (
        <>
          <SpaceBox height={24} />
          <SpacedRow>
            <StyledInputLabel shrink>
              {t('product_full_service')}
            </StyledInputLabel>
            <TextInput
              label={t('price')}
              type='number'
              value={form.full_price}
              onChange={(e) =>
                !isNaN(Number(e.target.value)) &&
                setForm({ ...form, full_price: Number(e.target.value) })
              }
            />
          </SpacedRow>
        </>
      )}
      <SpaceBox height={8} />
      <Error>{errors['prices']}</Error>
      <PhotosDialog
        onConfirm={(image) => {
          setPickedPhoto(image)

          if (isString(image)) {
            setForm({
              ...form,
              photo_id: undefined,
              image,
            })
          } else {
            setForm({
              ...form,
              image: undefined,
              photo_id: image.photo_id,
            })
          }
        }}
        open={photosDialogOpen}
        close={() => setPhotosDialogOpen(false)}
      />
    </Dialog>
  )
}

const StyledInputLabel = withStyles(() => ({
  root: {
    fontSize: 16,
    color: Colors.textColor_500,
    fontWeight: 500,
  },
}))(InputLabel)

const StyledTextInput = styled(TextInput)`
  width: 100%;
`

const ImageDescription = styled.p`
  font-size: 14px;
  margin: 0px;
  color: ${Colors.textColor_400};
`

const Error = styled.p`
  font-size: 14px;
  margin: 0px;
  color: ${Colors.red_500};
`

const Label = styled.p`
  font-size: 14px;
  color: ${Colors.textColor_500};
`

export default ProductDialog
