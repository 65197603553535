import { Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Courier } from '../../../models/Courier'
import {
  Row,
  SpaceBox,
  SpacedRow,
} from '../../../styles/commonStyledComponents'
import { Button, ButtonType } from '../../Common/Button'
import { SearchBar } from '../../Common/SearchBar'

export interface ICouriersHeaderProps {
  onNewCourierPressed: () => void
  readCouriers: (search?: string | undefined) => Promise<Courier[] | undefined>
}

const CouriersHeader: FunctionComponent<ICouriersHeaderProps> = (
  props: ICouriersHeaderProps
) => {
  const { onNewCourierPressed, readCouriers } = props

  const { t } = useTranslation()

  const search = (search: string) => {
    if (readCouriers) {
      void readCouriers(search)
    }
  }

  return (
    <StyledSpacedRow>
      <Row>
        <Typography variant='h4'>{t('couriers')}</Typography>
        <SpaceBox width={32} />
        <SearchBar
          hint={t('search_courier_by_name')}
          onChange={(e) => search(e.target.value)}
        />
      </Row>
      <Button
        label={t('new_courier')}
        mode={ButtonType.SUCCESS_MAIN}
        startIcon={<AddIcon />}
        onClick={onNewCourierPressed}
      />
    </StyledSpacedRow>
  )
}

const StyledSpacedRow = styled(SpacedRow)`
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
`

export default CouriersHeader
