import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { get as getItem, map } from 'lodash'
import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { deleteApi, get, post, put } from '../../Api/Api'
import { serverUrl } from '../../Api/api.config'
import { BasePage } from '../../components/Common/BasePage'
import { CenterContainer } from '../../components/Common/CenterContainer'
import { Dialog, DialogType } from '../../components/Common/Dialog'
import { Loader } from '../../components/Common/Loader'
import { CourierCard } from '../../components/Courier/CourierCard'
import { ScheduleDialog } from '../../components/Schedules/ScheduleDialog'
import { SchedulesHeader } from '../../components/Schedules/SchedulesHeader'
import {
  CreateScheduleForm,
  EditScheduleForm,
  Schedule,
  SchedulesAction,
  SchedulesActionType,
  SchedulesGrouped,
  schedulesInitialState,
  SchedulesState,
  Weekday,
} from '../../models/Schedule'
import { alertService } from '../../providers/AlertProvider'
import { AuthContext } from '../../providers/AuthProvider'
import { Colors } from '../../styles/colors'
import { Column, Row } from '../../styles/commonStyledComponents'

const Schedules: FunctionComponent<Record<string, never>> = () => {
  const reducer = (
    state: SchedulesState,
    action: SchedulesAction
  ): SchedulesState => {
    switch (action.type) {
      case SchedulesActionType.SCHEDULES_READ_REQUEST:
        return {
          ...state,
          readSchedules: {
            ...state.readSchedules,
            isLoading: true,
          },
        }
      case SchedulesActionType.SCHEDULES_READ_SUCCESS:
        return {
          ...state,
          readSchedules: {
            ...action.payload,
            isLoading: false,
          },
        }
      case SchedulesActionType.SCHEDULES_READ_FAILURE:
        return {
          ...state,
          readSchedules: schedulesInitialState.readSchedules,
        }
      case SchedulesActionType.SCHEDULE_CREATE_REQUEST:
        return {
          ...state,
          createSchedule: {
            ...state.createSchedule,
            isCreating: true,
          },
        }
      case SchedulesActionType.SCHEDULE_CREATE_SUCCESS:
        return {
          ...state,
          createSchedule: {
            ...action.payload,
            isCreating: false,
          },
        }
      case SchedulesActionType.SCHEDULE_CREATE_FAILURE:
        return {
          ...state,
          createSchedule: schedulesInitialState.createSchedule,
        }
      case SchedulesActionType.SCHEDULE_EDIT_REQUEST:
        return {
          ...state,
          editSchedule: {
            ...state.editSchedule,
            isEditing: true,
          },
        }
      case SchedulesActionType.SCHEDULE_EDIT_SUCCESS:
        return {
          ...state,
          editSchedule: {
            ...action.payload,
            isEditing: false,
          },
        }
      case SchedulesActionType.SCHEDULE_EDIT_FAILURE:
        return {
          ...state,
          editSchedule: schedulesInitialState.editSchedule,
        }
      case SchedulesActionType.SCHEDULE_DELETE_REQUEST:
        return {
          ...state,
          deleteSchedule: {
            ...state.deleteSchedule,
            isDeleting: true,
          },
        }
      case SchedulesActionType.SCHEDULE_DELETE_SUCCESS:
        return {
          ...state,
          deleteSchedule: {
            ...action.payload,
            isDeleting: false,
          },
        }
      case SchedulesActionType.SCHEDULE_DELETE_FAILURE:
        return {
          ...state,
          deleteSchedule: schedulesInitialState.deleteSchedule,
        }
    }
  }

  const [state, dispatch] = useReducer(reducer, schedulesInitialState)

  const {
    readSchedules: { schedules },
  } = state

  const [dialogOpen, setDialogOpen] = useState(false)
  const [isEditMode, setEditMode] = useState(false)
  const [toEdit, setToEdit] = useState<Schedule>()
  const [isFirstLoad, setFirstLoad] = useState(true)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [toDelete, setToDelete] = useState<Schedule>()

  const { t } = useTranslation()

  const {
    login: { token },
  } = useContext(AuthContext)

  const readSchedules = useCallback(async () => {
    dispatch({ type: SchedulesActionType.SCHEDULES_READ_REQUEST })

    try {
      const schedules = await get<SchedulesGrouped>(
        `${serverUrl}/schedules`,
        token
      )

      dispatch({
        type: SchedulesActionType.SCHEDULES_READ_SUCCESS,
        payload: { schedules },
      })
      return schedules
    } catch (err) {
      alertService.showAlert(err, 'error')
      dispatch({ type: SchedulesActionType.SCHEDULES_READ_FAILURE })
      return undefined
    }
  }, [token])

  const createSchedule = useCallback(
    async (create: CreateScheduleForm) => {
      dispatch({ type: SchedulesActionType.SCHEDULE_CREATE_REQUEST })

      try {
        const createdSchedule = await post<Schedule>(
          `${serverUrl}/schedules`,
          create,
          token
        )

        dispatch({
          type: SchedulesActionType.SCHEDULE_CREATE_SUCCESS,
          payload: { createdSchedule },
        })
        return createdSchedule
      } catch (err) {
        alertService.showAlert(err, 'error')
        dispatch({ type: SchedulesActionType.SCHEDULE_CREATE_FAILURE })
        return undefined
      }
    },
    [token]
  )

  const editSchedule = useCallback(
    async (id: number, edit: EditScheduleForm) => {
      dispatch({ type: SchedulesActionType.SCHEDULE_EDIT_REQUEST })

      try {
        const editedSchedule = await put<Schedule>(
          `${serverUrl}/schedules/${id}`,
          edit,
          token
        )

        dispatch({
          type: SchedulesActionType.SCHEDULE_EDIT_SUCCESS,
          payload: { editedSchedule },
        })
        return editedSchedule
      } catch (err) {
        alertService.showAlert(err, 'error')
        dispatch({ type: SchedulesActionType.SCHEDULE_EDIT_FAILURE })
        return undefined
      }
    },
    [token]
  )

  const deleteSchedule = useCallback(
    async (id: number) => {
      dispatch({ type: SchedulesActionType.SCHEDULE_DELETE_REQUEST })

      try {
        await deleteApi(`${serverUrl}/schedules/${id}`, token)

        dispatch({
          type: SchedulesActionType.SCHEDULE_DELETE_SUCCESS,
          payload: { deletedId: id },
        })
      } catch (err) {
        alertService.showAlert(err, 'error')
        dispatch({ type: SchedulesActionType.SCHEDULE_DELETE_FAILURE })
      }
    },
    [token]
  )

  useEffect(() => {
    const readSchedulesAsync = async () => {
      if (readSchedules) {
        await readSchedules()
      }

      setFirstLoad(false)
    }

    void readSchedulesAsync()
  }, [state.deleteSchedule.deletedId])

  const onNewSchedulePressed = () => {
    setEditMode(false)
    setToEdit(undefined)
    setDialogOpen(true)
  }

  const handleClose = () => setDialogOpen(false)

  const onEdit = (schedule: Schedule) => {
    setEditMode(true)
    setToEdit(schedule)
    setDialogOpen(true)
  }

  const onDelete = (schedule: Schedule) => {
    setToDelete(schedule)
    setOpenDeleteModal(true)
  }

  const deleteScheduleAsync = async () => {
    if (deleteSchedule && toDelete) {
      await deleteSchedule(toDelete.schedule_id)
      setOpenDeleteModal(false)
    }
  }

  const wrapWithBasePage = (body: JSX.Element) => {
    return (
      <BasePage
        header={<SchedulesHeader onNewSchedulePressed={onNewSchedulePressed} />}
        pageKey='schedules'
      >
        {body}
        <ScheduleDialog
          open={dialogOpen}
          close={handleClose}
          isEditMode={isEditMode}
          toEdit={toEdit}
          readSchedules={readSchedules}
          createSchedule={createSchedule}
          editSchedule={editSchedule}
        />
        <Dialog
          title={`${t('delete')} ${t('schedule').toLocaleLowerCase()}`}
          mode={DialogType.DELETE}
          open={openDeleteModal}
          onCancelPressed={() => setOpenDeleteModal(false)}
          onConfirmPressed={deleteScheduleAsync}
          onClose={() => setOpenDeleteModal(false)}
        >
          <ModalMessage>
            {t('delete_schedule_text_modal')}
            <br />
            <br />
            <DangerText>{t('important')}</DangerText>:{' '}
            {t('schedule_delete_warning')}
          </ModalMessage>
        </Dialog>
      </BasePage>
    )
  }

  if (isFirstLoad) {
    return wrapWithBasePage(
      <CenterContainer>
        <Loader />
      </CenterContainer>
    )
  }

  const weekdays: Weekday[] = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ]

  const type = ['delivery_day', 'pickup_day']

  return wrapWithBasePage(
    <Container>
      {map(weekdays, (day: string) => {
        return (
          <StyledColumn key={day}>
            <SectionHeader>{t(day)}</SectionHeader>
            {map(type, (scheduleType: string) => (
              <div>
                {getItem(getItem(schedules, day), scheduleType) && (
                  <div>
                    <Label>
                      <ArrowDownwardIcon /> {t(scheduleType)}
                    </Label>
                    <div>
                      {map(
                        getItem(getItem(schedules, day), scheduleType),
                        (item: Schedule) => (
                          <StyledCourierCard
                            key={item.schedule_id}
                            courier={item}
                            onDelete={() => onDelete(item)}
                            onEdit={() => onEdit(item)}
                          />
                        )
                      )}
                    </div>
                  </div>
                )}
              </div>
            ))}
          </StyledColumn>
        )
      })}
    </Container>
  )
}

const Container = styled(Row)`
  align-items: initial;
`

const StyledColumn = styled(Column)`
  align-items: center;
  flex-grow: 1;
  padding: 10px;
  border-right: 1px solid ${Colors.textColor_50};
  &:last-child {
    border-right: none;
  }
  min-width: 200px;
`

const SectionHeader = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  background-color: ${Colors.textColor_50};
  font-size: 20px;
  line-height: 23px;
  color: ${Colors.textColor_500};
  align-items: center;
  justify-content: center;
  border-radius: 6px;
`

const StyledCourierCard = styled(CourierCard)`
  width: 200px;
  margin-bottom: 20px;
`

const Label = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  background: ${Colors.primaryColor_50};
  border-radius: 4px;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${Colors.primaryColor_500};
  text-transform: uppercase;
  font-weight: 500;
`

const ModalMessage = styled.div`
  white-space: pre-line;
`

const DangerText = styled.span`
  color: ${Colors.red_500};
  font-weight: bold;
  text-transform: uppercase;
`

export default Schedules
