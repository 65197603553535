import React, { FunctionComponent } from 'react'
import styled from 'styled-components'

export interface ICenterContainerProps {
  alignItemsCenter?: boolean
}

const CenterContainer: FunctionComponent<ICenterContainerProps> = ({
  alignItemsCenter = false,
  children,
}) => {
  if (alignItemsCenter) {
    return <ContainerAlignCenter>{children}</ContainerAlignCenter>
  } else {
    return <Container>{children}</Container>
  }
}

export const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const ContainerAlignCenter = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  text-align: center;
`

export default CenterContainer
