import { Colors } from '../styles/colors'
import { Courier } from './Courier'
import { LaundryShort } from './Laundry'
import { Gender } from './Product'
import { Service } from './Service'

export type OrderState =
  | 'to_pay'
  | 'to_pickup'
  | 'picking_up'
  | 'washing'
  | 'to_deliver'
  | 'delivering'
  | 'completed'

export const stateColors = {
  to_pay: Colors.red_500,
  to_pickup: Colors.green_500,
  picking_up: Colors.green_500,
  washing: Colors.green_500,
  to_deliver: Colors.green_500,
  delivering: Colors.green_500,
  completed: Colors.primaryColor_500,
}

export const stateShadowColors = {
  to_pay: Colors.red_50,
  to_pickup: Colors.green_50,
  picking_up: Colors.green_50,
  washing: Colors.green_50,
  to_deliver: Colors.green_50,
  delivering: Colors.green_50,
  completed: Colors.primaryColor_50,
}

export const stateEmoji = {
  to_pay: '💳',
  to_pickup: '⏳',
  picking_up: '🚚',
  washing: '🧼',
  to_deliver: '⏳',
  delivering: '🚚',
  completed: '🏁',
}

export type OrderShort = {
  order_id: number
  client: {
    name: string
    surname: string
  }
  state: OrderState
  total_price: number
  products_number: number
  created_at: string
}

export type OrderShortList = {
  current_page: number
  orders: OrdersGrouped
  next_page_url: string
  total: number
}

export type OrdersGrouped = {
  to_pay?: OrderShort[]
  to_pickup?: OrderShort[]
  picking_up?: OrderShort[]
  washing?: OrderShort[]
  to_deliver?: OrderShort[]
  delivering?: OrderShort[]
  completed?: OrderShort[]
}

export type OrderedProduct = {
  product_id: number
  discount_id?: number | null
  // traslated_name_id: number
  name: string
  quantity: number
  service: Service
  original_price: number
  price: number
  discount?: number | null
}

type Address = {
  address_id: number
  name?: string | null
  address: string
  latitude: number
  longitude: number
  doorbell_name?: string | null
}

type Customer = {
  user_id: number
  name: string
  surname: string
  gender?: Gender | null
  email: string
  phone: string
}

export type Order = {
  order_id: number
  state: OrderState
  pickup_date: string
  laundry_arrival_date: string | null
  washing_complete_date: string | null
  scheduled_delivery_date: string | null
  delivery_date: string | null
  total_price: number
  products_number: number
  notes?: string | null
  detail: OrderedProduct[]
  created_at: string
  address: Address
  laundry: LaundryShort
  pickup_courier: Courier | null
  delivery_courier: Courier | null
  client: Customer
}

export type ChangeStateParams = {
  order_id?: number
  type?: 'courier' | 'laundry'
  next_state?: OrderState
  token?: string
  laundry_id?: number
  courier_id?: number
}

export type OrdersState = {
  readOrders: {
    orders?: OrdersGrouped
    pickupCourierId?: number
    deliveryCourierId?: number
    laundryId?: number
    isLoading: boolean
    currentPage: number
    total: number
  }
}

export const ordersInitialState: OrdersState = {
  readOrders: {
    orders: undefined,
    isLoading: false,
    currentPage: 1,
    total: 1,
  },
}

export enum OrdersActionType {
  ORDERS_READ_REQUEST,
  ORDERS_READ_SUCCESS,
  ORDERS_READ_FAILURE,
}

export type OrdersAction =
  | { type: OrdersActionType.ORDERS_READ_REQUEST }
  | {
      type: OrdersActionType.ORDERS_READ_SUCCESS
      payload: {
        orders?: OrdersGrouped
        pickupCourierId?: number
        deliveryCourierId?: number
        laundryId?: number
        currentPage: number
        total: number
      }
    }
  | { type: OrdersActionType.ORDERS_READ_FAILURE }

export type OrderDetailState = {
  readOrderDetail: {
    order?: Order
    isLoading: boolean
  }
}

export const orderDetailInitialState: OrderDetailState = {
  readOrderDetail: {
    order: undefined,
    isLoading: false,
  },
}

export enum OrderDetailActionType {
  ORDER_DETAIL_READ_REQUEST,
  ORDER_DETAIL_READ_SUCCESS,
  ORDER_DETAIL_READ_FAILURE,
}

export type OrderDetailAction =
  | { type: OrderDetailActionType.ORDER_DETAIL_READ_REQUEST }
  | {
      type: OrderDetailActionType.ORDER_DETAIL_READ_SUCCESS
      payload: {
        order?: Order
      }
    }
  | { type: OrderDetailActionType.ORDER_DETAIL_READ_FAILURE }

export type OrderStateChangeState = {
  readOrder: {
    order?: Order
    isLoading: boolean
  }
  changeState: {
    changedStateOrderId?: number
    isChangingState: boolean
  }
}

export const orderStateChangeInitialState: OrderStateChangeState = {
  readOrder: {
    order: undefined,
    isLoading: false,
  },
  changeState: {
    changedStateOrderId: undefined,
    isChangingState: false,
  },
}

export enum OrderStateChangeActionType {
  ORDER_READ_REQUEST,
  ORDER_READ_SUCCESS,
  ORDER_READ_FAILURE,
  CHANGE_STATE_REQUEST,
  CHANGE_STATE_SUCCESS,
  CHANGE_STATE_FAILURE,
}

export type OrderStateChangeAction =
  | { type: OrderStateChangeActionType.ORDER_READ_REQUEST }
  | {
      type: OrderStateChangeActionType.ORDER_READ_SUCCESS
      payload: { order?: Order }
    }
  | { type: OrderStateChangeActionType.ORDER_READ_FAILURE }
  | { type: OrderStateChangeActionType.CHANGE_STATE_REQUEST }
  | {
      type: OrderStateChangeActionType.CHANGE_STATE_SUCCESS
      payload: { changedStateOrderId?: number }
    }
  | { type: OrderStateChangeActionType.CHANGE_STATE_FAILURE }
