/* eslint-disable react/prop-types */
import { ButtonBase, withStyles } from '@material-ui/core'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import { isString, isUndefined } from 'lodash'
import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Photo } from '../../../models/Photo'
import { Colors } from '../../../styles/colors'
import { Row, SpaceBox } from '../../../styles/commonStyledComponents'

export interface IPhotoPickerFieldProps {
  onClick: () => void
  picked?: Photo | string
}

const PhotoPickerField: FunctionComponent<IPhotoPickerFieldProps> = ({
  onClick,
  picked,
}) => {
  const { t } = useTranslation()

  return (
    <FullWidthButton onClick={onClick}>
      <StyledRow>
        {!isUndefined(picked) ? (
          <ImagePreview src={isString(picked) ? picked : picked.link} />
        ) : (
          <>
            <SpaceBox width={16} />
            <PhotoCameraIcon
              fontSize='small'
              htmlColor={Colors.textColor_200}
            />
            <SpaceBox width={16} />
          </>
        )}
        <VerticalSeparator />
        <SpaceBox width={6} />
        <Label>
          {!isUndefined(picked) ? t('change_image') : t('pick_from_images')}
        </Label>
      </StyledRow>
    </FullWidthButton>
  )
}

const FullWidthButton = withStyles(() => ({
  root: {
    height: 38,
    width: '100%',
    borderRadius: 4,
    border: `1px solid ${Colors.textColor_100}`,
  },
}))(ButtonBase)

const StyledRow = styled(Row)`
  width: 100%;
`

const VerticalSeparator = styled.div`
  height: 38px;
  width: 1px;
  background-color: ${Colors.textColor_50};
`

const ImagePreview = styled.img`
  height: 36px;
  width: 52px;
  border-radius: 4px 0 0 4px;
`

const Label = styled.p`
  font-size: 14px;
  color: ${Colors.textColor_200};
`

export default PhotoPickerField
