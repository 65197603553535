import { get } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Order, stateEmoji } from '../../../models/Order'
import { Colors } from '../../../styles/colors'
import { toItalianDate } from '../../../utils/formatting'

interface HistoryInfoProps {
  order: Order
  className?: string
}

const HistoryInfo = ({ className, order }: HistoryInfoProps): JSX.Element => {
  const {
    created_at,
    delivery_date,
    laundry_arrival_date,
    pickup_date,
    scheduled_delivery_date,
    washing_complete_date,
  } = order
  const { t } = useTranslation()
  return (
    <Container className={className}>
      {created_at && (
        <Item>
          🎉 {t('order_history_created_at')} {toItalianDate(created_at)}
        </Item>
      )}
      {pickup_date && (
        <Item>
          {get(stateEmoji, 'to_pickup')} {t('order_history_pickup_date')}{' '}
          {toItalianDate(pickup_date)}
        </Item>
      )}
      {laundry_arrival_date && (
        <Item>
          {get(stateEmoji, 'washing')} {t('order_history_laundry_arrival_date')}{' '}
          {toItalianDate(laundry_arrival_date)}
        </Item>
      )}
      {washing_complete_date && (
        <Item>
          🧺 {t('order_history_washing_complete_date')}{' '}
          {toItalianDate(washing_complete_date)}
        </Item>
      )}
      {scheduled_delivery_date && (
        <Item>
          {get(stateEmoji, 'to_deliver')}{' '}
          {t('order_history_scheduled_delivery_date')}{' '}
          {toItalianDate(scheduled_delivery_date)}
        </Item>
      )}
      {delivery_date && (
        <Item>
          {get(stateEmoji, 'completed')} {t('order_history_delivery_date')}{' '}
          {toItalianDate(delivery_date)}
        </Item>
      )}
    </Container>
  )
}

const Container = styled.div`
  width: 410px;
  background: ${Colors.white};
  border: 1px solid ${Colors.textColor_50};
  box-sizing: border-box;
  box-shadow: 0px 8px 8px ${Colors.textColor_50};
  border-radius: 6px;
`

const Item = styled.div`
  padding-left: 15px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${Colors.textColor_100};
  &:last-child {
    border-bottom: none;
  }
`

export default HistoryInfo
