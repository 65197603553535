import React from 'react'
import styled from 'styled-components'

import { Colors } from '../../../styles/colors'
import { Column, SpacedRow } from '../../../styles/commonStyledComponents'
import { CenterContainer } from '../CenterContainer'

export interface NotFoundProps {
  description: string
}

const NotFound = ({ description }: NotFoundProps): JSX.Element => {
  return (
    <CenterContainer alignItemsCenter={true}>
      <StyledSpacedRow>
        <SpacedImg src='/assets/png/error_404.png' height={400} width={400} />
        <StyledColumn>
          <Title>Oops!</Title>
          <Description>{description}</Description>
        </StyledColumn>
      </StyledSpacedRow>
    </CenterContainer>
  )
}

const StyledSpacedRow = styled(SpacedRow)`
  @media only screen and (max-width: 770px) {
    flex-direction: column;
  }
`

const SpacedImg = styled.img`
  margin-right: 50px;
  @media only screen and (max-width: 770px) {
    margin-right: 0px;
  }
`

const Description = styled(Column)`
  font-size: 24px;
  line-height: 35px;
  color: ${Colors.textColor_400};
  text-align: left;
  @media only screen and (max-width: 770px) {
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    font-size: 22px;
  }
`

const StyledColumn = styled(Column)`
  min-width: 210px;
  @media only screen and (max-width: 770px) {
    min-width: unset;
    align-items: unset;
  }
`

const Title = styled.div`
  font-size: 40px;
  line-height: 70px;
  color: ${Colors.textColor_500};
`

export default NotFound
