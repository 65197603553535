import React, { useEffect, useState } from 'react'
import { Observable, Subject } from 'rxjs'

import Alert, { AlertType } from '../../components/Common/Alert/Alert'

type ShowAlertProps = {
  message: string
  type: AlertType
}

const alertSubject = new Subject<ShowAlertProps | null>()

// enable subscribing to alerts observable
const onAlert = (): Observable<ShowAlertProps | null> => {
  return alertSubject.asObservable()
}

const showAlert = (message: string, type: AlertType): void => {
  alertSubject.next({ message, type })
}

const hideAlert = (): void => {
  alertSubject.next(null)
}

export const alertService = {
  onAlert,
  showAlert,
  hideAlert,
}

type AlertProviderProps = {
  children: JSX.Element
}

const AuthProvider = ({ children }: AlertProviderProps): JSX.Element => {
  const [openAlert, setOpenAlert] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [type, setColor] = useState<AlertType>('info')

  const show = (alert: ShowAlertProps): void => {
    setErrorMessage(alert.message)
    setColor(alert.type)
    setOpenAlert(true)
  }

  const hide = (): void => {
    setOpenAlert(false)
    setErrorMessage('')
  }

  useEffect(() => {
    alertService.onAlert().subscribe((alert) => {
      if (alert) {
        show(alert)
      } else {
        hide()
      }
    })
  }, [])

  return (
    <>
      {children}
      <Alert open={openAlert} onClose={hide} type={type} text={errorMessage} />
    </>
  )
}

export default AuthProvider
