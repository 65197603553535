import { get } from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Order, stateColors, stateEmoji } from '../../../models/Order'
import { Colors } from '../../../styles/colors'
import { toItalianDate } from '../../../utils/formatting'

interface CurrentStateProps {
  order: Order
  className?: string
}

const CurrentState = ({ className, order }: CurrentStateProps): JSX.Element => {
  const { delivery_date, pickup_date, scheduled_delivery_date, state } = order
  const { t } = useTranslation()
  const color = stateColors[state] || Colors.textColor_500

  const stateMessage = {
    to_pay: t('order_state_message_to_pay'),
    to_pickup:
      t('order_state_message_to_pickup') + ' ' + toItalianDate(pickup_date),
    picking_up: t('order_state_message_picking_up'),
    washing: t('washing'),
    to_deliver:
      t('order_state_message_to_deliver') +
      ' ' +
      toItalianDate(scheduled_delivery_date),
    delivering: t('order_state_message_delivering'),
    completed:
      t('order_state_message_completed') + ' ' + toItalianDate(delivery_date),
  }

  return (
    <Container className={className} color={color}>
      {get(stateEmoji, state)} {get(stateMessage, state)}
    </Container>
  )
}

interface StyleProps {
  colors?: string
}

const Container = styled.div<StyleProps>`
  width: 410px;
  background: ${(props) => props.color || Colors.textColor_500};
  border: 1px solid ${Colors.textColor_50};
  box-sizing: border-box;
  box-shadow: 0px 8px 8px ${Colors.textColor_50};
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  height: 60px;
  color: ${Colors.white};
  font-size: 20px;
`

export default CurrentState
