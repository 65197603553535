import { Typography } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { SpacedRow } from '../../../styles/commonStyledComponents'
import { Button, ButtonType } from '../../Common/Button'

export interface ISchedulesHeaderProps {
  onNewSchedulePressed: () => void
}

const SchedulesHeader = (props: ISchedulesHeaderProps): JSX.Element => {
  const { onNewSchedulePressed } = props

  const { t } = useTranslation()

  return (
    <StyledSpacedRow>
      <Typography variant='h4'>{t('schedules')}</Typography>
      <Button
        label={t('new_schedule')}
        mode={ButtonType.SUCCESS_MAIN}
        startIcon={<AddIcon />}
        onClick={onNewSchedulePressed}
      />
    </StyledSpacedRow>
  )
}

const StyledSpacedRow = styled(SpacedRow)`
  align-items: center;
  padding-top: 32px;
  padding-left: 48px;
  padding-right: 48px;
`

export default SchedulesHeader
