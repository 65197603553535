import {
  Snackbar,
  SnackbarProps,
  Theme,
  ThemeProvider,
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert'
import React from 'react'

import { defaultTheme } from '../../../styles/muiTheme'

export type AlertType = 'success' | 'info' | 'warning' | 'error'

interface AlertProps extends Partial<SnackbarProps> {
  type?: AlertType
  theme?: Theme
  text: string
}

const Alert = (props: AlertProps): JSX.Element => {
  const { theme = defaultTheme, text, type = 'success' } = props
  return (
    <ThemeProvider theme={theme}>
      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        {...props}
      >
        <MuiAlert elevation={6} variant='filled' severity={type}>
          {text}
        </MuiAlert>
      </Snackbar>
    </ThemeProvider>
  )
}

export default Alert
